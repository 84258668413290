import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addModelData, getModelData } from '../../../slice/modelData/ModelDataSlice'
import { getNewJobId, getUserNewProject, getUserProjectCompressUrl, getUserProjectImageUrl } from '../../../slice/user/UserProject'
// import { getApiModelData } from '../../../slice/modelData/ModelDataSlice'

import { NewSegment, SamModel } from '../../../Model/Job/SamModel'
import { addJobData,  } from '../../../slice/userJobSlice/UserJobSlice'
import { getAllSegment } from '../../../slice/segment/SegmentSlice'

 type Props={
  resetSamData:()=>void
 }

const SamData = ({resetSamData}:Props) => {
    const getUserProjectImageUrls= useSelector(getUserProjectImageUrl)
    const getUserProjectCompressUrls= useSelector(getUserProjectCompressUrl)
    const getAllSegments = useSelector(getAllSegment);
  const getModelDatas= useSelector(getModelData) as SamModel[]
    const getUserNewProjects= useSelector(getUserNewProject)
    const getNewJobIds= useSelector(getNewJobId)
       const dispatch= useDispatch()
         const isApi= useRef(true)
       useEffect(()=>{
         
        const newallseg:NewSegment[]=[]
        if(getModelDatas &&
           getModelDatas.length>0 &&
           getUserProjectImageUrls &&
          getUserNewProjects &&
          getUserProjectCompressUrls&&
          isApi.current &&
          getNewJobIds
         
        ){
             isApi.current=false
            const allseg= getModelDatas[0].segments
                 
                if(allseg){
                  Object.keys(allseg).forEach(item=>{   // door
                    const shortName= getAllSegments.find(seg=>(seg.name)?.toLowerCase()===item.toLowerCase())?.short_code
                    
                    const keyValue=allseg[item]
                        if(keyValue){
                          Object.keys(keyValue).forEach((seg)=>{  // door 1
                            const segValue= keyValue[seg]
                              const oldShortName=segValue.details?.seg_short;

                              const numberFromString = oldShortName?.match(/\d+$/)?.[0];
                             // console.log("numberFromString",numberFromString)
                              const number = parseInt(numberFromString??"", 10);
                             // console.log("number",number)
                              if(shortName && number){
                                const newShortName=(shortName+number)
                              //  console.log("newShortName",newShortName)
                                if(segValue){
                                     if(segValue.details?.annotation_type==undefined 
                                     ){
                                       const data = {
                                                 [seg]: {
                                                     details: {
                                                         ...segValue.details,
                                                         annotation_type: "system" ,
                                                         seg_short:newShortName
                                                        
                                                     },
                                                     designer: []
                                                 }
                                             };
                                                newallseg.push(data)
                                     }
                                }
                              }
                              
                             

                          })
                        }
                   
                  })
                }
               
           
            
        }
        if(newallseg &&
          newallseg.length>0
        ){
          
          jobStructure(newallseg)
        }
       },[getModelDatas,getUserNewProjects,
        getUserProjectImageUrls,getUserProjectCompressUrls
        ,getAllSegments,getNewJobIds])


    
      const jobStructure=(newallseg:NewSegment[])=>{
  

        const data={
            project_id: getUserNewProjects.id,
            job_json:JSON.stringify(newallseg),
          image:getUserProjectImageUrls ,
          compressImage:getUserProjectCompressUrls,
          job_view: "Front",
          isArch: false,
          base_64:"",
          seg_labels_count:{},
          dimension_pixel:getModelDatas[0].ImageSize,
          segments:newallseg,
          id:getNewJobIds,
         // base_64: JSON.stringify(getModelDatas[0].Base64)
        }

      

       dispatch(addJobData(data))
        isApi.current=true
        resetSamData()
      }
    
  return (
    <div>
        
    </div>
  )
}

export default SamData