
const imageCache: { [key: string]: string } = {};

export const ConvertImageToBase64 = async (
    imageUrl: string,
    imagePath: string
  ) => {
    try {
      // Fetch the image as a Blob
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const reader = new FileReader();
  
      const base64String = await new Promise<string>((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
  
// Create an image element to set the width and height
const img = new Image();
img.src = base64String;
await new Promise<void>((resolve) => {
  img.onload = () => {
    img.width = 1023;
    img.height = 592;
    resolve();
  };
});

// Save the Base64 string into cache
imageCache[imagePath] = base64String;


    return base64String;

    } catch (err) {
     // console.error('Error converting image to Base64 and caching it:', err);
      return null;
    }
  };
  

 
  export const getCachedBase64Image = (imageUrl: string): string | null => {

      return imageCache[imageUrl] || null;
  
  };

  
  export const removeImageFromCache = (imageUrl: string): void => {
    delete imageCache[imageUrl];
  };

  
  export const clearCache = (): void => {
    for (const key in imageCache) {
      if (Object.prototype.hasOwnProperty.call(imageCache, key)) {
        delete imageCache[key];
      }
    }
  };

 