
import React, { useEffect, useState } from 'react'
import { NameModel } from '../../../../../../Model/material/MaterialModel';
import { useDispatch, useSelector } from 'react-redux';
import { addBrandIdSearchSwatch, addCategorySearchSwatch, addSearchBrandId, addSearchCatId, getIsResetSearch, getSearchBrandIds, resetSearchCatId, resetSearchSwatch, updateIsFilter, updateResetSearch } from '../../../../../../slice/materialSlice/SearchMaterial';
import { resetToolActive } from '../../../../../../slice/toolActive/ToolActiveSlice';

type Props = {
    title: string;
    searchKey: string;
    searchOptions: NameModel[];
    segmentType: string;
}

const MultipleSelect = ({ title, searchKey, searchOptions ,segmentType}: Props) => {
    const [searchItem, setsearchItem] = useState<{ [key: string]: NameModel[] }>({
         "category": [],
          "brand": [],
          "style": [],

        });
   

     const getSearchBrandIdss = useSelector(getSearchBrandIds);  
    const getIsResetSearchs = useSelector(getIsResetSearch)

   console.log("searchItemsearchItem===",searchItem)
    // reset search
    useEffect(() => {
        if(getIsResetSearchs){
            setsearchItem({
                "category": [],
                "brand": [],
                "style": [],
            })
            dispatch(resetToolActive())
            dispatch(updateResetSearch(false))
        }
    },[getIsResetSearchs])

    const handleSearchKey = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = JSON.parse(e.target.value);
        console.log("SelectBrand", value);
    
        if (searchKey === 'category') {
            setsearchItem({
                ...searchItem,
                [searchKey]: [value], // Only one item in the array for category
            });
        } else {
            const isExist = searchItem[searchKey]?.find((item) => item.id === value.id);
            console.log("isExist", isExist);
    
            if (!isExist) {
                setsearchItem((prev) => ({
                    ...prev,
                    [searchKey]: [...(prev[searchKey] || []), value],
                }));
            } else {
                setsearchItem((prev) => ({
                    ...prev,
                    [searchKey]: prev[searchKey].filter((item) => item.id !== value.id),
                }));
            }
        }
    };

    // update category on redux
    useEffect(() => {
       if (searchItem[searchKey]?.length > 0 && segmentType) {
            if (searchKey === "category") {
                const catData: NameModel = {
                    id: searchItem[searchKey][0].id,
                    title: searchItem[searchKey][0].title,
                };
                 dispatch(addCategorySearchSwatch({ type: segmentType, catData: catData }));
                dispatch(addSearchCatId({ catData: catData }));
              
            }
        }
    }, [searchItem,segmentType]);

    //update brands on redux
    useEffect(() => {
        if (searchItem[searchKey]?.length > 0) {
            if (searchKey === "brand") {
                if (getSearchBrandIdss.length === 0) {
                    dispatch(addSearchBrandId({ id: searchItem[searchKey][0].id }));
                } else if (getSearchBrandIdss.length > 0) {
                    const allBrands = searchItem[searchKey];
                    allBrands.forEach((brand) => {
                        if ( brand.id&&!getSearchBrandIdss.includes(brand.id)) {
                            dispatch(addSearchBrandId({ id: brand.id }));
                            dispatch(addBrandIdSearchSwatch({ type: segmentType, catId: searchItem["category"][0].id, brands: getSearchBrandIdss }));
                        }
                    });
                }

            }
         
        }
    
  }, [searchItem, searchKey, getSearchBrandIdss]);

  const [selectedItems, setSelectedItems] = useState<NameModel[]>([]);
  const dispatch = useDispatch();
  const isResetSearch = useSelector(getIsResetSearch);

  // ✅ Reset search state when needed
  useEffect(() => {
    if (isResetSearch) {
      setSelectedItems([]);
      dispatch(updateResetSearch(false));
    }
  }, [isResetSearch, dispatch]);

  const handleSelection = (item: NameModel) => {
    const updatedSelection = selectedItems.some(
      (selected) => selected.id === item.id
    )
      ? selectedItems.filter((selected) => selected.id !== item.id) // Remove if already selected
      : [...selectedItems, item]; // Add new selection

    setSelectedItems(updatedSelection);

    // ✅ Update Redux for category (single select)
    if (searchKey === "category" && updatedSelection.length > 0) {
      dispatch(addSearchCatId({ catData: updatedSelection[0] }));
    }

    // ✅ Update Redux for brand (multi-select)
    if (searchKey === "brand") {
      updatedSelection.forEach((brand) => {
        dispatch(addSearchBrandId({ id: brand.id }));
      });
    }
  };

  return (
    <>
      <div className="btn-group pe-2">
        <div className="dropdown">
          {/* Dropdown Button */}
          <button
        className="btn btn-outline border dropdown-toggle w-100"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false">
        {selectedItems.length > 0
          ? selectedItems.map((item) => item.title).join(", ") // Show selected items
          : `Select ${title}`}
      </button>

          {/* Dropdown Menu */}
          <ul className="dropdown-menu" style={{ width: "200px" }}>
            {searchOptions.length > 0 ? (
              searchOptions.map((item) => (
                <li key={item.id} className="dropdown-item">
                  <label className="form-check-label d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      checked={selectedItems.some(
                        (selected) => selected.id === item.id
                      )}
                      onChange={() => handleSelection(item)}
                    />
                    {item.title || "Untitled"}
                  </label>
                </li>
              ))
            ) : (
              <li className="dropdown-item text-muted">
                No categories available
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default MultipleSelect;
