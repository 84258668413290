import React, { ReactNode, useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { RootState } from '../store/Store';
import store from "../store/Store";

import Layout from '../layout/Layout';
import HomePage from '../page/homePage/HomePage';
import NotFound from '../page/notfound/NotFound';
import Profile from '../page/profilePage/Profile';
import BrandRoutes from './brandRoutes/BrandRoutes';
import ProjectRoutes from './projectRoutes/ProjectRoutes';
import LoginPage from '../page/login/LoginPage';
import AuthRoutes from './authRoutes/AuthRoutes';
import CustomerRoutes from './customerRoute/CustomerRoute';
import AdminRoutes from './adminRoutes/AdminRoutes';
import CreateProjectHomes from '../module/createproject/CreateProjectHomes';
import SegmentsRoutes from './segmentRoutes/SegmentsRoutes';
import UsersRoutes from './userroutes/UsersRoutes';
import ShapeRoutes from './shapeRoutes/ShapeRoutes';
import CanvasRoutes from './canvasRoute/CanvasRoutes';
import Generate_Image from '../page/generate_Image/Generate_Image';
import Edit_Image from '../page/generate_Image_edit/Edit_Image';
import MediaRoutes from './mediaRoutes/MediaRoutes';
import DemoRoutes from './demoTable/DemoTable';
import DasboardRoute from './dasboardRoute/DasboardRoute';
import SamModelPage from '../page/samModel/SamModelPage';
import ShareProPage from '../page/shareproject/ShareProPage';
import CanvasEdit from './canvasEdit/CanvasEdit';
import FabricPlayPage from '../page/fabricPlay/FabricPlayPage';
import Settings from '../page/settings/Settings';
import AccountPage from '../page/myaccount/AccountPage';
import { userModel } from '../Model/user/UserModel';

// Component to check authentication
const ProtectedRoute: React.FC<{ children: ReactNode }> = ({ children }) => {
  const user = useSelector((state: RootState) => state.user.user) as userModel[];
  const authUser=user[0]
 // console.log(user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/auth/login'); // Redirect to login if user is not found
    }
  }, [authUser, navigate]);

  return <>{children}</>;
};

const AppRoutes: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="auth/*" element={<AuthRoutes />} />
            <Route path="media/*" element={<MediaRoutes />} />
            <Route path="*" element={<NotFound />} />
            <Route path="profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="project/*" element={<ProtectedRoute><CanvasRoutes /></ProtectedRoute>} />
            <Route path="brand/*" element={<ProtectedRoute><BrandRoutes /></ProtectedRoute>} />
            <Route path="dashboard/*" element={<ProtectedRoute><AdminRoutes /></ProtectedRoute>} />
            <Route path="projects/*" element={<ProtectedRoute><ProjectRoutes /></ProtectedRoute>} />
            <Route path="customer/*" element={<ProtectedRoute><CustomerRoutes /></ProtectedRoute>} />
            <Route path="designer/*" element={<ProtectedRoute><CustomerRoutes /></ProtectedRoute>} />
            <Route path="createproject" element={<ProtectedRoute><CreateProjectHomes /></ProtectedRoute>} />
            <Route path="segments/*" element={<ProtectedRoute><SegmentsRoutes /></ProtectedRoute>} />
            <Route path="user/*" element={<ProtectedRoute><UsersRoutes /></ProtectedRoute>} />
            <Route path="shape/*" element={<ProtectedRoute><ShapeRoutes /></ProtectedRoute>} />
            <Route path="dashboard-page/*" element={<ProtectedRoute><DasboardRoute /></ProtectedRoute>} />
            <Route path="generate-image" element={<ProtectedRoute><Generate_Image /></ProtectedRoute>} />
            <Route path="generate-image/edit" element={<ProtectedRoute><Edit_Image /></ProtectedRoute>} />
            <Route path="fabric-play" element={<ProtectedRoute><FabricPlayPage /></ProtectedRoute>} />
            <Route path="demo-table/*" element={<ProtectedRoute><DemoRoutes /></ProtectedRoute>} />
            <Route path="sam-model" element={<ProtectedRoute><SamModelPage /></ProtectedRoute>} />
            <Route path="share" element={<ProtectedRoute><ShareProPage /></ProtectedRoute>} />
            <Route path="canvas-edit" element={<ProtectedRoute><CanvasEdit /></ProtectedRoute>} />
            <Route path="settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
            <Route path="myaccount" element={<ProtectedRoute><AccountPage /></ProtectedRoute>} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
};

export default AppRoutes;
