import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MaterialModel, NameModel } from "../../Model/material/MaterialModel";
import { SearchMaterialModal } from "../../Model/material/SearchMaterial";
import { StatArrow } from "@chakra-ui/react";
import { CiTwoTone } from "@ant-design/icons";

interface SearchMaterialStates {
  searchCategory: string[],
  isFilter: boolean,
  isStartFilter: boolean,
  searchCatId: NameModel,
  searchBrandId: number[],
  searchStyleId: number[],
  allSearchedSwatch: SearchMaterialModal[],
  isBrandSearching: boolean,
  isStyleSearching: boolean,
  isSwatchSearching: boolean,
  isResetSearch:boolean
}

const initialState: SearchMaterialStates = {
  searchCategory: [],
  isFilter: false,
  searchCatId: {},
  searchBrandId: [],
  searchStyleId: [],
  isStartFilter: false,
  allSearchedSwatch: [],
  isBrandSearching: false,
  isStyleSearching: false,
  isSwatchSearching: false,
  isResetSearch:false
};

const SearchMaterialSlice = createSlice({
  name: "searchMaterial",
  initialState,
  reducers: {
    resetSearchMaterial: (state) => {
      return initialState;
    },
    updateIsFilter: (state, action) => {
      state.isFilter = action.payload;
     
    },
    resetFilter:(state)=>{
      state.searchCatId={}
      state.searchBrandId=[]
      state.searchStyleId=[]
      state.allSearchedSwatch=[]
    },
    addSearchCatId: (state, action) => {
      const { catData } = action.payload;
     
      state.searchCatId = {
        id: catData?.id ?? 0,
        title: catData?.title ?? ""
      };
    },
    resetSearchCatId: (state) => {
      state.searchCatId = {  };
    },
    addSearchBrandId: (state, action) => {
      const { id } = action.payload;
      const allBrand = state.searchBrandId;
      if (allBrand && allBrand.length === 0) {
        state.searchBrandId.push(id);
      } else {
        const brandIndex = state.searchBrandId.findIndex(item => item === id);
        if (brandIndex == -1) {
          state.searchBrandId.push(id);
        } else {
          state.searchBrandId.splice(brandIndex, 1);
        }
      }
    },
    resetSearchBrandId: (state) => {
      state.searchBrandId = [];
    },
    updateIsStartFilter: (state, action) => {
      state.isStartFilter = action.payload;
    },
    addSearchStyleId: (state, action) => {
      const { styleId } = action.payload;
      const allStyle = state.searchStyleId;
    
      if (allStyle.length === 0) {
        state.searchStyleId.push(styleId);
      } else {
        const styleIndex = state.searchStyleId.findIndex(item => item === styleId);
        if (styleIndex == -1) {
          state.searchStyleId.push(styleId);
        } else {
          state.searchStyleId.splice(styleIndex, 1);
        }
      }
    },
    resetSearchStyleId: (state) => {
      state.searchStyleId = [];
    },
    addSegmentTypeAllSearchSwatch: (state, action) => {
      const { type } = action.payload;
      const allSwatch = state.allSearchedSwatch;
      const data: SearchMaterialModal = {
        segmentType: type,
        category: { }, 
        brand: [],
        style: [],
        Swatches: []
      };
      if (allSwatch && allSwatch.length === 0) {
        state.allSearchedSwatch.push(data);
      } else {
        const segmentIndex = allSwatch.findIndex(swatch => swatch.segmentType === type);
        if (segmentIndex == -1) {
          state.allSearchedSwatch.push(data);
        } else {
          state.allSearchedSwatch[segmentIndex] = data;
        }
      }
    },
    addCategorySearchSwatch: (state, action) => {
      const { type, catData } = action.payload;
      const allSwatch = state.allSearchedSwatch;
      const segmentIndex = allSwatch.findIndex(swatch => swatch.segmentType === type);
      if (segmentIndex != -1 && catData) {
        allSwatch[segmentIndex].category = {
          id: catData.id,
          title: catData.title
        };
      }
    },
    addcategoryBasedSwatch: (state, action) => {
      const { type,catId,swatches } = action.payload;
      const allSwatch = state.allSearchedSwatch;
        const segmentIndex = allSwatch.findIndex(swatch => swatch.segmentType === type);
        if (segmentIndex != -1) {
         
          const cat= allSwatch[segmentIndex]?.category?.id===catId
             if(cat){
              state.allSearchedSwatch[segmentIndex].Swatches = swatches
            }
             }
    },
    
    addBrandIdSearchSwatch:(state,action)=>{
     const {type, catId, brands}=action.payload
     
     const allSwatch = state.allSearchedSwatch;
     const segmentIndex = allSwatch.findIndex(swatch => swatch.segmentType === type);
     if(segmentIndex!=-1){
      const categoryId=  state.allSearchedSwatch[segmentIndex]?.category?.id
         if(categoryId===catId){
          state.allSearchedSwatch[segmentIndex].brand=brands
         }
      
     }
    }, 
    addcategoryBrandBasedSwatch: (state, action) => {
      const { type, catId,brands, swatches } = action.payload;
      const allSwatch = state.allSearchedSwatch;
      const segmentIndex = allSwatch.findIndex(swatch => swatch.segmentType === type);
      if (segmentIndex == -1) {
          
        //  check the catId
           const cat= allSwatch[segmentIndex]?.category?.id===catId
           if(cat){
            allSwatch[segmentIndex].brand=brands
            allSwatch[segmentIndex].Swatches=swatches
           }
         
      } else {
        state.allSearchedSwatch[segmentIndex].Swatches = swatches;
      }
    },
    addStyleSearchSwatch(state, action) {
      const { type, catId, styles } = action.payload;
      const allSwatch = state.allSearchedSwatch;
      const segmentIndex = allSwatch.findIndex(swatch => swatch.segmentType === type);
      if (segmentIndex != -1) {
           const categoryId=  state.allSearchedSwatch[segmentIndex]?.category?.id
           if(categoryId===catId){
            state.allSearchedSwatch[segmentIndex].style = styles;
           }
      
      }
    },

   
    addAllSearchedSwatch: (state, action) => {
      const { catId, brandId, styleId } = action.payload;
      const alSwatch = state.allSearchedSwatch;
    },
    resetSearchSwatch: (state) => {
      state.searchBrandId = [];
    },
    updateIsBrandSearching: (state, action) => {
      state.isBrandSearching = action.payload;
    },
    updateIsStyleSearching: (state, action) => {
      state.isStyleSearching = action.payload;
    },
    addUpdateSearchCategory: (state, action) => {
      const { category } = action.payload;
      const allCategory = state.searchCategory;
      if (allCategory && allCategory.length === 0) {
        state.searchCategory.push(category);
      } else {
        const catIndex = state.searchCategory.findIndex(item => item === category);
        if (catIndex == -1) {
          state.searchCategory.push(category);
        } else {
          state.searchCategory.splice(catIndex, 1);
        }
      }
    },
  
    resetSearchCategory: (state) => {
      state.searchCategory = [];
    },
    updateIsSawatchSearching: (state, action) => {
      state.isSwatchSearching = action.payload;
  },
  updateResetSearch:(state,action)=>{
    state.isResetSearch=action.payload
  }
  ,
  resetAllSearchFilter:(state)=>{
    return initialState

  }
}
});

export const {
  resetSearchMaterial,
  updateIsFilter,
  addSearchCatId,
  resetSearchCatId,
  addSearchBrandId,
  resetSearchBrandId,
  updateIsStartFilter,
  addSearchStyleId,
  resetSearchStyleId,
  addAllSearchedSwatch,
  resetSearchSwatch,
  updateIsBrandSearching,
  updateIsStyleSearching,
  addUpdateSearchCategory,
  resetSearchCategory,
  addSegmentTypeAllSearchSwatch,
  addCategorySearchSwatch,
  addcategoryBasedSwatch,
  addBrandIdSearchSwatch,
  addcategoryBrandBasedSwatch,
  addStyleSearchSwatch,
  updateIsSawatchSearching,
  resetFilter,
  resetAllSearchFilter,
  updateResetSearch

} = SearchMaterialSlice.actions;

export const getIsFilter = (state: { searchMaterial: SearchMaterialStates }) => state.searchMaterial.isFilter;
export const getSearchCatId = (state: { searchMaterial: SearchMaterialStates }) => state.searchMaterial.searchCatId;
export const getSearchBrandIds = (state: { searchMaterial: SearchMaterialStates }) => state.searchMaterial.searchBrandId;
export const getIsStartFilter = (state: { searchMaterial: SearchMaterialStates }) => state.searchMaterial.isStartFilter;
export const getSearchStyleId = (state: { searchMaterial: SearchMaterialStates }) => state.searchMaterial.searchStyleId;
export const getIsSearchBrand = (state: { searchMaterial: SearchMaterialStates }) => state.searchMaterial.isBrandSearching;
export const getIsSearchStyle = (state: { searchMaterial: SearchMaterialStates }) => state.searchMaterial.isStyleSearching;
export const getSearchCategory = (state: { searchMaterial: SearchMaterialStates }) => state.searchMaterial.searchCategory;

export const getAllSearchSwatch = (state: { searchMaterial: SearchMaterialStates }) => state.searchMaterial.allSearchedSwatch;
export const getIsSwatchSearching = (state: { searchMaterial: SearchMaterialStates }) => state.searchMaterial.isSwatchSearching;

export const getIsResetSearch = (state: { searchMaterial: SearchMaterialStates }) => state.searchMaterial.isResetSearch;
export default SearchMaterialSlice.reducer;