// import { Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
import {
 
  getSelectedSegment,

} from '../../../../slice/userJobSlice/UserJobSlice'
import { useDispatch, useSelector } from 'react-redux'

import {  JobSegmentationModel } from '../../../../Model/masterArray/MasterArrayModel'

import { addSvgImage, addSvgOption, addSvgPhotoImage, getSvgOption } from '../../../../slice/svdImageSlice/SvgImageSlice'
import { SelectedGroupModel } from '../../../../Model/svgModel/SvgModel'
import { stopLoading } from '../../../../slice/loading/LoadingSlice'

import ReplaceSwatchHome from '../replaceSwatch/ReplaceSwatchHome'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'

import { getDoorSwatches, getGarageSwatches, getGutterSwatches, getRoofSwatches, getTrimSwatches, getWallSwatches, getWindowSwatches, resetDoorSwatches, resetWallSwatches, resetWindowSwatches, resetRoofSwatches, resetTrimSwatches, resetGutterSwatches, resetGarageSwatches } from '../../../../slice/swatches/SwatchSlices'
import { CatBrandStyleSwatchModel } from '../../../../Model/material/CatBrandStyleSwatchModel'
import Loading from '../../../loading/Loading'
import GetAllSwatchImage from '../segmentbasedSwatch/GetAllSwatchImage'
import { getSelectedSegmentForSwatch } from '../../../../slice/segment/SegmentSlice'
import { getGroupName } from '../../../../slice/toolActive/ToolActiveSlice'
import InformationSwatch from './InformationSwatch'

import { getMaterialPath, MaterialPath } from '../util/materialPath/MaterialPath'
import { GetSortedSwatchImage } from '../util/swatchImage/GetSortedSwatchImage'


type Props={
    allSegDetail:JobSegmentationModel[]
}


const SwatchImages = () => {
    const [svgPoints, setSvgPoints] = useState<number[]>([]); // Points for the polygon
    const [palletUrl, setPalletUrl] = useState<string|null>(null); // Base64 string for the image
   
    const [show, setShow] = useState(false);
    
    const getSelectedSegments= useSelector(getSelectedSegment)
    const dispatch= useDispatch()
  
    const [ allSwatchImages, setAllSwatchImage]= useState<CatBrandStyleSwatchModel[]>([])

    const [selctedSwatch, setSelectedSwatch]= useState< CatBrandStyleSwatchModel|null>()
    const [isApi, setIsApi]= useState<boolean>(false)
     const[new_bucket, setNew_bucket]= useState<number>(0)
   
    const [groupSegmentData, setGroupSegmentData]= useState<JobSegmentationModel[]>([])

  
    const getSvgOptions= useSelector(getSvgOption)
       
    const doorSwatches= useSelector(getDoorSwatches)
    const windowSwatches= useSelector(getWindowSwatches)
    const roofSwatches= useSelector(getRoofSwatches)
    const trimSwatches= useSelector(getTrimSwatches)
    const garageSwatches= useSelector(getGarageSwatches)
    const gutterSwatches= useSelector(getGutterSwatches)
    const wallSwatches= useSelector(getWallSwatches) 
    const[currentSwatches, setCurrentSwatches]= useState<string|null>(null)

    const getGroupNames= useSelector(getGroupName)
    const [activeIndex, setActiveIndex] = useState<number | null>(null); // Track the active swatch index
    

    useEffect(() => {
     
        if (getGroupNames ) {
         
                     if (getGroupNames === "Door" && doorSwatches && doorSwatches.length > 0) {
                         //console.log("Setting door swatches")
                         setCurrentSwatches("Door")
                         const allDoors=GetSortedSwatchImage(doorSwatches)
                         setAllSwatchImage(allDoors)
                     } else if (getGroupNames === "Wall" && wallSwatches && wallSwatches.length > 0) {
                         //console.log("Setting wall swatches")
                           setCurrentSwatches("Wall")
                           const allWall=GetSortedSwatchImage(wallSwatches)
                         setAllSwatchImage(allWall)
                     } else if (getGroupNames === "Window" && windowSwatches && windowSwatches.length > 0) {
                         //console.log("Setting window swatches")

                         setCurrentSwatches("Window")
                         const allWindow=GetSortedSwatchImage(windowSwatches)
                         setAllSwatchImage(allWindow)
                     } else if (getGroupNames === "Roof" && roofSwatches && roofSwatches.length > 0) {
                         //console.log("Setting roof swatches")
                         setCurrentSwatches("Roof")
                         setAllSwatchImage(roofSwatches)
                     } else if (getGroupNames === "Garage" && garageSwatches && garageSwatches.length > 0) {
                         //console.log("Setting garage swatches")
                         setCurrentSwatches("Garage")
                         setAllSwatchImage(garageSwatches)
                     } else if (getGroupNames === "Gutter" && gutterSwatches && gutterSwatches.length > 0) {
                         //console.log("Setting gutter swatches")
                         setCurrentSwatches("Gutter")
                         setAllSwatchImage(gutterSwatches)
                     } else if (getGroupNames === "Trim" && trimSwatches && trimSwatches.length > 0) {
                         //console.log("Setting trim swatches")
                         setCurrentSwatches("Trim")
                         setAllSwatchImage(trimSwatches)
                     }
                     
           
        }
       
    }, [getGroupNames, doorSwatches, wallSwatches,windowSwatches])

   
  // update the selected segment for swatch
  useEffect(()=>{
    if(getSelectedSegments && getSelectedSegments.length>0){
      setGroupSegmentData(getSelectedSegments)
    }
  },[getSelectedSegments])

      
  const handleSwatch=(data:CatBrandStyleSwatchModel, index: number)=>{
    let path=data.new_bucket==0?getMaterialPath("old material"):getMaterialPath("materials")
    setNew_bucket(data?.new_bucket??0)
    if(data.is_featured){
      handleSwatchFeature(data)

    }else{
     // console.log("no feature")
      setSelectedSwatch(data)
       const swatchUrl=`${path}${data.photo}`
   
      setPalletUrl(swatchUrl)
      setActiveIndex(index); // Set the active index here
      if(groupSegmentData && groupSegmentData.length==0){
       dispatch(addMessage({
         isShow:true,
         mess:"Please select the segment",
          toastType:"Error"
        }))
        // alert('Please select the segment')
      }else {
       dispatch(addSvgOption({
         isOpenModal:true,
         svgModelName:""
        }))
      }
    }
       
   
  
  }
  const handleSwatchFeature=(data:CatBrandStyleSwatchModel)=>{
   // console.log("yes feature")
   let path=data.new_bucket==0?getMaterialPath("old material"):getMaterialPath("materials")

    if(data.bucket_path && data.photo){
      setSelectedSwatch(data)
      const swatchUrl=data.bucket_path=="default"?`${path}${data.photo}`:data.bucket_path
     setPalletUrl(swatchUrl)
     if(groupSegmentData && groupSegmentData.length==0){
      
      dispatch(addMessage({
        isShow:true,
        mess:"Please select the segment",
         toastType:"Error"
       }))
      
     }else {
      dispatch(addSvgOption({
        isOpenModal:true,
        svgModelName:""
       }))
     }
    }
   
  
  
  }
 
  // React svg
useEffect(()=>{

  if(getSvgOptions.svgModelName==="reactSvg" && 
    groupSegmentData && 
    groupSegmentData.length>0 && palletUrl){
    reactSvg(groupSegmentData,palletUrl)
  }

  
},[getSvgOptions,groupSegmentData,palletUrl])

// open opencv
useEffect(()=>{

  if(getSvgOptions.svgModelName==="openCv" &&
   !getSvgOptions.isOpenModal &&!isApi &&
    palletUrl){
        //console.log("setIsApi is true")
       
        setIsApi(true)
      }
     
  
  
},[getSvgOptions,palletUrl, isApi])


const reactSvg=(groupSegmentData:JobSegmentationModel[],swatchUrl:string)=>{
  const allSelected:SelectedGroupModel[]=[]
  if(groupSegmentData && groupSegmentData.length>0 && swatchUrl){
          groupSegmentData.map(item=>{
                  if(item.segName && item.details?.annotation && item.details.bb_annotation_int){
                      const data={
                          segname:item.segName,
                          annoatation:item.details?.annotation ??[],
                          coOrdinate:item.details?.bb_annotation_int??[]
                      }
                      allSelected.push(data)
                  }
          })
       
    if(allSelected && allSelected.length>0){
      dispatch(addSvgImage({
          swatchUrl:swatchUrl,
          selectedGroup:allSelected
         }))
    }
         
     }
}



  const handleShow = (data:CatBrandStyleSwatchModel) =>{
    let path=data.new_bucket==0?getMaterialPath("old material"):getMaterialPath("materials")

   setShow(true);
    setSelectedSwatch(data)
    const allSelected:SelectedGroupModel[]=[]
     //const swatchUrl=data?.photo_svg
    const swatchUrl=`${path}${data.photo}`;
    setPalletUrl(swatchUrl)
  } 

 const[ coOrdinate, setCoordinate]= useState<number[]>([])

// set svg selected seg  point
 



  const handleResetSwatch=(mess:boolean)=>{
    setSelectedSwatch(null)
    setPalletUrl(null)
    setIsApi(false)
    dispatch(stopLoading())
    if (mess) {

    } else if (!mess) {
    
      dispatch(addMessage({
        isShow:true,
        mess:"Error on swatch image",
         toastType:"Error"
       }))
    }
  }
   
  // reload swatches
  const handleReloadSwatches=()=>{
    if(currentSwatches){
    setAllSwatchImage([])
      if(currentSwatches==="Door"){
          dispatch(resetDoorSwatches())
      }else if(currentSwatches==="Wall"){
        dispatch(resetWallSwatches())
      }else if(currentSwatches==="Window"){
        dispatch(resetWindowSwatches())
      }else if(currentSwatches==="Roof"){
        dispatch(resetRoofSwatches())
      }else if(currentSwatches==="Garage"){
        dispatch(resetGarageSwatches())
      }else if(currentSwatches==="Gutter"){
        dispatch(resetGutterSwatches())
      }else if(currentSwatches==="Trim"){
        dispatch(resetTrimSwatches())
      }
      
      
    }
  }
  
    const handleCloseSwatch=()=>{
      setShow(false)  
      setSelectedSwatch(null)
    }
  return (

    <>
    
       {allSwatchImages &&
        allSwatchImages.length== 0 ?(
          <h6 className='d-flex justify-content-center align-items-center'>Loading 
            <svg width="36" height="36" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><circle className="spinner qM83" cx="4" cy="12" r="2"></circle><circle className="spinner oXPr" cx="12" cy="12" r="2"></circle><circle className="spinner ZTLf" cx="20" cy="12" r="2"></circle></svg>
          </h6>
        ):(
          allSwatchImages.map((item, index) =>
          
           {
            
            let newPath;
            if(item.new_bucket==0){
              newPath=getMaterialPath("old material")
             
            }else if(item.new_bucket==1){
              newPath=getMaterialPath("materials")
            }else{
              newPath=getMaterialPath("old material")
            }
           
            return  (
              <div className="col-4" key={`${item.id}-${index}`}>
                
              <div
            key={index}
            className={`palletsbox ${activeIndex === index ? "active" : ""}`} // Add active class conditionally
          >
                  <div>
                 
                  <img src={`${newPath}${item?.photo}`} alt="swatch" 
                      onClick={() => handleSwatch(item, index)} // Pass index to handleSwatch
                      />
                    {item.isSelected &&
                    <i className="bi bi-check2"></i>}
                  {/* <svg
                    className="checkicon md"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    ></circle>
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    ></path>
                  </svg> */}

                  </div>

                  <span
                    role="button"
                    className="pallets-info"
                    onClick={() => handleShow(item)} // Show Offcanvas
                  >
                    <i className="bi bi-info"></i>
                  </span>

                </div>
                
              </div>
             
            )
           }
        )
       )}
         <button className='btn btn-primary'>All Materials Loaded</button>

    {show &&
     selctedSwatch &&
      <InformationSwatch
      show={show}
      selctedSwatch={selctedSwatch}
      onclose={handleCloseSwatch}
      />}
   

     { palletUrl!=null &&
     isApi &&
     selctedSwatch  &&
     <ReplaceSwatchHome
     new_bucket={new_bucket}
     selctedSwatch={selctedSwatch}
      palletUrl={palletUrl}
      resetSwatch={handleResetSwatch}
      />}

      <GetAllSwatchImage/>
    </>
  )
}

export default SwatchImages
