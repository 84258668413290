import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import EditSegmentForm from '../edit/EditSegentForm';
import { SegmentModel } from '../../../../Model/segment/SegmentModel';

type Props = {
  isOpenModal: boolean;
  resetModal: () => void;
};

const AddSegment = ({ isOpenModal, resetModal }: Props) => {
  const [segmentData, setSegmentData] = useState<SegmentModel>({
    name: '',
    color: '',
    description: '',
    index: 0,
    isActive: false,
    isVisible: false,
    color_code: '',
    short_code: '',
    icon: '',
    icon_svg: '',
    categories: [],
  });

  const handleCloseAdd = () => {
    setSegmentData({
      name: '',
      color: '',
      description: '',
      index: 0,
      isActive: false,
      isVisible: false,
      color_code: '',
      short_code: '',
      icon: '',
      icon_svg: '',
      categories: [],
    });
    resetModal();
  };

  return (
    <Modal show={isOpenModal} onHide={resetModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create new entry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditSegmentForm
          type='add'
          editData={segmentData}
          closeEdit={handleCloseAdd}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AddSegment;