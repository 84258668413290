import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NameModel } from "../../../../../../Model/material/MaterialModel";
import {
  addSearchCatId,
  getIsFilter,
  updateIsBrandSearching,
  updateIsSawatchSearching,
} from "../../../../../../slice/materialSlice/SearchMaterial";
import "./CategorySearch.scss";
import { getMasterArray } from "../../../../../../slice/canvas/masterArray/MasterArraySlice";
import { GetCetegoryColor } from "../util/getCategoryColor";
type Props = {
  allSegCategory: NameModel[];
  startSearchBrand: (data:string, colorCode:string) => void;
};
const CategorySearch = ({ allSegCategory, startSearchBrand }: Props) => {
  const dispatch = useDispatch();
  const [color, setColor] = React.useState<string | null | undefined>(null);
  const [selectCategory, setSelectCategory] = React.useState<string | null | undefined>(null);
  const getMasterArrays= useSelector(getMasterArray)
  const getIsFilters = useSelector(getIsFilter);

// update filter when close
  useEffect(() => {
    if(!getIsFilters){
      setSelectCategory(null);
    }  
  }, [getIsFilters]);

  // set the color
  useEffect(() => {
    if( getMasterArrays && getMasterArrays.allSeg && getMasterArrays.allSeg.length>0){
      GetCetegoryColor(getMasterArrays.allSeg,setColor)
    }
  },[getMasterArrays])
  
  const handleCategory = (catData: NameModel) => {
 
    if (catData && catData.id && catData.title) {
      setSelectCategory(catData.title);
      dispatch(updateIsBrandSearching(true));
      dispatch(updateIsSawatchSearching(true));
      dispatch(addSearchCatId({ catData: catData }));
      startSearchBrand(catData.title, color??"");
    }
  };

  return (
    <div className="d-flex flex-wrap align-items-center gap-2">
      {allSegCategory &&
        allSegCategory.length > 0 &&
        allSegCategory.map((item) => {
          return (
            <div className="seg-pro">
              <div className="total-seg-sec d-flex flex-wrap align-items-center gap-1">
                <div className="pro-category">
                  <div
                    className="seg-progress-details rounded-pill"
                    key={`${item.title}`}
                    style={{
                                    border: `1px solid ${color}`,
                                    borderRadius: "6px",
                                
                                    backgroundColor: item.title===selectCategory ? `${color}50` : "transparent",
                                    transition: "background-color 0.3s ease",
                                }}
                    onClick={()=>handleCategory(item)}
                  >
                    <svg
                        className="checkicon sm"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 52 52"
                      >
                        <circle
                          className="checkmark__circle"
                          cx="26"
                          cy="26"
                          r="25"
                          fill="none"
                        ></circle>
                        <path
                          className="checkmark__check"
                          fill="none"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        ></path>
                      </svg>
                      {item.title}
                      
                  
                  </div>
                </div>
              </div>
            </div>
           
          );
        })}
    </div>
  );
};

export default CategorySearch;
