import React, { useEffect, useRef, useState } from 'react'

import RightSection from './RightSection/RightSection'
import LeftSections from './LeftSections/LeftSections'
import "./ImageView.scss";
import { useSelector } from 'react-redux';

import RefreshHome from '../../Refresh/RefreshHome';

import SimmerLeft from '../canavas/reannotationCanvas/shimmerLeft/SimmerLeft';
import { getMasterArray } from '../../slice/canvas/masterArray/MasterArraySlice';

import GetBase64Image from './LeftSections/base64Image/GetBase64Image';



const ImageView = () => {

      const getMasterArrays= useSelector(getMasterArray)

  return (
    <>
        <div className='editor-wrapper'>
            <div className="editor-toolbar-left">
                <div className="canvas-left-cat h-100">
                 { getMasterArrays &&
                 getMasterArrays.allSeg &&
                 getMasterArrays.allSeg.length===0?
                 (<SimmerLeft/>):
                 ( <LeftSections/>)
                 
                 
                }
               
                   
                </div>
            </div>
            <div className="editor-canvas position-relative">
              
             {/* <AiExteriorsDesign/> */}
                <RightSection/>
        
                
            </div>
        </div>
        
        <GetBase64Image/>
       <RefreshHome/>
    </>
  )
}

export default ImageView
