import React, { useEffect, useRef } from 'react'
import { JobModel } from '../../../Model/Job/JobModel'
import { SegMentGroupModel } from '../../../Model/masterArray/MasterArrayModel'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSegment } from '../../../slice/segment/SegmentSlice'
import { SegmentModel } from '../../../Model/segment/SegmentModel'
import { addMasterGroup } from '../../../slice/canvas/groupSlice.tsx/GroupSlice'

type Props={
    jobData:JobModel
    resetMasterGroup:()=>void
}
const GetMasterGroup = ({jobData,resetMasterGroup}:Props) => {
    const dispatch= useDispatch()
    const getAllSegments = useSelector(getAllSegment);

     const isApi= useRef(true)
    useEffect(()=>{
        
        if(jobData &&
          getAllSegments && 
          isApi.current ){
            isApi.current= false
            getMateGroup(jobData,getAllSegments)
        }
    },[getAllSegments,jobData])

 const getMateGroup=(jobData:JobModel,getAllSegments:SegmentModel[])=>{
      if(jobData && 
        jobData.segments && 
        getAllSegments && 
        getAllSegments.length>0){
        const allseg=jobData.segments
        const allGroup: SegMentGroupModel[]=[]
        getAllSegments.map(item=>{
            if(allseg){
                const segArray:string[]=[]
                Object.keys(allseg).forEach(key=>{
                    const segName=key.replace(/\d/g, '');
                    if( segName&&item.name?.toLowerCase()===segName.toLocaleLowerCase()) {
                       segArray.push(key)
                    }
                })
                if(segArray.length>0){
                   const data={
                    [item.name??'']:segArray
                   }
                   allGroup.push(data)
                }
              }
              
        })
              if(allGroup.length>0){
               // console.log("allGroup----->",allGroup)
                dispatch(addMasterGroup(allGroup))
              }  
                isApi.current= true
              resetMasterGroup()
      
      }

    }
  return (
    <>
        
    </>
  )
}

export default GetMasterGroup