import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { GetBrandscategoryIdBased } from '../../../../../../api/material/swatch/SwatchApi'
import axios from 'axios'
import { addMessage } from '../../../../../../slice/messageToast/ToastSlice'
import { addcategoryBasedSwatch, updateIsSawatchSearching } from '../../../../../../slice/materialSlice/SearchMaterial'


type Props={
    segType:string,
    catId:number
    brands:number[],
    resetSearchCatBrand:()=>void
 }
const SearchSwatchCatBrnd = ({catId,brands,segType,resetSearchCatBrand}:Props) => {

    const dispatch= useDispatch()
    const isApi= useRef(true)

    useEffect(()=>{
        if(catId && 
            brands &&
            segType && 
            isApi.current){
            isApi.current=false
            getBrandBasedOnCat(catId, brands,segType)
        }
    }, [catId, brands,segType]) // added 'catId' and 'brands' as dependencies

    const getBrandBasedOnCat=async(catId:number, brands:number[],segType:string)=>{
        try{
            const response= await GetBrandscategoryIdBased(catId, brands)
           // console.log("response brand swaycthch---",response)
            if(response  && response.status==200){
                 dispatch(updateIsSawatchSearching(false))
                dispatch(addcategoryBasedSwatch(
                    {
                        type:segType,
                        catId:catId,
                        swatches:response.data.data
                    }))
                isApi.current=true
                resetSearchCatBrand()
            }
    }catch(err){
        if(axios.isAxiosError(err)){
            
            dispatch(addMessage({
                isShow:true,
                mess: err.response?.data.message,
                toastType:"Error"
               }))
        }

        
        isApi.current=true
        resetSearchCatBrand()
    }
}
   
  return (
    <></>
  )
}

export default SearchSwatchCatBrnd