import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSegregateSegment } from "../../../../../slice/segegratedSlice/SegregatedSlice";
import { getMasterArray } from "../../../../../slice/canvas/masterArray/MasterArraySlice";
import { get } from "http";
import { ReactSVG } from "react-svg";
import { getGroupName } from "../../../../../slice/toolActive/ToolActiveSlice";
import { getUserLogin } from "../../../../../slice/user/UserSlice";
import { getAllSegment } from "../../../../../slice/segment/SegmentSlice";
import { SegmentModel } from "../../../../../Model/segment/SegmentModel";
import {
  MasterArrayModel,
  MasterModel,
} from "../../../../../Model/masterArray/MasterArrayModel";
import SegmentDetails from "../../projectDetails/SegmentDetails";
import "../../projectDetails/ProjectDetails.scss";
const SegmentFiliter = () => {
  const [markActive, setMarkActive] = useState<string | null>(null);
  const getMasterArrays = useSelector(getMasterArray);
  const getGroupNames = useSelector(getGroupName);
  const [filterSegment, setFilterSegment] = useState<
    SegmentModel[] | MasterArrayModel[]
  >([]); // Change model type based on login status
  const getUserLogins = useSelector(getUserLogin);
  const getAllSegments = useSelector(getAllSegment);

  // update the active state of the segment
  useEffect(() => {
    if (getGroupNames) {
      setMarkActive(getGroupNames);
    } else {
      setMarkActive(null);
    }
  }, [getGroupNames]);

  useEffect(() => {
    if (
      getUserLogins &&
      getUserLogins[0]?.id &&
      getMasterArrays &&
      getMasterArrays?.allSeg &&
      getMasterArrays?.allSeg?.length > 0
    ) {
      if (getMasterArrays?.allSeg?.length > 0) {
        setFilterSegment(getMasterArrays.allSeg); // Use MasterModel when logged in
      }
    } else {
      if (getAllSegments?.length > 0) {
        setFilterSegment(getAllSegments); // Use SegmentModel otherwise
      }
    }
  }, [getUserLogins, getAllSegments, getMasterArrays]);

  return (
    <>
      {filterSegment &&
        filterSegment.length > 0 &&
        filterSegment.map((item, index) => {
          const path = "https://dzinlystrapi.s3.us-east-2.amazonaws.com";
          if (item.isActive) {
            return (
              <div
                className="seg-progress-details rounded-2"
                key={`${item.name}-${index}`}
                style={{
                  border: `1px solid ${item.color_code}`,
                  borderRadius: "6px",
                  // color:"white",
                  // backgroundColor: item.isActive ? `${item.color_code}50` : "transparent",
                  transition: "background-color 0.3s ease",
                }}>
                <span
                  className="seg-color-code"
                  style={{ backgroundColor: `${item.color_code}` }}></span>
                <p className="m-0">{item.name}</p>
              </div>
            );
          }
        })}
    </>
  );
};

export default SegmentFiliter;
