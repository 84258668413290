import React, { useEffect, useState } from 'react'
import AuthRefresh from './AuthRefresh'
import RefreshCanvas from './RefreshCanvas'
import ProjectPageRefresh from './ProjectPageRefresh'
import CommentRefesh from './commentRefresh/CommentRefesh'
import { switchToOutline } from '../slice/tabControl/TabControlSlice'
import { changeSegmentTab } from '../slice/tabControl/TabControlSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getSettingPath } from '../slice/settingPathSlice/SettingPathSlice'
import GetAllSettingPath from '../module/admin/settingPath/api/GetAllSettingPath'
import GetBase64Image from '../module/ImageView/LeftSections/base64Image/GetBase64Image'
import SelelctedProjectRefresh from './selectedProjectRefresh/SelelctedProjectRefresh'
import RefreshAllSwatches from './material/RefreshAllSwatches'

const RefreshHome = () => {
    const dispatch= useDispatch()
    const getSettingPaths= useSelector(getSettingPath)
    const[isSearch,setIsSearch]=useState<boolean>(false)
    
     // reset Setting Path search
     useEffect(() => {
      if(getSettingPaths.length==0){
        setIsSearch(true)
      }  
}, 
[ ]);

  useEffect(()=>{
    dispatch(changeSegmentTab("details"))
    dispatch(switchToOutline("segment"))
  },[])

  

  const handleResetSettingPath = () => {
    setIsSearch(false)
  }
  return (
    <div>
        
        <AuthRefresh/>
        <RefreshCanvas/>
          {/*  refresh comment */}
     <CommentRefesh />
        <ProjectPageRefresh/>

        { isSearch  &&
   <GetAllSettingPath
    resetSettingPath={handleResetSettingPath}
    />}

{/* get selected job */}
     <SelelctedProjectRefresh/>
<GetBase64Image/>

{/* get all swatches */}
  <RefreshAllSwatches/>  

    </div>
  )
}

export default RefreshHome