import React, { useEffect, useState } from 'react'
import { getAllSegment } from '../../../../../../slice/segment/SegmentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { NameModel } from '../../../../../../Model/material/MaterialModel';
import { getCategoryName } from '../../../../../../slice/materialSlice/MaterialCategorySlice';
import { SearchCategoryBasedOnSegment } from '../../../../../ImageView/LeftSections/util/searchCatBrandStyle/SearchCatBrandStyleUtil';
import { addSegmentTypeAllSearchSwatch, resetAllSearchFilter, resetSearchSwatch } from '../../../../../../slice/materialSlice/SearchMaterial';
import { addGroupName, getGroupName } from '../../../../../../slice/toolActive/ToolActiveSlice';

type Props = {
    allCategory: (data: NameModel[], type:string) => void;
}
const SelectSegment = ({allCategory}:Props) => {
    const getAllSegments= useSelector(getAllSegment)
    const getCategoryNames = useSelector(getCategoryName);
    const [allSegCategory, setAllSegCategory] = useState<NameModel[]>([]);
    const dispatch = useDispatch();
    const [segmentSearch, setSegmentSearch] = useState<string>("");
    const getGroupNames = useSelector(getGroupName);
    const handleSegment = (e: React.ChangeEvent<HTMLSelectElement>) => {
     
        const value = e.target.value;
       // setSegmentSearch(value);
        dispatch(addGroupName(value))
        dispatch(addSegmentTypeAllSearchSwatch({
            type: value,
            
        }))
    }

    useEffect(() => {
      
        if (
          getAllSegments &&
          getAllSegments.length > 0 &&
          getGroupNames &&
          getCategoryNames &&
          getCategoryNames.length > 0
        ) {
          
          setAllSegCategory([])
          //search category based on segment
          SearchCategoryBasedOnSegment(getAllSegments,getGroupNames,getCategoryNames, setAllSegCategory);
        }
      }, [getAllSegments, getGroupNames, getCategoryNames]);
    
      useEffect(() => {
        if (allSegCategory && allSegCategory.length > 0) {
             // reset all filter
          allCategory(allSegCategory,getGroupNames);
        }
      }, [allSegCategory]);
  return (
    <>
     {/* select segment */}
     <div className="btn-group pe-2">
          <select
            className="form-select"
            aria-label="Category Select"
           value={getGroupNames}
         onChange={(e) => handleSegment(e)}
          >
            <option value="" >Select Segment</option>
            {getAllSegments.length > 0 ? (
              getAllSegments.map((item, index) => (
                <option key={index}
                 value={item?.name}>
                  {item.name || "Untitled"}
                </option>
              ))
            ) : (
              <option value="">No categories available</option>
            )}
          </select>
        </div>
    </>
  )
}

export default SelectSegment