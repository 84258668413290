import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsFilter,
  getIsSearchBrand,
  getIsSearchStyle,
  getIsStartFilter,
  getIsSwatchSearching,
  getSearchBrandIds,
  getSearchCatId,
  resetSearchBrandId,
  resetSearchCatId,
  resetSearchMaterial,
  resetSearchStyleId,
  updateIsBrandSearching,
  updateIsFilter,
  updateIsStyleSearching,
} from "../../../../../slice/materialSlice/SearchMaterial";
import CategorySearch from "./categorySearch/CategorySearch";
import { getGroupName } from "../../../../../slice/toolActive/ToolActiveSlice";
import { getAllSegment } from "../../../../../slice/segment/SegmentSlice";
import { getCategoryName } from "../../../../../slice/materialSlice/MaterialCategorySlice";
import { NameModel } from "../../../../../Model/material/MaterialModel";

import BrandSearch from "./searchBrand/BrandSearch";

import StyleSearch from "./searchStyle/StyleSearch";
import { resetSearchBrandName } from "../../../../../slice/materialSlice/MaterialBrandSlice";
import { resetSearchStyleName } from "../../../../../slice/materialSlice/MaterialStyleSlice";
import { Spinner } from "react-bootstrap";
import SearchSwatchBased from "./searchSwatchApi/SearchSwatchBased";
import SearchEdSwatchImage from "./SearchEdSwatchImage";
import "./SearchSection.scss";
import "../../projectDetails/ProjectDetails.scss";
import SegmentFiliter from "./SegmentFiliter";
import { SearchCategoryBasedOnSegment } from "../../util/searchCatBrandStyle/SearchCatBrandStyleUtil";
import ShowSelected from "./showSelected/ShowSelected";
import SearchBrand from "../../../../admin/AdminMaterial/swatches/searchSwatchs/searchBrand/SearchBrand";
import SearchStyleBrandBased from "../../../../admin/AdminMaterial/swatches/searchSwatchs/searchStyle/SearchStyleBrandBased";
const SearchSection = () => {
  const [color, setColor] = React.useState<string | null | undefined>(null);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [selectedStyles, setSelectedStyles] = useState<string[]>([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [allSegCategory, setAllSegCategory] = useState<NameModel[]>([]);
  const [isCategoryOpen, setIsCategoryOpen] = useState(true);
  const [isBrandOpen, setIsBrandOpen] = useState(false);
  const [isStyleOpen, setIsStyleOpen] = useState(false);
  const [isSwatchOpen, setIsSwatchOpen] = useState(false);
  const [isSearchBrand, setIsSearchBrand] = useState(false);
  const [isSearchStyle, setIsSearchStyle] = useState(false);
  // const [isSearchStyle, setIsSearchStyle] = useState(false);


  const dispatch = useDispatch();
  const getIsFilters = useSelector(getIsFilter);
  const getGroupNames = useSelector(getGroupName);
  const getCategoryNames = useSelector(getCategoryName);

  const getSearchBrandIdss = useSelector(getSearchBrandIds);



  const getSearchCatIds = useSelector(getSearchCatId);
  const getAllSegments = useSelector(getAllSegment);

  const getIsStartFilters = useSelector(getIsStartFilter);

  //show selected category
  useEffect(() => {}, [selectedCategory]);
  // update brand and style
  useEffect(() => {
    if (getIsStartFilters) {
      dispatch(resetSearchCatId());

      dispatch(resetSearchBrandId()); // make search brandId=[]
      dispatch(resetSearchBrandName()); // make search brandANme=[]

      dispatch(resetSearchStyleName()); // make search styleName=[]
      dispatch(resetSearchStyleId()); // make search styleId=[]
    }
  }, [getIsStartFilters]);

  useEffect(() => {
    if (getIsFilters) {
      setShowOffcanvas(getIsFilters);
      setIsCategoryOpen(true);
    } else {
      setShowOffcanvas(false);
    }
  }, [getIsFilters]);

  const handleCloseFilter = () => {
    setSelectedBrands([]);
    setSelectedStyles([]);
    setSelectedCategory([]);

    dispatch(resetSearchMaterial());
    dispatch(updateIsFilter(false));
    //setAllSegCategory([])
  };

  useEffect(() => {
    if (
      getAllSegments &&
      getAllSegments.length > 0 &&
      getGroupNames &&
      getCategoryNames &&
      getCategoryNames.length > 0
    ) {
      //search category based on segment
      SearchCategoryBasedOnSegment(
        getAllSegments,
        getGroupNames,
        getCategoryNames,
        setAllSegCategory
      );
    }
  }, [getAllSegments, getGroupNames, getCategoryNames]);

  // open search brand start
  const handleSearchBrand = (cat: string, colorCode: string) => {
    setColor(colorCode);
    setSelectedCategory([cat]);
    setIsSearchBrand(true);
    setIsCategoryOpen(false);
    setIsBrandOpen(true);
  };

  const handleSelectedBrand = (brands: NameModel[]) => {
    setSelectedBrands(
      brands
        .filter((brand) => brand.title !== undefined)
        .map((brand) => brand.title as string)
    );
  };

  // close brand search
  const handleResetSearchBrand = () => {
    dispatch(updateIsBrandSearching(false));
    // dispatch(updateIsStyleSearching(false))
    setIsSearchBrand(false);
  };

  // start search style

  const handleSelectedStyle = (styles: NameModel[]) => {
    setSelectedStyles(
      styles
        .filter((style) => style.title !== undefined)
        .map((style) => style.title as string)
    );
  };

  useEffect(() => {
    if (getSearchBrandIdss && getSearchBrandIdss.length > 0) {
      dispatch(updateIsStyleSearching(true));
      setIsSearchStyle(true);
    }
  }, [getSearchBrandIdss]);

  const handleResetStyle = () => {
    dispatch(updateIsStyleSearching(false));
    setIsSearchStyle(false);
  };

  //  search serach based on category
  return (
    <>
      <div
        className={`offcanvas offcanvas-start ${showOffcanvas ? "show" : ""}`}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header pb-2">
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn border-0 p-0"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={handleCloseFilter}>
              <i className="bi bi-arrow-left fs-5"></i>
            </button>

            <h5 className="offcanvas-title ps-2" id="offcanvasExampleLabel">
              Filters
            </h5>
          </div>

          <div className="pro-details-section ms-2">
            <div className="total-seg-sec d-flex flex-wrap align-items-center gap-1">
              <SegmentFiliter />
            </div>
          </div>
        </div>

        <div className="pro-details-sec p-0">
          <div className="offcanvas-body seg-filter p-0"></div>
          <div
            className="accordion accordion-flush  border-top"
            id="accordionFlushExample">
            {/* category */}
            <div className="accordion-item">
              <div className="accordion-header ">
                <button
                  className="accordion-button bg-white collapsed py-2 bg-body-tertiary"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded={isCategoryOpen}
                  aria-controls="flush-collapseOne"
                  onClick={() => setIsCategoryOpen(!isCategoryOpen)}>
                  Category
                  <div className="d-flex flex-wrap align-items-center gap-1  ms-3">
                    {selectedCategory && selectedCategory.length > 0 && (
                      <ShowSelected
                        selected={selectedCategory}
                        color_code={color ?? "#000"}
                      />
                    )}
                  </div>
                </button>
              </div>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse collapse ${isCategoryOpen ? 'show' : ''}`}
                data-bs-parent="#accordionFlushExample">
                <div className="accordion-body px-2 py-3">
                  {/* category accordian */}
                  <CategorySearch
                    allSegCategory={allSegCategory}
                    startSearchBrand={handleSearchBrand}
                  />
                </div>
              </div>
            </div>

            {/* brand */}
            <div className="accordion-item">
              <div className="accordion-header">
                <button
                  className="accordion-button bg-white collapsed py-2 bg-body-tertiary"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded={isBrandOpen}
                  aria-controls="flush-collapseTwo"
                  onClick={() => setIsBrandOpen(!isBrandOpen)}>
                  Brand{" "}
                  <span className="badge text-bg-primary ms-2">
                    {selectedBrands.length}
                  </span>
                </button>
              </div>
              <div
                id="flush-collapseTwo"
                className={`accordion-collapse collapse ${isBrandOpen ? 'show' : ''}`}
                data-bs-parent="#accordionFlushExample">
                <div className="accordion-body px-2 py-3">
                  <BrandSearch addSelectecdBrand={handleSelectedBrand} />
                </div>
              </div>
            </div>

            {/* style */}
            <div className="accordion-item">
              <div className="accordion-header">
                <button
                  className="accordion-button bg-white collapsed py-2 border-bottom bg-body-tertiary"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded={isStyleOpen}
                  aria-controls="flush-collapseThree"
                  onClick={() => setIsStyleOpen(!isStyleOpen)}>
                  Style{" "}
                  <span className="badge text-bg-primary ms-2">
                    {selectedStyles.length}
                  </span>
                </button>
              </div>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample">
                <div className="accordion-body px-2 py-3">
                  <StyleSearch addSelectedStyle={handleSelectedStyle} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <button
                className="accordion-button bg-white collapsed border-bottom py-2 px-3 bg-body-tertiary"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded={isSwatchOpen}
                aria-controls="flush-collapseThree"
                onClick={() => setIsSwatchOpen(!isSwatchOpen)}>
                Swatch
              
              </button>
            </div>

            <div
              id="flush-collapseThree">
              <div className="accordion-body px-3 ">
                <SearchEdSwatchImage />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* search brand based on category */}
      {isSearchBrand && (
        <SearchBrand
          catId={getSearchCatIds}
          resetSearch={handleResetSearchBrand}
        />
      )}
      {/* search style */}
      {isSearchStyle && getSearchBrandIdss && getSearchBrandIdss.length > 0 && (
        <SearchStyleBrandBased
          brandId={getSearchBrandIdss}
          resetSearchStyle={handleResetStyle}
        />
      )}
      <SearchSwatchBased />
    </>
  );
};

export default SearchSection;
