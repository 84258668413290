import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ImgComparisonSlider } from "@img-comparison-slider/react";
import { changeSegmentTab } from "../../../slice/tabControl/TabControlSlice";
import { getSwitchCanvas } from "../../../slice/tabControl/TabControlSlice";
import { getProjectGenAiImage } from "../../../slice/genAi/GenAiAttributesSlice";
import "./GenAiCanvas.scss";
import GenAiFeatureModal from "../../ImageView/exteriorsAi/tabs/genAiFeature/GenAiFeatureModal";
import GenAiGalleryImages from "./galleryImages/GenAiGalleryImages";
import { GalleryModel, GenAiImageModel } from "../../../Model/genAiAttributes/GenAiImages";
import GenAiCanvasModel from "./GenAiCanvasModel";
import { getWebSocketStatus } from "../../../slice/webSocketSlice/WebSocketSlice";
import { getSettingPath } from "../../../slice/settingPathSlice/SettingPathSlice";


const urlpath = process.env.REACT_APP_Project_Image
const GenAiCanvas = () => {
  const dispatch = useDispatch();
  const getSwitchCanvass = useSelector(getSwitchCanvas);
  const getProjectGenAiImages = useSelector(getProjectGenAiImage);
  const isGenAi=useRef(true)
  const getWebSocketStatuss= useSelector(getWebSocketStatus)
  const [showComparison, setShowComparison] = useState(false);
  const [genAiGallery, setGenAiGallery] = useState<GenAiImageModel|null>(null);
   const [compareGallery, setCompareGallery]= useState<GalleryModel|null>(  null)
   const [originalImage, setOriginalImage]= useState<GalleryModel|null>(  null)
   const [prokjectId, setProjectId]= useState<number|null>(null)
   const[jobId, setJobId]= useState<number|null>(null)
   const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
   const[path,setPath]= useState<string>("")
   const getSettingPaths= useSelector(getSettingPath)
   // set the image path
   useEffect(()=>{
    if(getSettingPaths && getSettingPaths.length>0){
      let path
      const projectPath= getSettingPaths.filter((data)=>data.title==="Project Image")
      if(projectPath.length>0){
        path=projectPath[0].path??""
        setPath(path)
      }
    }
 },[getSettingPaths])
  useEffect(() => {
    // Update the active tab based on getSwitchCanvass
    if (getSwitchCanvass) {
      dispatch(changeSegmentTab(getSwitchCanvass))
    }
  }, [getSwitchCanvass, dispatch]);

  
  useEffect(() => {
   
    if (
      getProjectGenAiImages &&
      getProjectGenAiImages.gallery &&
      getProjectGenAiImages.gallery.length > 0 &&
      getProjectGenAiImages.project_id &&
      getProjectGenAiImages.job_id &&
      isGenAi.current
    ) {
      isGenAi.current= false
      setJobId(getProjectGenAiImages.job_id)
      setGenAiGallery(getProjectGenAiImages)
      setProjectId( getProjectGenAiImages.project_id)
      setOriginalImage(getProjectGenAiImages.gallery[0])
        setCompareGallery(getProjectGenAiImages.gallery[1]??{})
    }
  }, []);

  const handleSwitchChange = () => {
    setShowComparison((prevState) => !prevState);
  };

   const handleComparegenAiImage=(genAiImage:GalleryModel)=>{
    setCompareGallery(genAiImage)
   }

     const handleOpenShareModal=()=>{
      //console.log("hello")
      setIsOpenModal(true)
     }
   const handleCloseModel=()=>{
    setIsOpenModal(false)
   }
  return (
    <>
      <div className=" gen-ai-page">
        <div className="re-anno-section" onClick={() => dispatch(changeSegmentTab("segment"))}>
        <button className="d-flex align-items-center btn btn-primary border-0  custom-back-button">
    <span className="fs-4 me-1">
        <i className="bi bi-arrow-left-short"></i>
    </span>
    <span className="back-text">Gen-Ai Gallery</span>
</button>

{getWebSocketStatuss &&
<span>
        <svg width="36" height="36" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <ellipse cx="12" cy="6" rx="4" ry="2" fill="#8e44ad">
    <animateTransform attributeName="transform" type="rotate" from="0 12 12" to="360 12 12" dur="2s" repeatCount="indefinite" />
  </ellipse>
  <ellipse cx="12" cy="18" rx="4" ry="2" fill="#16a085">
    <animateTransform attributeName="transform" type="rotate" from="360 12 12" to="0 12 12" dur="2s" repeatCount="indefinite" />
  </ellipse>
</svg>


</span>}
        </div>





        <div className="gen-ai-section">
          {/* Toggle View */}
          {showComparison ? (
            <div className="compare-image gen-ai-img">
              <ImgComparisonSlider className="comapare-img">
                {
                  genAiGallery &&
                  genAiGallery.project_id &&
                  genAiGallery.job_id &&
                  genAiGallery.gallery&&
                  genAiGallery.gallery.length>0 &&
                  genAiGallery.gallery 
                   .filter(item=>item.image_name==="Project Image")
                  .map(item=>{
                    
                    return(
                  <figure slot="first" className="before">
                    <img
                      className="w-100"
                      src={`${path}${genAiGallery.project_id}/${genAiGallery.job_id}/${item.image_path}`}
                      alt="Before"
                    />
                    <figcaption>Before</figcaption>
                  </figure>
                    )
                    
                  })
                 }
             

                 {compareGallery &&
                  compareGallery.image_name &&
                  compareGallery.image_path &&
                  prokjectId &&
                  <figure slot="second" className="after">
                  <img
                    className="w-100"
                    src={`${urlpath}/${prokjectId}/styleGen/${compareGallery.image_path}`}
                    alt="After" 
                  />
                  <figcaption>After</figcaption>
                </figure>
                  }

                
              </ImgComparisonSlider>
            </div>
          ) : (
          
            <div className="gen-ai-img">

{
                originalImage &&
                originalImage.image_name &&
                originalImage.image_path &&
                prokjectId &&
                jobId &&
               
                
                 <div className="w-100">
                  <img src={`${path}${prokjectId}/${jobId}/${originalImage.image_path}`} alt="no image" />
                </div>
              }

              
            </div>
          )}

          {/* Toggle Switch */}
          <div className="gen-ai-container mt-3">
          <div className="gen-ai-body d-flex "  onClick={handleOpenShareModal}>
              <div className="gen-ai-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-download"
                  viewBox="0 0 16 16">
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                </svg>
                <span>Download</span>
              </div>

              <div className="gen-ai-btn" >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-share"
                  viewBox="0 0 16 16"
                 
                  >
                  <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3" />
                </svg>
                <span>Share </span>
              </div>
            </div>
            
            <div className="d-flex">
              <button className="btn switch-btn">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    onChange={handleSwitchChange}
                    checked={showComparison}
                  />
                  <label htmlFor="flexSwitchCheckDefault">Compare</label>
                </div>
              </button>
            </div>
          </div>

          {/* Gallery Section */}
          <div className="row g-3 mt-3 pro-gallery-img">
            
              <GenAiGalleryImages
              startCompare={handleComparegenAiImage}
              />
          </div>
        </div>
        </div>


        {isOpenModal &&
        <GenAiCanvasModel
        openModal={isOpenModal}
        onclose={handleCloseModel}
        />}
        <GenAiFeatureModal/>
        

       
      
    </>
  );
};

export default GenAiCanvas;
