import React, { useEffect, useRef } from 'react'
import { ProjectCommentModel } from '../../../../Model/comments/CommentsModel'
import { AddUserComments } from '../../../../api/projectComments/ProjectCommentsApi'
import { useDispatch } from 'react-redux'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'
import { addProjectComment, updateProjectComment } from '../../../../slice/projectCommet/ProjectCommentSlice'
import axios from 'axios'


type Props={
  addMessageValue:ProjectCommentModel
  resetAddComment:()=>void
}
const AddComments = ({addMessageValue,resetAddComment}:Props) => {
  const isApi= useRef(true)
   const dispatch= useDispatch()
  useEffect(()=>{
    if(addMessageValue && isApi.current){
      isApi.current= false
      messageAdd(addMessageValue)
      
    }
  },[addMessageValue])


  const messageAdd=async(addMessageValue:ProjectCommentModel)=>{
    try{
    const response= await AddUserComments(addMessageValue)
       if(response  && response.status==200){
       // console.log("response", response)

           dispatch(addMessage({
            isShow:true,
         mess:"comment added successfully",
          toastType:"success"
           }))

            dispatch(updateProjectComment({
              updateMessage: response.data

            }))
           isApi.current= true
           resetAddComment()
       }
    }catch(err){
      if(axios.isAxiosError(err)){
            
        dispatch(addMessage({
            isShow:true,
            mess: err.response?.data.message,
            toastType:"Error"
           }))
    }
       isApi.current= true
       resetAddComment()
    }

  }
  return (
    <div></div>
  )
}

export default AddComments