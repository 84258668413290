import React, { useEffect, useRef } from 'react'
import { SegmentModel } from '../../../../Model/segment/SegmentModel'
import { AddNewSegment, EditSegment } from '../../../../api/segment/SegmentApi'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'
import { updateSegmentData } from '../../../../slice/segment/SegmentSlice'

type Props={
    segmentData:SegmentModel
    type:string
    resetApiCall:()=>void
}
const AddUpdateApi = ({segmentData, type,resetApiCall}:Props) => {
    const dispatch= useDispatch()
    const isApi=useRef(true)

    useEffect(()=>{
        if(isApi.current && segmentData &&type){
            if(type=="edit"){
                isApi.current=false
                addUpdateSegmentData(segmentData,EditSegment)
            }else {
                isApi.current=false
                addUpdateSegmentData(segmentData,AddNewSegment)
            }
            
        }
    },[isApi,type,segmentData])



    const addUpdateSegmentData=async(data:SegmentModel,functionName:Function)=>{

        try{
           
            const response = await functionName(data);
            console.log("response ad and edit ", response)
            if(response.status===200){
                   dispatch(updateSegmentData({
                          id:response.data.id,
                          data:response.data
                   }))
                isApi.current=true
                resetApiCall()
            }
        }catch(err){
           
            if(axios.isAxiosError(err)){
            
                dispatch(addMessage({
                    isShow:true,
                    mess: err.response?.data.message,
                    toastType:"Error"
                   }))

                  
            }
            isApi.current=true
            resetApiCall()
        }
    }
  return (
    <>
    </>
  )
}

export default AddUpdateApi