import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JobModel, JobSegmentModel, Segment } from "../../Model/Job/JobModel";
import { JobSegmentationModel, SegmentInfoModel } from "../../Model/masterArray/MasterArrayModel";
import { CurrentSwatch, SegmentDetails } from "../../Model/Job/SamModel";
import { SwatchesModel } from "../../Model/material/MaterialModel";
import { ProjectImage } from "../../Model/mediaModel/MediaModel";
import { JobBase64Model } from "../../Model/Job/ExtraModel";
import { StartUpateMasterArrayModel } from "../../Model/masterArray/UpdateIndexModel";
import { debug } from "util";

interface userJobStates {
    
    userJobData:JobModel,
    isUserJobAPiHit:boolean,
    selectedSegment:JobSegmentationModel[],
    isSelected:boolean,
    currentSwatch:CurrentSwatch[],
    genAiImage:ProjectImage[],
    
    allSwatchImages:SwatchesModel[],
    
    isUpload:boolean
    jobBase64:JobBase64Model
    base64?:string|null,
    isCacheImage:boolean
    
}

const initialState: userJobStates = {
    userJobData:{},
    isSelected:false,
    selectedSegment:[],
    allSwatchImages:[],
    genAiImage:[],
    currentSwatch:[], 
    isUserJobAPiHit:false,
    
    base64:"",
    isUpload:false,
    jobBase64:{},
    isCacheImage:false
   

};

const UserJobSlice = createSlice({
    name: "userJob",
    initialState,
    reducers: {
        addJobData:(state,action)=>{
            state.userJobData= action.payload
            state.isUserJobAPiHit= true
        },
        resetJobData:(state)=>{
            state.userJobData={}
        },
       
       
        updateJobId:(state, action)=>{
            const {job}=action.payload;
            state.userJobData.id=job.id
        }, 
        resetUserJob:(state)=>{
            state.userJobData={}
            state.selectedSegment=[]
           
            state.currentSwatch=[]
            state.genAiImage=[]
           // state.information={}
            state.base64=null
            
        },
        updateBase64:(state,action)=>{
            const {base64, isUpload}=action.payload
            state.base64=base64
            //state.isUpload=isUpload
        },
        resetBase64:(state)=>{
            state.base64=null
        },

        resetUpload:(state)=>{
       state.isUpload= false
        },
        addSelectedSegment:(state,action)=>{
            state.selectedSegment=action.payload
            
            
        }, 
        updateIsSelected:(state,action)=>{
            state.isSelected=action.payload
        },
        resetSelectedSegment:(state)=>{
            state.selectedSegment=[]
        },
       
        addCurrentSwatch: (state, action) => {
            const { segName, swatchData } = action.payload;
            const swatchIndex = state.currentSwatch.findIndex(item => item.name === segName);
            if (swatchIndex !== -1 && state.currentSwatch[swatchIndex]?.allSwatch) {
              state.currentSwatch[swatchIndex].allSwatch.push(swatchData);
            } else {

              state.currentSwatch.push({
                name: segName,
                allSwatch: [swatchData], // Initialize allSwatch with the new swatchData
              });
            }
          },
          
        addAllSwatchImages:(state,action)=>{
            state.allSwatchImages=action.payload
        }, 
        resetAllSwatchImages:(state)=>{
            state.allSwatchImages=[]
        },
       
         addGenAi:(state,action)=>{
            const {allImages}=action.payload
            const allData:ProjectImage[]=[]
           
            allImages.map((item:ProjectImage)=>{
                const match = item.image.match(/styleGen\/([^_]+)/);
                if(match && match.length>0){
                    const data:ProjectImage={
                        name:match[1]??"",
                        image:item.image,
                        size:item.size,
                        lastModified:item.lastModified
                    }
                    allData.push(data)
                }
               
            })
            if(allData.length>0){
                state.genAiImage=allData
            }
            
        },
       
        // stopIsStartIndexing:(state)=>{
        //  state.isStartIndexing= false
        // },
        searchJobBase64:(state,action)=>{
            const {imagePath,projectId,jobId}= action.payload
            state.jobBase64.imagePath=imagePath
            state.jobBase64.projectId= projectId
            state.jobBase64.jobId= jobId
        }, 
        resetJobBase64:(state)=>{
            state.jobBase64={}
        },
        updateChacheImage:(state,action)=>{
            state.isCacheImage=action.payload
        }

    }
}
)

export const {addJobData,resetJobData,updateJobId,
    resetUserJob,
    updateBase64,
    resetUpload,
    addSelectedSegment,
    resetSelectedSegment,
    addCurrentSwatch, 
    
    addAllSwatchImages,
    resetBase64,
    addGenAi,
    searchJobBase64,
    resetJobBase64,
    resetAllSwatchImages,
    updateChacheImage,
}=UserJobSlice.actions;
export const getUserJobData=  (state:{userJob:userJobStates})=>state.userJob.userJobData

export const getUserJobBase64=  (state:{userJob:userJobStates})=>state.userJob.base64

export const getUserJobBase64Upload=  (state:{userJob:userJobStates})=>state.userJob


export const getSelectedSegment = (state: { userJob: userJobStates }): JobSegmentationModel[]=> {
    return state.userJob.selectedSegment|| [];
}
export const getCurrentTransImage = (state: { userJob: userJobStates }): CurrentSwatch[]=> {
    return state.userJob.currentSwatch|| [];
}


export const getAllSwatchImages = (state: { userJob: userJobStates }):SwatchesModel []=> {
    return state.userJob.allSwatchImages|| [];
}

export const getGenAiImage = (state: { userJob: userJobStates }): ProjectImage[]=> {
    return state.userJob.genAiImage|| [];
}
export const getJobBase64 = (state: { userJob: userJobStates }): JobBase64Model=> {
    return state.userJob.jobBase64|| {};
}
export const getProjectId = (state: { userJob: userJobStates }): number=> {
    return state.userJob.userJobData.project_id|| 0;
}
export const getIsSelected = (state: { userJob: userJobStates }): boolean=> {
    return state.userJob.isSelected|| false;
}

export const getIsCacheImage = (state: { userJob: userJobStates }): boolean=> {
    return state.userJob.isCacheImage|| false;
}

export default UserJobSlice.reducer