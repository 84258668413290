import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import EditSegmentForm from './EditSegentForm'
import { SegmentModel } from '../../../../Model/segment/SegmentModel'

type Props = {
    show: boolean
    onCancel: () => void
    editData:SegmentModel

}
const EditHome = ({show, onCancel,editData}:Props) => {
    

    const handleClose = () => {
        onCancel()
    }
  return (
    <>
     <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Segment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <EditSegmentForm
              type='edit'
              editData={editData}
              closeEdit={handleClose}
            />
        </Modal.Body>
        <Modal.Footer>
          {/* <button onClick={handleClose}>
            Close
          </button>
          <button >Save</button> */}
        </Modal.Footer>
      </Modal>
    
    </>
  )
}

export default EditHome