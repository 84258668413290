import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { useDispatch, useSelector } from "react-redux";
import { getJobBase64, getUserJobBase64 } from "../../../slice/userJobSlice/UserJobSlice";
import { getSegregateSegment } from "../../../slice/segegratedSlice/SegregatedSlice";
import {
  changeSegmentTab,
  getTabControl,
  switchToOutline,
} from "../../../slice/tabControl/TabControlSlice";
import { getAllSegment } from "../../../slice/segment/SegmentSlice";
import { getSwitchCanvas } from "../../../slice/tabControl/TabControlSlice";
import { Segment } from "../../../Model/Job/JobModel";
import { JobSegmentModel } from "../../../Model/Job/JobModel";
import { CanvasControlModel, PointModel } from "../../../Model/masterArray/MasterArrayModel";
import { addCanavasControl } from "../../../slice/canvas/masterArray/CanvasControlSlice";
import { addComment } from "../../../slice/projectCommet/ProjectCommentSlice";
import CommentHome from "../../ImageView/RightSection/comment/CommentHome";
import Tools from "../../ImageView/RightSection/tools/Tools";
import { UpdateToolActive } from "../../../slice/toolActive/ToolActiveSlice";
import { CanvasImage } from "../canvasUtil/SetImageAsBackground";
import { collectPoints } from "../canvasUtil/CreatePolygon";
import { checkMouseInsidePolygon, GetGroupObject } from "../canvasUtil/CheckMousePointInsidePolygon";
import { hideAnnotationOnCanvas } from "../canvasUtil/HideAnnotation";
import { showAnnotationOnCanvas } from "../canvasUtil/ShowAnnotation";
import { getCachedBase64Image } from "../canvasUtil/ConvertImageToBAse64";

interface PointAnnotation {
  x: number;
  y: number;
}

interface CustomGroupOptions extends fabric.IGroupOptions {
  groupName?: string;
  subGroupName?: string;
  childGroupName?: string; // Example for an object
}
const CommentsCanvas = () => {
  const canvasRef = useRef<fabric.Canvas | null>(null);
 
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);
  
  const canvasElementRefs = useRef<HTMLCanvasElement | null>(null);
  const rightSectionRef = useRef<HTMLDivElement | null>(null);
const containerRef = useRef(null);
  const getUserJobBase64s = useSelector(getUserJobBase64);
  const [scaleX, setScalex] = useState<number | null | undefined>();
  const [scaleY, setScaley] = useState<number | null | undefined>();
  const getSegregateSegments = useSelector(getSegregateSegment);
  const [annotationPoint, setAnnotationPoint] = useState<PointAnnotation[]>([]);
  const isCanvas = useRef(true);
  const isFillPolygon = useRef(true);
  const [top, setTop] = useState<number | null | undefined>();
  const [left, setLeft] = useState<number | null | undefined>();
  const dispatch = useDispatch();
  const getTabControls = useSelector(getTabControl);
  const getSwitchCanvass = useSelector(getSwitchCanvas);
  const getJobBase64s= useSelector(getJobBase64)
  const getAllSegments = useSelector(getAllSegment);

  // Clean up canvas on unmount
  useEffect(() => {
    return () => {
      if (canvasRef.current) {
        canvasRef.current.dispose();
        canvasRef.current = null;
      }
    };
  }, []);
  // Handle canvas disposal on switch
  useEffect(() => {
    if (getSwitchCanvass === "segment" && 
      canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null; // Clear the reference after disposal
      dispatch(changeSegmentTab("segment"));
    } else if (getSwitchCanvass === "dimension" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null; // Clear the reference after disposal
      dispatch(changeSegmentTab("dimension"));
    } else if (getSwitchCanvass === "compare" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null; // Clear the reference after disposal
      dispatch(changeSegmentTab("compare"));
    } else if (getSwitchCanvass === "genAiImage" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null; // Clear the reference after disposal
      dispatch(changeSegmentTab("genAiImage"));
    }
  }, [getSwitchCanvass, dispatch]);

  // Initialize the canvas when the tab is set to "outline"
  useEffect(() => {
    if (
      getTabControls === "comments" &&
      !canvasRef.current &&
      canvasElementRefs.current
    ) {
      isCanvas.current = true;
      generateCanvas();
    }
  }, [getTabControls, canvasRef]);

   // Adjust canvas dimensions to fit the container
      const resizeCanvas = (fabricCanvas: fabric.Canvas) => {
        const container = containerRef.current;
        if (container) {
          const { offsetWidth, offsetHeight } = container;
    
          fabricCanvas.setWidth(offsetWidth);
          fabricCanvas.setHeight(offsetHeight);
          fabricCanvas.renderAll();
        }
      };
    
  
    const generateCanvas=()=>{
    
   // Initialize Fabric canvas
   const rightSection = document.querySelector('.canvas-right') as HTMLElement| null;
   
   if (!rightSection) {
     console.warn('Element with class .canvas-right not found');
   }
   const canvas = new fabric.Canvas(canvasElementRefs.current, {
    //  width:rightSection==null? window.innerWidth: window.innerWidth - rightSection.offsetWidth, // Adjust width
    //  height: window.innerHeight,
     backgroundColor: '#f0f0f0', // Canvas background
   });
  
  
   const imgGroup = new fabric.Group([], { 
     selectable: false, 
     name: 'imageGroup',
     visible: true ,
     hasBorders: false,
       hasControls: false,
   });
  
    // Set initial canvas size
    resizeCanvas(canvas);
  
    // Resize the canvas when the window resizes
    const handleResize = () => resizeCanvas(canvas);
    //window.addEventListener("resize", handleResize);
  
   // Set the canvasRef to the Fabric.js canvas instance
   
   canvas.add(imgGroup);
   canvas.requestRenderAll();
   canvasRef.current = canvas;
   canvas.on('mouse:over', (event) => {
     
     handleMouseOver(event);
   });
   canvas.on('mouse:out',
     (event) => {
       handleMouseOut(event);
    });
    canvas.on("mouse:move", (event) => {
      handleMouseMove(event);
    });
    canvas.on("mouse:down", (event) => {
      handleMouseDown(event);
    });
    
  
      return () => {
        canvas.off("mouse:over", handleMouseOver);
        canvas.off("mouse:out", handleMouseOut);
        canvas.off("mouse:move", handleMouseMove);
        canvas.off("mouse:down", handleMouseDown);
       // canvas.off("mouse:up", handleMouseUp);
      };
    };

  //handle mouse down
  const handleMouseDown = (event: fabric.IEvent) => {
    handleGroupClick(event);
  };



  // Load the background image based on the job base64
  useEffect(() => {
    if (
      getJobBase64s &&
      getJobBase64s.imagePath &&
      canvasRef.current &&
      getTabControls === "comments"
    ) {

      const base64Image= getCachedBase64Image(getJobBase64s.imagePath )
 
    if(base64Image){
 
      CanvasImage(base64Image, canvasRef, setScalex, setScaley, setTop, setLeft);
       
    }
    
    }
  }, [getJobBase64s, getTabControls]);


  // Handle Segregate Segments and draw polygons
  useEffect(() => {
    if (
      canvasRef.current &&
      getSegregateSegments &&
      getSegregateSegments.length > 0 &&
      getAllSegments &&
      getAllSegments.length > 0 &&
      scaleX &&
      scaleY &&
      top 
    ) {
      let lefts=0
      getSegregateSegments.forEach((segmentModel: JobSegmentModel) => {
        Object.keys(segmentModel).forEach((key) => {
          const jobDetail = segmentModel[key] as Segment;
          const annotation = jobDetail?.details?.annotation;
          const cordinate = jobDetail?.details?.bb_annotation_int;
          const segName = key;
          const textName = jobDetail?.details?.seg_short ?? "";
          const groupName = jobDetail?.details?.seg_type ?? "";
          const subGroupName = jobDetail?.details?.group ?? "";
          const childName = jobDetail?.details?.seg_short ?? "";
          const segColor = getAllSegments.find(
            (item) => item.name === groupName
          );
          const color = segColor?.color_code;
          if (annotation && cordinate && segName && color) {
            collectPoints(
              annotation,
              segName,
              cordinate,
              groupName,
              subGroupName,
              childName,
              textName,
              color,
              scaleX,
              scaleY,
              canvasRef,
              isFillPolygon?.current,
              top,
              lefts,
        
            );
          }
        });
      });
    }
  }, [canvasRef.current, getSegregateSegments, scaleX, scaleY, getAllSegments,left, top]);

 
//  handle mouse move
  const handleMouseMove = (event: fabric.IEvent) => {
    const pointer = canvasRef.current?.getPointer(event.e);
     console.log("event---",event.target)
    //console.log("isFillPolygon",isFillPolygon.current)
    if(pointer){
      const isColor= false;
      const fabricPoint = new fabric.Point(pointer.x, pointer.y);
      console.log("pointer.x---",pointer.x)
      console.log("pointer.y---",pointer.y)
      setPositionX(pointer.x);
    setPositionY(pointer.y)
    checkMouseInsidePolygon(fabricPoint,canvasRef,isColor,hideAnnotation,showAnnotation,isFillPolygon)
   }
   
    

  };

  const isColor=useRef(false)
  
const showAnnotation=(segName:string,color:string)=>{
 
  showAnnotationOnCanvas(canvasRef,segName,color,isFillPolygon,isColor)
}

  const hideAnnotation=(segName:string)=>{
    hideAnnotationOnCanvas(segName, canvasRef, isFillPolygon);
   }
  // return to main canavs
  const handlehoverLayer = () => {
    dispatch(UpdateToolActive(0))
    dispatch(switchToOutline("segment"))
    dispatch(changeSegmentTab('segment'))
   
  };

  const handleMouseOut = (event: fabric.IEvent) => {
    
  };

  // const showAnnotation = (name: string) => {
  //   console.log("Annotation name",name)
  //   const targetname = name;
  //   const allObjects = canvasRef.current?.getObjects();
  //   if (allObjects && allObjects.length > 0) {
  //     const currentObject = allObjects.find((item) => item.name == targetname);
  //     // console.log("currentObject",currentObject)
  //     const currentObjGroup = currentObject as fabric.Group;
  //     const showObject = currentObjGroup
  //       ?.getObjects()
  //       .filter((item) => item.name != "pattern");

  //     if (showObject) {
  //       showObject?.forEach((obj) => {
  //         if (!obj.visible) {
  //           obj.set({
  //             visible: true,
  //           });
  //         }
  //         if (obj.opacity === 0.1) {
  //           obj.set({
  //             opacity: 10,
  //             visible: true,
  //           });
  //         }

  //         canvasRef.current?.requestRenderAll();
  //       });
  //     } else {
  //       // console.log(" no currentObject",currentObject)
  //       //hideAnnotation(targetname)
  //     }
  //   }
  // };

  // on mouse hover
  const handleMouseOver = (event: fabric.IEvent) => {
    //  console.log("event",event)
    //  console.log("canvasRef.current",canvasRef.current)
    if (event.target) {
      const targetnames = event.target?.name;
      if (targetnames) {
       // showAnnotation(targetnames);
      }
    }
  };
  // hide hover effect
  

  const handleGroupClick = (data: fabric.IEvent) => {
    console.log("data--->",data)
    const pointer = canvasRef.current?.getPointer(data.e);
  
    if(pointer){
      const fabricPoint = new fabric.Point(pointer.x, pointer.y);
      let isComment = true;
      GetGroupObject(fabricPoint, canvasRef,isComment,canvasControl);
    }
  };

  const canvasControl=(data: CanvasControlModel)=>{
    dispatch(addCanavasControl(data));
    dispatch(addComment(data));
  }
  return (
    <>
      <div className="editor-canvas position-relative  overflow-hidden"
        ref={containerRef}
        style={{
          height: "100vh",
          overflow: "hidden",
          transformOrigin: "top left",
        }}
      >
        <div className="re-anno-section" onClick={handlehoverLayer}>
          <button className=" d-flex  align-items-center btn custom-back-button rounded-pill border-0 fs-6">
            <span className="fs-4">
              <i className="bi bi-arrow-left-short pe-1"></i>
            </span>
            Comments
          </button>
          <button className=" d-flex  align-items-center btn custom-back-button rounded-pill border-0 fs-6"
           style={{marginLeft:650, marginTop:-50}}
          >
            <span className="fs-4">
              {/* <i className="bi bi-arrow-left-short pe-1"></i> */}
            </span>
            <span>position x:{positionX}</span>
            <span>position y:{positionY}</span>
          </button>

        </div>
        <canvas ref={canvasElementRefs} className="dzi-van" />
        <Tools/>
      </div>
      <CommentHome />
     
    </>
  );
};

export default CommentsCanvas;
