import React, { useEffect, useState } from "react";
import {
  NameModel,
  SearchSwatchModel,
} from "../../../../../Model/material/MaterialModel";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryName } from "../../../../../slice/materialSlice/MaterialCategorySlice";
import {
  getAllBrandName,
  getSearchBrandName,
} from "../../../../../slice/materialSlice/MaterialBrandSlice";
import {
  getSearchStyleName,
  getStyleName,
} from "../../../../../slice/materialSlice/MaterialStyleSlice";
import CallSearchApi from "./CallSearchApi";
import SearchBrand from "./searchBrand/SearchBrand";
import { updateIsSearch } from "../../../../../slice/materialSlice/MaterialSlice";

import { startLoading } from "../../../../../slice/loading/LoadingSlice";

import { addSearchCatId, getSearchBrandIds, getSearchCatId, resetAllSearchFilter, updateIsFilter, updateIsStartFilter, updateIsStyleSearching, updateResetSearch } from "../../../../../slice/materialSlice/SearchMaterial";



import SelectSegment from "./segment/SelectSegment";
import MultipleSelect from "./segment/MultipleSelect";
import SearchStyleBrandBased from "./searchStyle/SearchStyleBrandBased";
import SearchSwatchBased from "../../../../ImageView/LeftSections/segmentbasedSwatch/searchSwatch/searchSwatchApi/SearchSwatchBased";

const SearchSwatches = () => {
  const dispatch = useDispatch();

  const [segmentType, setSegmentType] = useState<string>("");

  const [categorySearch, setCategorySearch] = useState<NameModel>({});

  const [brandSearch, setBrandSearch] = useState<NameModel[]>([]);

  const [styleSearchTerm, setStyleSearchTerm] = useState("");
  const [styleSearchId, setStyleSearchId] = useState<number | null>(null);

  const [allStyleName, setAllStyleName] = useState<NameModel[]>([]);
  const [allCatName, setAllCatName] = useState<NameModel[]>([]);
  const [allBrandeName, setAllBrandeName] = useState<NameModel[]>([]);

  const [searchSwatch, setSearchSwatch] = useState<SearchSwatchModel | null>(
    null
  );

  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [isBrand, setIsBrand] = useState<boolean>(false);
  const [isStyle, setIsStyle] = useState<boolean>(false);
  const [isResetSearch, setIsResetSearch] = useState<boolean>(false);
  const getSearchBrandNames = useSelector(getSearchBrandName);
  const getSearchCatIds = useSelector(getSearchCatId);
  const getSearchBrandIdss = useSelector(getSearchBrandIds);
  const getSearchStyleNames = useSelector(getSearchStyleName);

  useEffect(() => {
    if (getSearchStyleNames?.length > 0) setAllStyleName(getSearchStyleNames);
  }, [getSearchStyleNames]);

  useEffect(() => {
    if (getSearchBrandNames?.length > 0) setAllBrandeName(getSearchBrandNames);
  }, [getSearchBrandNames]);

  // start search brands

  useEffect(() => {

    if (getSearchCatIds&&
        getSearchCatIds.id &&
        getSearchCatIds.title) {
            setCategorySearch(getSearchCatIds);
            dispatch(updateIsFilter(true));
            setIsBrand(true);
        }
   

  }, [getSearchCatIds]);

  // start search all style
  useEffect(() => {
    if (getSearchBrandIdss && getSearchBrandIdss.length > 0) {
      setIsStyle(true);
    }
  }, [getSearchBrandIdss]);


  const handleSegmentBAsedCategory = (data: NameModel[], type:string) => {
   // console.log("data==>", data);
    setAllCatName(data);
    setSegmentType(type)
  }


  const handleResetStyle = () => {
    dispatch(updateIsStyleSearching(false));
    setIsStyle(false);
  };

  const handleReset = () => {

    dispatch(resetAllSearchFilter());
    dispatch(updateResetSearch(true))
    setIsResetSearch(true)

    // setCategorySearchId(null)
    // setBrandSearchId(null);
    // setStyleSearchId(null);
    // setCategorySearchTerm("");
    // setBrandSearchTerm("");
    // setStyleSearchTerm("");
    // dispatch(updateIsSearch(false));
  };

  const handleSearchCatChange = () => {
    setIsBrand(false);
    dispatch(updateIsFilter(false));
    // console.log("event", e.target.value);
    // setCategorySearchTerm(e.target.value);

    // if (allCatName && allCatName.length > 0) {
    //     const filteredCategories = allCatName.filter((item: NameModel) =>
    //         item?.title?.toLowerCase().includes(e.target.value.toLowerCase())
    //     );
    //     if(filteredCategories &&
    //          filteredCategories.length>0){
    //         setCategorySearchTerm(filteredCategories[0]?.title??"");
    //     }

    //     //setAllCatName(filteredCategories)
    //     //console.log("Filtered Categories: ", filteredCategories);
    // }
  };

  const handleSearch = () => {
    dispatch(startLoading());
    dispatch(updateIsSearch(true));
    const data: SearchSwatchModel = {
      //   catId:categorySearchId??0,
      //    brandId:brandSearchId??0,
      //   styleId: styleSearchId??0
    };
    setSearchSwatch(data);
    setIsSearch(true);
  };

  const handleResetSearch = () => {
    setIsSearch(false);
    setSearchSwatch(null);
  };

  const handleResetsearchBrand = () => {
    setIsBrand(false);
  };
  return (
    <>
    
        <div className="d-flex align-items-center">
          {/* select segemnt */}

          <SelectSegment 
          allCategory={handleSegmentBAsedCategory}
           />

         

         


               
               {/* category */}
               
               <MultipleSelect
               title={"Category"}
                searchKey={"category"}
                searchOptions={allCatName}
                segmentType={segmentType}
                
               />
               {/* Brands */}

               <MultipleSelect
               title={"Brand"}
                searchKey={"brand"}
                searchOptions={allBrandeName}
                segmentType={segmentType}
                
               />
               {/* Style */}

               <MultipleSelect
               title={"Style"}
                searchKey={"style"}
                searchOptions={allStyleName}
                segmentType={segmentType}
                
               />



        <div className="sea-button mx-4">
          <button className="btn btn-primary" onClick={handleSearch}>
            Search
          </button>

        </div>

           <div className="d-flex">
           <div className="sea-button ">
            <button className="btn  btn-outline-primary" onClick={handleSearch}>
              Search
            </button>
          </div>
          <div className="sea-button mx-3">
            <button className="btn btn-outline-primary" onClick={handleReset}>
              Reset
            </button>
          </div>

          <button
            type="button"
            className="btn btn-primary"
            // onClick={handleAddSwatch}
            >
            Create new entry
          </button>
          </div>

          </div>
        
      {isSearch && searchSwatch && (
        <CallSearchApi
          searchSwatch={searchSwatch}
          resetSearchSwatch={handleResetSearch}
        />
      )}

      {/* serach brand based on category */}
      {isBrand && categorySearch && (
        <SearchBrand
          catId={categorySearch}
          resetSearch={handleSearchCatChange}
        />
      )}

      {/* search style based on brand */}
      {isStyle && getSearchBrandIdss && getSearchBrandIdss.length > 0 && (
        <SearchStyleBrandBased
          brandId={getSearchBrandIdss}
          resetSearchStyle={handleResetStyle}
        />
      )}

      <SearchSwatchBased />
      </>
  );
};

export default SearchSwatches;
