import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearchBrandName } from "../../../../../../slice/materialSlice/MaterialBrandSlice";
import {
  addBrandIdSearchSwatch,
  addSearchBrandId,
  getIsFilter,
  getSearchCatId,
} from "../../../../../../slice/materialSlice/SearchMaterial";
import { NameModel } from "../../../../../../Model/material/MaterialModel";
import { getGroupName } from "../../../../../../slice/toolActive/ToolActiveSlice";
import { GetCetegoryColor } from "../util/getCategoryColor";
import { getMasterArray } from "../../../../../../slice/canvas/masterArray/MasterArraySlice";

type Props = {
  addSelectecdBrand: (brands: NameModel[]) => void;
};
const BrandSearch = ({ addSelectecdBrand }: Props) => {
  const dispatch = useDispatch();
  const [allBrandName, setAllBrandName] = React.useState<NameModel[]>([]);
  const [color, setColor] = React.useState<string | null | undefined>(null);

  const getSearchBrandNames = useSelector(getSearchBrandName);
  const getSearchCatIds = useSelector(getSearchCatId);
  const [brands, setBrands] = React.useState<NameModel[]>([]);
  // const [selectedBrands, setSelectedBrands] = React.useState<number[]>([])
  const getGroupNames = useSelector(getGroupName);

  const getMasterArrays = useSelector(getMasterArray);
  const getIsFilters = useSelector(getIsFilter);

  // update filter when close
  useEffect(() => {
    if (!getIsFilters) {
      setBrands([]);
    }
  }, [getIsFilters]);

  // set the color
  useEffect(() => {
    if (
      getMasterArrays &&
      getMasterArrays.allSeg &&
      getMasterArrays.allSeg.length > 0
    ) {
      GetCetegoryColor(getMasterArrays.allSeg, setColor);
    }
  }, [getMasterArrays]);

  // update the brand Name
  useEffect(() => {
    if (getSearchBrandNames && getSearchBrandNames.length > 0) {
      setAllBrandName(getSearchBrandNames);
    } else {
      setAllBrandName([]);
    }
  }, [getSearchBrandNames]);

  const handleSelectBrand = (brandValue: NameModel) => {
    if (brandValue && brandValue.id && brandValue.title) {
      dispatch(
        addSearchBrandId({
          id: brandValue.id,
        })
      );

      if (brands.some((brand) => brand.id === brandValue.id)) {
        const brd = brands.filter((brand) => brand.id !== brandValue.id);
        setBrands(brd);
        addSelectecdBrand(brd);
      } else {
        addSelectecdBrand([...brands, brandValue]);
        setBrands([...brands, brandValue]);
      }
      // dispatch(updateIsStyleSearching(true));
    }
  };

  // update the brand in redux
  useEffect(() => {
    if (getSearchCatIds?.id && brands.length > 0 && getGroupNames) {
      dispatch(
        addBrandIdSearchSwatch({
          type: getGroupNames,
          catId: getSearchCatIds.id,
          brands: brands,
        })
      );

      setTimeout(() => {
        // dispatch(updateIsStyleSearching(true));
      }, 3000);
      // dispatch(updateIsStyleSearching(true));
      // dispatch(updateIsSawatchSeaxrching(false));
    }
  }, [brands, getSearchCatIds?.id, getGroupNames]); // added optional chaining to getSearchCatIds.id

  return (
    <>
      <div className="d-flex flex-wrap align-items-center gap-2">
        {getSearchBrandNames &&
          getSearchBrandNames.length > 0 &&
          getSearchBrandNames.map((item) => {
            let selectedBrand: string = "";
            if (brands && brands.length > 0) {
              brands.map((brand) => {
                if (brand.id === item.id) {
                  selectedBrand = brand.title ?? "";
                }
              });
            }
            return (
              
              <div
                className=" rounded-pill py-1 px-2 select-brand"
                key={item.id}
                style={{
                  border: `1px solid ${color}`,
                  borderRadius: "6px",

                  backgroundColor:
                    item.title === selectedBrand ? `${color}50` : "transparent",
                  transition: "background-color 0.3s ease",
                }}
                onClick={() => handleSelectBrand(item)} // Add click handler to span
              >
                <label
                  className="form-check-label"
                  htmlFor={`checkbox-${item.id}`}>
                  {item.title}
                </label>
                     
                <svg
                    className="checkicon md"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    ></circle>
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    ></path>
                  </svg>   
            
              </div>
            );
          })}
      </div>
    </>
  );
};

export default BrandSearch;
