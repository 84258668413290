import axios from 'axios'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addModelData } from '../../../slice/modelData/ModelDataSlice'
import { updateBase64 } from '../../../slice/userJobSlice/UserJobSlice'
import { getNewJobId, getUserNewProject, getUserProject } from '../../../slice/user/UserProject'
import { stopLoading } from '../../../slice/loading/LoadingSlice'
import { addMessage } from '../../../slice/messageToast/ToastSlice'
import { getSettingPath } from '../../../slice/settingPathSlice/SettingPathSlice'

const backendUrl =process.env.REACT_APP_BACKEND_URL
 const urlpath= process.env.REACT_APP_Project_Image
type Props={
    url:string,
    isBase64:boolean,
    resetCallApi:(data:string)=> void
}
const ModelApi = ({url,isBase64,resetCallApi}:Props) => {
  const getUserNewProjects= useSelector(getUserNewProject)
     const isApi= useRef(true)
      const dispatch= useDispatch()
     const getSettingPaths= useSelector(getSettingPath)
     const getNewJobIds= useSelector(getNewJobId)

     useEffect(()=>{
      
        if(url &&
          getUserNewProjects.id &&
           isApi.current &&
           getSettingPaths &&
           getSettingPaths.length>0 &&
           getNewJobIds){
            let path
             const projectPath= getSettingPaths.filter((data)=>data.title==="Project Image")
             if(projectPath.length>0){
                 path=projectPath[0].path
                 callAIModel(url,getUserNewProjects.id,getNewJobIds,path??"")
                 isApi.current= false
             }
          
        }
     },[url,getUserNewProjects])

    const callAIModel=  async (url:string, projectId:number, jobId:number,path:string)=>{
          
        
        try{
    const urlpaths=`${path}${projectId}/${jobId}/${url}`
   
         const apiPath="beta/beta-object-url"
            const modelData = await axios.post(
                `${backendUrl}/${apiPath}?url=${urlpaths}`,{
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });
                
               console.log("modelData from ",modelData)
                
              if(modelData && modelData.status===200){
                 
                // localStorage.setItem("samData", JSON.stringify(modelData.data));
                if(isBase64){
                    dispatch(updateBase64(modelData.data.Base64))
                    resetCallApi("success")
                }else{
                    dispatch(addModelData(modelData.data))
                    resetCallApi("success")
                }
                 
              }
             
        }catch(err){
          if(axios.isAxiosError(err)){
            
            dispatch(addMessage({
                isShow:true,
                mess: err.response?.data.message,
                toastType:"Error"
               }))
        }
           //console.log("error model ",err)
            dispatch(stopLoading())
            resetCallApi("fail")
        }
    }
  return (
    <div>
        
    </div>
  )
}

export default ModelApi