import React, { useEffect } from 'react'
import ShowProjects from '../../module/projectPage/ProjectHome'
import GetAllSwatchImage from '../../module/ImageView/LeftSections/segmentbasedSwatch/GetAllSwatchImage'
import { useDispatch, useSelector } from 'react-redux'
import { getDoorSwatches, getGarageSwatches, getGutterSwatches, getRoofSwatches, getTrimSwatches, getWallSwatches, getWindowSwatches, updateRefetchDoor, updateRefetchGarage, updateRefetchGutter, updateRefetchRoof, updateRefetchTrim, updateRefetchWall, updateRefetchWindow } from '../../slice/swatches/SwatchSlices'

const Projects = () => {
  const dispatch = useDispatch()
    
  const getDoorSwatchess= useSelector(getDoorSwatches)
  const getWindowSwatchess= useSelector(getWindowSwatches)
  const getRoofSwatchess= useSelector(getRoofSwatches)
  const getGarageSwatchess= useSelector(getGarageSwatches)
  const getGutterSwatchess= useSelector(getGutterSwatches)
  const getWallSwatchess= useSelector(getWallSwatches)
  const getTrimSwatchess= useSelector(getTrimSwatches)



  // fectch for door
  useEffect(() => {
    // start fetching door swatches
      if(getDoorSwatchess && getDoorSwatchess.length==0){
        dispatch(updateRefetchDoor(true))
      }
      
      if (getWindowSwatchess && getWindowSwatchess.length === 0) {
        dispatch(updateRefetchWindow(true))
    }
     
     if(getRoofSwatchess && getRoofSwatchess.length==0){
        dispatch(updateRefetchRoof(true))
     }
    
     if(getGarageSwatchess && getGarageSwatchess.length==0){
        dispatch(updateRefetchGarage(true))
     }

     if(getGutterSwatchess && getGutterSwatchess.length==0){
        dispatch(updateRefetchGutter(true))
     }
     if(getWallSwatchess && getWallSwatchess.length==0){
        dispatch(updateRefetchWall(true))
     }

     if(getTrimSwatchess && getTrimSwatchess.length==0){
        dispatch(updateRefetchTrim(true))
     }
     // stop fetching wall swatches
     dispatch(updateRefetchTrim(true)) // stop fetching trim swatches
  },[getDoorSwatchess,getWindowSwatchess,
    getRoofSwatchess,getGarageSwatchess,
    getGutterSwatchess,getWallSwatchess,
    getTrimSwatchess])

  return (
    <div className='p-0'>
        <ShowProjects/>

        
        <GetAllSwatchImage/>
    </div>
  )
}

export default Projects