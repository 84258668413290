import React, { useEffect, useRef, useState, useCallback } from "react";
import CreateProjectFrom from "../../createproject/CreateProjectFrom";
// import EditProjectFrom from "../EditProjectFrom"
import CreateProjectModel from "../CreateProjectModel";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "antd";
import {
  Card,
  Input,
  InputRef,
  Table,
  TableColumnsType,
  TablePaginationConfig,
} from "antd";
import Search from "antd/es/transfer/search";
import LeftSection from "../../leftSection/LeftSection";
import EditCreateProject from "../EditCreateProject";
import EditCreateproject from "./SegEditCreateproject";
import SegEditCreateproject from "./SegEditCreateproject";
import { SegmentModel } from "../../../Model/segment/SegmentModel";
import { useSelector } from "react-redux";
import { getAllSegment } from "../../../slice/segment/SegmentSlice";
import Loading from "../../loading/Loading";
import { Image } from "antd";
import SearchAdd from "../../dasboardpages/components/pageContent/SearchAdd";
import Sidebar from "../../dasboardpages/components/Sidebar";
import PageContentHeader from "../../dasboardpages/components/pageContent/PageContentHeader";
import DataTable from 'react-data-table-component';
import EditSegmentForm from "./edit/EditSegentForm";
import EditHome from "./edit/EditHome";
import AddSegment from "./addSegment/AddSegment";

interface TableData {
  name: string;

  labels: string;
  media: string;
  color: string;
  colorcode: string;

  state: string;
}


const SegmentTableData = () => {
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    {}
  );
 
  const [selectAll, setSelectAll] = useState(false);
  const [allSegment, setAllSegments] = useState<SegmentModel[]>([]);
   const[isEdit, setIsEdit]= useState(false)
   const [recordEdit, setRecordEdit]= useState<SegmentModel>()  
  const [isOpenModal, setIsOpenModal] = useState(false);
  const getAllSegments = useSelector(getAllSegment);
 
  // getAll Segments from rrdux
  useEffect(() => {
    if (getAllSegments && getAllSegments.length > 0) {
      setAllSegments(getAllSegments);
    }
  }, [getAllSegments]);
  const handleSelectAllChange = () => {
    const newCheckedItems: { [key: string]: boolean } = {};

    setCheckedItems(newCheckedItems);
    setSelectAll(!selectAll);
  };

  const [isEditProjectFrom, setIsProEditForm] = useState(false);

  const handleEditSegment = (record:SegmentModel) => {
    //console.log("handleEditSegment");
    setIsEdit(true)
    setRecordEdit(record)
    //navigate("/segments/edit");
  };

  const [isActive, setIsActive] = useState(false); // State to track whether the div is active
  const [firstHeader, setFirstHeader] = useState<string>("Material Library");
  const [secondHeader, setSecondHeader] = useState<string>("Swatch");

  const [breadCum1, setbreadCum1] = useState<string>("Home");
  const [breadCum2, setbreadCum2] = useState<string>("Library");
  const [breadCum3, setbreadCum3] = useState<string>("Swatch");

  const toggleActiveClass = () => {
    setIsActive(!isActive); // Toggle the isActive state
  };

  const handleSearch = useCallback((value: string) => {
    // Implement debouncing logic here if necessary
    console.log(value);
  }, []);

  
    const handleAddSegment=()=>{
      setIsOpenModal(true)
    }
  const columns = [
    {
      name: "Name",
      // selector: (row: Country) => row.name, // Selector returns a primitive value
      selector: (row: SegmentModel) => row.name??"", 
      sortable: true,
    },
    
    {
      name: "Media",
      cell: (row: SegmentModel) => {
       // console.log("row", row.icon)
        return (
          <img
            src={row.icon ?? ""}
            alt="No Image"
            style={{ width: "50px", height: "50px"}}
          />
        );
      },
    },
    {
      name: "Color",
      // selector: (row: Country) => row.nativeName,
       selector: (row: SegmentModel) => row.color??"",

      sortable: true,
    },
    {
      name: "short Code",
      // selector: (row: Country) => row.nativeName,
       selector: (row: SegmentModel) => row.short_code??"",

      sortable: true,
    },
    {
      name: "Color Code",
      // selector: (row: Country) => row.nativeName,
       selector: (row: SegmentModel) => row.color_code??"",

      sortable: true,
    },
    
    {
      name: "Index",
      // cell: (row: Country) => [<CiIndent />, <CiEdit />], // Use cell for custom rendering
       selector: (row: SegmentModel) => row.index??"",

    },
    {
      name: "Active",
      // cell: (row: Country) => [<CiIndent />, <CiEdit />], // Use cell for custom rendering
       selector: (row: SegmentModel) => row.isActive?"true":"false",

    },
    {
      name: "Action",
      cell: (row: SegmentModel) => (
        <>
<div className="d-flex action">
                  <div className="action-icon-table" 
                  onClick={() => handleEditSegment(row)}
                  >
                    <i className="bi bi-pencil-square mx-0"></i>
                  </div>
                  <div className="action-icon-table">
                    <i className="bi bi-copy mx-0"></i>
                  </div>
                  <div className="action-icon-table">
                    <i className="bi bi-box-arrow-up-right mx-0"></i>
                  </div>
                  <div className="action-icon-table trash-icon-media"
                  // onClick={() => handleDelete(row)}
                   >
                    <i className="bi bi-trash3 mx-0"></i>
                  </div>
                </div>
                </>)
      }, 
  ];

  return (
    <>
    <div className='card mx-3'>

<div className='card-header bg-white p-2 d-flex justify-content-between align-items-center'>
          <input
            type="search"
            className="form-control w-auto"
            placeholder="Search"
          />
          <button type='button' className='btn btn-primary'
           
            onClick={handleAddSegment}
           >Create new entry</button>
        </div>

     <div className='card-body p-0'>
      {allSegment && allSegment.length == 0 ? (
        <Loading />
      ) : ( 

        <DataTable 
        columns={columns} 
        data={allSegment}
        pagination
        fixedHeader
      />

    

      )}
      </div>
    </div>

    {/*  edit form  */}

    {isEdit &&recordEdit &&
    
    <EditHome
    show={isEdit}
    onCancel={()=>setIsEdit(false)}
    editData={recordEdit}
    />}


 <AddSegment
  isOpenModal={isOpenModal}
  resetModal={()=>setIsOpenModal(false)}
 /> 

    </>

    
    
  );
};

export default SegmentTableData;
