import React, { useEffect, useState } from 'react';
import { SegmentModel } from '../../../../Model/segment/SegmentModel';
import AddUpdateApi from './AddUpdateApi';


type Props={
    type:string
    editData:SegmentModel
    closeEdit:()=>void
}
const EditSegmentForm = ({type,editData,closeEdit}:Props) => {
    const [ isCallApi, setIsCallApi ] = useState(false);
    const [newCategory, setNewCategory] = useState('');

  const [segmentData, setSegmentData] = useState<SegmentModel>({
    name: '',
    color: '',
    description: '',
    index: 0,
    isActive: false,
    isVisible: false,
    color_code: '',
    short_code: '',
    icon: '',
    icon_svg: '',
    categories: [],
  });

  useEffect(() => {
    if(type === 'edit' && editData){
      setSegmentData(editData);
    }
  }, [editData, type]);
 
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    const checked = (e.target as HTMLInputElement).checked;
  
    setSegmentData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsCallApi(true)
  };

  const addCategory = () => {
    if (newCategory && 
        segmentData.categories &&
        segmentData.categories.length > 0 &&
        !segmentData.categories.includes(newCategory)) {
      setSegmentData((prevData) => ({
        ...prevData,
        categories: [...prevData?.categories??[], newCategory],
      }));
      setNewCategory('');
    }else if(newCategory && 
        segmentData.categories &&
        segmentData.categories.length== 0){
        setSegmentData((prevData) => ({
            ...prevData,
            categories: [newCategory],
        }));
        }
  };

  const removeCategory = (category: string) => {
    setSegmentData((prevData) => ({
      ...prevData,
      categories: prevData.categories?.filter((cat) => cat !== category) ?? [],
    }));
  };


  const handleResetApiCall=()=>{
    setIsCallApi(false)
    setSegmentData({
      name: '',
      color: '',
      description: '',
      index: 0,
      isActive: false,
      isVisible: false,
      color_code: '',
      short_code: '',
      icon: '',
      icon_svg: '',
      categories: [],
    })
    closeEdit()
  }
  return (
    <>
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="w-full max-w-2xl bg-white rounded-lg shadow-lg">
       

        <form onSubmit={handleSubmit}>
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                <input
                  type="text"
                  name="name"
                  value={segmentData.name}
                  onChange={handleInputChange}
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter wall name"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Color</label>
                <input
                  type="text"
                  name="color"
                  value={segmentData.color}
                  onChange={handleInputChange}
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter color name"
                  required
                />
            
              </div>

              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                <textarea
                  name="description"
                  value={segmentData.description}
                  onChange={handleInputChange}
                  rows={3}
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter description"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Index</label>
                <input
                  type="number"
                  name="index"
                  value={segmentData.index}
                  onChange={handleInputChange} 
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter index"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
                <div className="flex items-center space-x-4 mt-2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="isActive"
                      checked={segmentData.isActive}
                      onChange={handleInputChange}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <label className="ml-2 text-sm text-gray-700">Active</label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="isVisible"
                      checked={segmentData.isVisible}
                      onChange={handleInputChange}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <label className="ml-2 text-sm text-gray-700">Visible</label>
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Color Code</label>
                <input
                  type="color"
                  name="color_code"
                  value={segmentData.color_code}
                  onChange={handleInputChange}
                  className="w-full h-10 rounded-md border border-gray-300 px-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Short Code</label>
                <input
                  type="text"
                  name="short_code"
                  value={segmentData.short_code}
                  onChange={handleInputChange}
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  maxLength={2}
                  placeholder="Enter short code"
                  required
                />
              </div>

              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Icon URL</label>
                <input
                  type="url"
                  name="icon"
                  value={segmentData.icon}
                  onChange={handleInputChange}
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter icon URL"
                />
              </div>

              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Icon SVG</label>
                <textarea
                  name="icon_svg"
                  value={segmentData.icon_svg}
                  onChange={handleInputChange}
                  rows={3}
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 font-mono text-sm"
                  placeholder="Enter SVG code"
                />
              </div>
              <div className="col-span-2">
  <label className="block text-sm font-medium text-gray-700 mb-2">Categories</label>
  <div className="flex flex-wrap gap-2 mb-2">
      { segmentData &&
      segmentData.categories &&
    segmentData?.categories?.length > 0 &&
      segmentData.categories.map((category, index) => (
        <span
          key={index}
          className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800"
        >
          {category}
          <button
            type="button"
            onClick={() => removeCategory(category)}
            className="ml-2 inline-flex items-center"
          >
          
          <i className="bi bi-x-lg"></i>
          </button>
        </span>
      ))}
  </div>
  <div className="flex gap-2">
    <input
      type="text"
      value={newCategory}
      onChange={(e) => setNewCategory(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          addCategory();
        }
      }}
      className="flex-1 rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      placeholder="Add a category"
    />
    <button
      type="button"
      onClick={addCategory}
      className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
    >
      Add
    </button>
  </div>
</div>
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-link text-secondary text-decoration-none" data-bs-dismiss="modal">Close</button>
            <button type="submit" className="btn btn-primary"  >Save changes</button>
          </div>
        </form>
      </div>
    </div>

      {
        isCallApi && (
         <AddUpdateApi
         segmentData={segmentData}
          type={type}
          resetApiCall={handleResetApiCall}
         />
        )
      }
    </>
    
  );
};

export default EditSegmentForm;