import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CatBrandStyleSwatchModel } from "../../Model/material/CatBrandStyleSwatchModel";


interface SwatchesStates {
    doorSwatches:CatBrandStyleSwatchModel[]
    windowSwatches:CatBrandStyleSwatchModel[]
    roofSwatches:CatBrandStyleSwatchModel[]
    garageSwatches:CatBrandStyleSwatchModel[]
    gutterSwatches:CatBrandStyleSwatchModel[]
    wallSwatches:CatBrandStyleSwatchModel[]
    trimSwatches:CatBrandStyleSwatchModel[]
    isRefetchDoor:boolean
    isRefetchWindow:boolean
    isRefetchRoof:boolean
    isRefetchGarage:boolean
    isRefetchGutter:boolean
    isRefetchWall:boolean
    isRefetchTrim:boolean
    

   
    
}

const initialState: SwatchesStates = {
doorSwatches:[],
windowSwatches:[],
roofSwatches:[],
garageSwatches:[],
gutterSwatches:[],
wallSwatches:[],
trimSwatches:[],
isRefetchDoor:false,
isRefetchWindow:false,
isRefetchRoof:false,
isRefetchGarage:false,
isRefetchGutter:false,
isRefetchWall:false,
isRefetchTrim:false,

};

const SwatchesSlice = createSlice({
    name: "swatches",
    initialState,
    reducers: {
       addDoorSwatches:(state,action)=>{
        const {door}=action.payload;
           door.forEach((item:CatBrandStyleSwatchModel)=>{
            state.doorSwatches.push({
                ...item,
                isSelected: false
            });
           })
            //state.doorSwatches=action.payload
       },
       updateRefetchDoor:(state,action)=>{
        state.isRefetchDoor=action.payload
       },
       addWindowSwatches:(state,action)=>{
        const {window}=action.payload;
        window.forEach((item:CatBrandStyleSwatchModel)=>{
            state.windowSwatches.push({
                ...item,
                isSelected: false
            });
           })
        //state.windowSwatches=action.payload
       },
         updateRefetchWindow:(state,action)=>{
          state.isRefetchWindow=action.payload
         },
       addRoofSwatches:(state,action)=>{
        const {roof}=action.payload;
        roof.forEach((item:CatBrandStyleSwatchModel)=>{
            state.roofSwatches.push({
                ...item,
                isSelected: false
            });
           })
        // state.roofSwatches=action.payload
       },
         updateRefetchRoof:(state,action)=>{
          state.isRefetchRoof=action.payload
         },
       addGarageSwatches:(state,action)=>{
        const {garage}=action.payload;
        garage.forEach((item:CatBrandStyleSwatchModel)=>{
            state.garageSwatches.push({
                ...item,
                isSelected: false
            });
           })
        // state.garageSwatches=action.payload
        },
        updateRefetchGarage:(state,action)=>{
            state.isRefetchGarage=action.payload
           },
        addGutterSwatches:(state,action)=>{
            const {gutter}=action.payload;
            gutter.forEach((item:CatBrandStyleSwatchModel)=>{
                state.gutterSwatches.push({
                    ...item,
                    isSelected: false
                });
               })

           // state.gutterSwatches=action.payload
        },
        updateRefetchGutter:(state,action)=>{
            state.isRefetchGutter=action.payload
           },
        addWallSwatches:(state,action)=>{
            const {wall}=action.payload;
            wall.forEach((item:CatBrandStyleSwatchModel)=>{
                state.wallSwatches.push({
                    ...item,
                    isSelected: false
                });
               })
            //state.wallSwatches=action.payload
        },  
        updateRefetchWall:(state,action)=>{
            state.isRefetchWall=action.payload
           },
        addTrimSwatches:(state,action)=>{
            const {trim}=action.payload;
            trim.forEach((item:CatBrandStyleSwatchModel)=>{
                state.trimSwatches.push({
                    ...item,
                    isSelected: false
                });
               })
           // state.trimSwatches=action.payload
        },
        updateRefetchTrim:(state,action)=>{
            state.isRefetchTrim=action.payload
           },
        resetDoorSwatches:(state)=>{
            state.doorSwatches=[]
            state.isRefetchDoor=false
        },  
        resetWindowSwatches:(state)=>{
            state.windowSwatches=[]
            state.isRefetchWindow=false
        },  
        resetRoofSwatches:(state)=>{
            state.roofSwatches=[]
            state.isRefetchRoof=false
        },
        resetGarageSwatches:(state)=>{
            state.garageSwatches=[]
            state.isRefetchGarage=false
        },
        resetGutterSwatches:(state)=>{
            state.gutterSwatches=[]
            state.isRefetchGutter=false
        },  
        resetWallSwatches:(state)=>{
            state.wallSwatches=[]
            state.isRefetchWall=false
        },
        resetTrimSwatches:(state)=>{
            state.trimSwatches=[]
            state.isRefetchTrim=false
        }, 
        updateSelectedSwatch:(state,action)=>{
            const {groupName , swatchId}=action.payload
            const id= parseInt(swatchId)
            debugger

            if(groupName==="Door"){
               const doorIndex= state.doorSwatches.findIndex((item)=>item.id===swatchId)    
               if(doorIndex!==-1){
                state.doorSwatches[doorIndex].isSelected=true
               }

        } 
           else if (groupName==="Window" ){
            const id= parseInt(swatchId)
            const windowIndex= state.windowSwatches.findIndex((item)=>item.id===swatchId)
            if(windowIndex!==-1){
                state.windowSwatches[windowIndex].isSelected=true
            }
        }
            else if(groupName=="Wall" ){
                const wallIndex= state.wallSwatches.findIndex((item)=>item.id===swatchId)
                if(wallIndex!==-1){
                    state.wallSwatches[wallIndex].isSelected=true
                }
                
            }
        else if(groupName=="Roof"){
            const roofIndex= state.roofSwatches.findIndex((item)=>item.id===swatchId)
            if(roofIndex!==-1){
                state.roofSwatches[roofIndex].isSelected=true
            }

           
        }
        else if(groupName=="Garage"){
            const garageIndex= state.garageSwatches.findIndex((item)=>item.id===swatchId)
            if(garageIndex!==-1){
                state.garageSwatches[garageIndex].isSelected=true
            }
        }

        else if(groupName=="Gutter"){
            const gutterIndex= state.gutterSwatches.findIndex((item)=>item.id===swatchId)
            if(gutterIndex!==-1){
                state.gutterSwatches[gutterIndex].isSelected=true
            }
        }
        else if(groupName=="Trim"){
            const trimIndex= state.trimSwatches.findIndex((item)=>item.id===swatchId)
            if(trimIndex!==-1){
                state.trimSwatches[trimIndex].isSelected=true
            }
        }
    }
    }
}
)

export const {
    addDoorSwatches,resetDoorSwatches,updateRefetchDoor,
    addWindowSwatches,resetWindowSwatches,updateRefetchWindow,
    addRoofSwatches,resetRoofSwatches,updateRefetchRoof,    
    addGarageSwatches,resetGarageSwatches,updateRefetchGarage,
    addGutterSwatches,resetGutterSwatches,updateRefetchGutter,
    addWallSwatches,resetWallSwatches,updateRefetchWall,
    addTrimSwatches,resetTrimSwatches,updateRefetchTrim,
    updateSelectedSwatch
}=SwatchesSlice.actions;
export const getDoorSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.doorSwatches;
export const getWindowSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.windowSwatches;
export const getRoofSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.roofSwatches;
export const getGarageSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.garageSwatches;
export const getGutterSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.gutterSwatches;
export const getWallSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.wallSwatches;
export const getTrimSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.trimSwatches;


export const getIsRefetchDoor=  (state:{swatches:SwatchesStates})=>state.swatches.isRefetchDoor;
export const getIsRefetchWindow=  (state:{swatches:SwatchesStates})=>state.swatches.isRefetchWindow;
export const getIsRefetchRoof=  (state:{swatches:SwatchesStates})=>state.swatches.isRefetchRoof;
export const getIsRefetchGarage=  (state:{swatches:SwatchesStates})=>state.swatches.isRefetchGarage;
export const getIsRefetchGutter=  (state:{swatches:SwatchesStates})=>state.swatches.isRefetchGutter;
export const getIsRefetchWall=  (state:{swatches:SwatchesStates})=>state.swatches.isRefetchWall;
export const getIsRefetchTrim=  (state:{swatches:SwatchesStates})=>state.swatches.isRefetchTrim;

export default SwatchesSlice.reducer