import axios from "axios";
import { ProjectModel, UpdateProjectModel } from "../../Model/project/ProjectModel";
import { SegmentModel } from "../../Model/segment/SegmentModel";

const databaserServer="http://localhost:5000"

 const serverApi =process.env.REACT_APP_SERVER_URL
// const serverApi ="https://ztrbpzguq1.execute-api.us-east-2.amazonaws.com/dev"

export function GetallSegments() {

   // return axios.get(`${serverApi}/projects`,)
    return axios.get(`${serverApi}/segment/get-all`,)
        .then(response => {
            // console.log("segment", response.data)
            return response
        }).catch(error => {
            throw error;
        });


}


export function UpdateSegment(data:SegmentModel){
    return axios.post(`${serverApi}/segment/update`,data)
    .then(response => {
        // console.log("update segment", response)
        return response
    }).catch(error => {
        throw error;
    });
}


export function EditSegment(data:SegmentModel){
    
    return axios.post(`${serverApi}/segment/edit`,data)
    .then(response => {
       
        return response
    }).catch(error => {
        throw error;
    });
}

export function AddNewSegment(data:SegmentModel){
    
    return axios.post(`${serverApi}/segment/add`,data)
    .then(response => {
       
        return response
    }).catch(error => {
        throw error;
    });
}



// delete segments
export function DeleteSegment(id:number){

    const data={
        id:id
    }
    return axios.post(`${serverApi}/segment/delete`,data)
    .then(response => {
        console.log("delete segment", response)
        return response
    }).catch(error => {
        throw error;
    });
}