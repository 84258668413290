import { fabric } from "fabric";

interface ExtendedGroup extends fabric.Group {
  isActived?: boolean;
}
export const showAnnotationOnCanvas = (
  canvasRef: React.RefObject<fabric.Canvas>,
  name: string,
  color: string,
  isFillPolygon: React.MutableRefObject<boolean>,
  isColor: React.MutableRefObject<boolean>
) => {
  const targetname = name;
  const allObjects = canvasRef.current?.getObjects();
 
  if (allObjects && allObjects.length > 0) {
    const currentObject = allObjects.find((item) => item.name == targetname);
    const currentObjGroup = currentObject  as ExtendedGroup;
    const showObject = currentObjGroup
      ?.getObjects()
      .filter((item) => item.name != "pattern");
  
    currentObjGroup?.set({isActived: true});
    if (showObject) {

      showObject?.forEach((obj) => {
        if (!obj.visible) {
          obj.set({
            visible: true,
          });
        }

        if (obj.opacity === 0.1) {
          obj.set({
            opacity: isFillPolygon.current ? 0.3 : 10,
            visible: true,
            fill: isColor? color : "transparent",
          });
        }

        canvasRef.current?.requestRenderAll();
      });
    }
  }
};

