import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";

import "./AddGroupModel.scss";
import {
  getAddSegmentGroup,
  getGroupList,
  updateChildAddSegment,
  updateGroup,
} from "../../../../slice/canvas/groupSlice.tsx/GroupSlice";
import { useNavigate } from "react-router-dom";
import {
  AddSubGroupModel,
  MainGroupModel,
} from "../../../../Model/groupingModel/GroupingModel";
import {
  addGroupOnSegmentation,
  getMasterArray,
} from "../../../../slice/canvas/masterArray/MasterArraySlice";
import { changeSegmentTab } from "../../../../slice/tabControl/TabControlSlice";
import ReactDOMServer from "react-dom/server";

import {
  GroupMasterSegment,
  MasterArrayModel,
  MasterModel,
} from "../../../../Model/masterArray/MasterArrayModel";
import AnnotationList from "./AnnotationList";
import { getSegregateSegment } from "../../../../slice/segegratedSlice/SegregatedSlice";
import { getTargetAddSegment } from "../../../../slice/canvas/updatevalue/UpdateValueSlice";
import {
  addGroupName,
  addSubGroupName,
} from "../../../../slice/toolActive/ToolActiveSlice";
type Props = {
  isSegmentModal: boolean;
  handleClose: () => void;
  selectedSegment: boolean;
  ok: (groupName: string, childGroupName: string, shortName: string) => void;
};
const AddGroupModel = ({
  isSegmentModal,
  selectedSegment,
  handleClose,
  ok,
}: Props) => {
  const getGroupLists = useSelector(getGroupList);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [groupList, setGroupList] = useState<MainGroupModel | null>(null);
  const getMasterArrays = useSelector(getMasterArray);
  const [jobId, setJobId] = useState<number | null>(null);
  const [segmentArray, setSegmentArray] = useState<MasterModel | null>(null);
  const getSegregateSegments = useSelector(getSegregateSegment);
  const getAddSegmentGroups = useSelector(getAddSegmentGroup);
  const [segName, setsegName] = useState<string | null>(null);
  const [segShortName, setSegShortName] = useState<string | null>(null);
  const [groupName, setGroupName] = useState<string | null>(null);
  const [childGroupName, setChildGroupName] = useState<string | null>(null);
  const [selectedRadio, setSelectedRadio] = useState<string | null>(null);

  const getTargetAddSegments = useSelector(getTargetAddSegment);
  // get job id
  useEffect(() => {
    if (getMasterArrays && getMasterArrays.jobId) {
      setJobId(getMasterArrays.jobId);
      setSegmentArray(getMasterArrays);
    }
  }, [getMasterArrays]);

  useEffect(() => {
    if (
      getSegregateSegments &&
      getSegregateSegments.length > 0 &&
      getAddSegmentGroups
    ) {
      const childGroupName = getAddSegmentGroups.childGroupName;
      const groupName = getAddSegmentGroups.groupName;
      const childGroups = getSegregateSegments.filter((item) => {
        return Object.keys(item).some(
          (key) => item[key].details?.seg_type === groupName
        );
      });

      if (childGroups.length > 0 && childGroupName && groupName) {
        const childobj = childGroups.filter((item) => {
          return Object.keys(item).some(
            (key) => item[key].details?.seg_name === childGroupName
          );
        });
        if (childobj) {
          const seg_short = childobj[0]?.[childGroupName].details?.seg_short;
          if (seg_short) {
            const newSegShort =
              seg_short.replace(/[0-9]/g, "") + (childGroups.length + 1);
            setSegShortName(newSegShort);
            setsegName(groupName + (childGroups.length + 1));
            setGroupName(groupName);
            setChildGroupName(childGroupName);
            // console.log("newSegname",newSegShort)
            //console.log("newSegname",groupName+(childGroups.length+1))
          }
        }
      }
    }
  }, [getSegregateSegments, getAddSegmentGroups]);

  const [activeSubmenuIndex, setActiveSubmenuIndex] = useState<{
    [key: number]: number | null;
  }>({});
  // Function to handle submenu click
  const handleSubmenuClick = (parentIndex: number, submenuIndex: number) => {
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));
  };

  // popover start
  const iconRef = useRef<HTMLDivElement | null>(null);
  const popoverRef = useRef<any>(null); // To store popover instance

  useEffect(() => {
    if (iconRef.current) {
      const bootstrap = require("bootstrap"); // Import Bootstrap JS

      popoverRef.current = new bootstrap.Popover(iconRef.current, {
        html: true,
        content: ReactDOMServer.renderToString(<AnnotationList />),
        placement: "right",
        customClass: "seg-popover", // Ensure this is not empty
        trigger: "manual",
      });
    }

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Element; // Assert target as Element

      if (iconRef.current && !iconRef.current.contains(target)) {
        popoverRef.current.hide(); // Hide popover if clicked outside
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      if (popoverRef.current) {
        popoverRef.current.dispose(); // Clean up popover on unmount
      }
      document.removeEventListener("mousedown", handleClickOutside); // Clean up event listener
    };
  }, []);

  // Function to handle icon click
  const handleIconClick = () => {
    if (popoverRef.current) {
      popoverRef.current.toggle(); // Toggle popover on icon click
    }
  };

  // popover end

  const [openAccordions, setOpenAccordions] = useState<{
    [key: number]: boolean;
  }>({});

  const toggleAccordion = (index: number) => {
    setOpenAccordions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleSubAddGroup = (
    segmentation: GroupMasterSegment[],
    name: string
  ) => {
    // console.log("segmentation", segmentation)
    // console.log("name", name)
    const newSubGroup = name + (segmentation.length + 1);
    //console.log("newSubGroup", newSubGroup)
    const data: AddSubGroupModel = {
      segmentGroupName: name,
      groupName: newSubGroup,
    };
    // console.log("data", data)
    dispatch(addGroupOnSegmentation(data));
  };

  const handleChildGroup = (group: MasterArrayModel, subGroupName: string) => {
    //  console.log("group",group)
    // console.log("subGroupName",subGroupName)
    const allSeg = group.short_code;
    // console.log("allseg",allSeg)
    dispatch(
      updateChildAddSegment({
        groupName: group.name,
        childGroupName: subGroupName,
        shortName: group.short_code,
        subGroupLength: allSeg?.length,
      })
    );
    const len = group.segmentation?.length;
    //console.log("len",len)
    //console.log("group?.short_code??",group?.short_code??""+(len?len+1:1))

    //setSubGroupName(subGroupName)
    setChildGroupName(subGroupName);
    setGroupName(group.name ?? "");
    if (len && allSeg) {
      setSegShortName(allSeg + len);
    }
    // dispatch(changeSegmentTab("newSegment"))
  };

  //  useEffect(()=>{
  //   console.log("groupName", groupName)
  //   console.log("subgroupName",subgroupName)
  //   console.log("childName",childName)
  //  },[groupName,subgroupName,childName])

  // handle save
  const handleSave = () => {
    // console.log("save")
    //  console.log("groupName",groupName)
    //  console.log("childGroupName",childGroupName)
    //  console.log("segShortName",segShortName)
    if (groupName && childGroupName && segShortName) {
      dispatch(addGroupName(groupName));
      dispatch(addSubGroupName(childGroupName));

      setGroupName(null);
      setChildGroupName(null);
      setSegShortName(null);
      setsegName(null);
      ok(groupName, childGroupName, segShortName);
    }
  };

  return (
    <>
      <Modal
        show={isSegmentModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between align-items-center w-100">
            {/* <h5 className="mb-0">Annotation Editor</h5> */}
            <Modal.Title className="fs-5">Annotation Editor</Modal.Title>

            {/* <h5 >Annotation Editor</h5> */}
            {/* <h5  id="exampleModalLabel">Confirmation</h5> */}

            <div className="d-flex align-items-center me-3 ">
              <span className="dot-icon" ref={iconRef} data-bs-toggle="hover">
                <i
                  className="bi bi-three-dots-vertical"
                  ref={iconRef}
                  data-bs-toggle="popover"
                  onClick={handleIconClick} // Attach click handler
                ></i>
              </span>
              {/* <button type="button" className="btn-close" aria-label="Close"></button> */}
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className="mb-2">
          <span>
            <h5>Please select Segment</h5>
          </span>

          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-section">
                <li className="breadcrumb-item">
                  <a href="#">{groupName}</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">{childGroupName}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {segShortName}
                </li>
              </ol>
            </nav>
          </div>

          {segmentArray &&
            segmentArray.allSeg &&
            segmentArray.allSeg.length > 0 &&
            segmentArray.allSeg.map((item, index) => {
              return (
                <div className="accordion accordion-seg-list mt-2 " key={index}>
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <button
                        className={`accordion-button seg-index-btn ${
                          openAccordions[index] ? "" : "collapsed"
                        }`}
                        type="button"
                        onClick={() => toggleAccordion(index)}>
                        <span className="w-100 m-0 fs-6 fw-semibold">
                          {item.name}
                        </span>

                        <span
                          className="addseg-icon"
                          onClick={(event) => {
                            event.stopPropagation(); // Prevents the click from affecting the accordion
                            handleSubAddGroup(
                              item.segmentation || [],
                              item.name ?? ""
                            );
                          }}>
                          <i
                            className={`bi ${
                              openAccordions[index] ? "bi-plus" : "bi-plus"
                            } ms-auto`}></i>
                        </span>
                      </button>
                    </div>

                    {item.segmentation && item.segmentation.length > 0 && (
                      <div
                        className={`accordion-collapse collapse ${
                          openAccordions[index] ? "show" : ""
                        }`}>
                        <div className="accordion-body p-0">
                          <div className="seg-group-list p-0 m-0">
                            {item.segmentation.map((seg, segIndex) => {
                              const key = Object.keys(seg)[0];
                              return (
                                <div
                                  className="bg-white cursor-move border-bottom ps-4 segmentGroup"
                                  key={segIndex}
                                  onClick={() => {
                                    setSelectedRadio(key);
                                    handleChildGroup(item, key);
                                  }}>
                                  <div className="d-flex align-items-center">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id={`radio-${segIndex}`}
                                      checked={selectedRadio === key}
                                      onChange={() => {}} // Required to avoid React warning about controlled component
                                      // onClick={(e) => e.stopPropagation()} // Prevent double triggering
                                    />
                                    <div className="seg-group-title seg-groups-title">
                                      {key}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}

          <div className="actions-btns d-flex align-items-center justify-content-between mt-4 mb-3">
            <button
              className="btn btn-tranparent border-primary text-primary"
              // disabled={!selectedSegment}
              onClick={handleClose}>
              Delete
            </button>
            <button
              className="btn btn-primary"
              // disabled={!selectedSegment}
              onClick={handleSave}>
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddGroupModel;
