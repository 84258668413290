import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userModel } from "../../Model/user/UserModel";
import { ProjectModel } from "../../Model/project/ProjectModel";

interface ProjectStates {
    userProjects: ProjectModel[];
     originalImagePath:string,
     compressImagePath:string,
     newProject:ProjectModel,
     imageFile:File|null,
     projectResponce:string,
     isReload:boolean,
     newJobId:number
}

const initialState: ProjectStates = {
    userProjects: [],
    originalImagePath:"",
    compressImagePath:"",
    newProject:{},
    imageFile:null,
    projectResponce:"",
    isReload:false,
    newJobId:0
};

const UserProjectSlice = createSlice({
    name: "userProjects",
    initialState,
    reducers: {
        addUserProject: (state, action) => {
            state.userProjects=action.payload;
            state.projectResponce="get all projects"
        },
        addProjectResponse:(state,action)=>{
             state.projectResponce= action.payload
        }, 
        resetProjectResponse:(state)=>{
             state.projectResponce=""
        },
        resetAllProjectSlice:(state)=>{
            state.userProjects= []
            state.compressImagePath=""
            state.compressImagePath=""
            state.newProject={}
            state.imageFile=null
            state.projectResponce=""
        },
        addImageUrl:(state,action)=>{
         state.originalImagePath= action.payload
        },
        addCompressImage:(state,action)=>{
            state.compressImagePath=action.payload
        },
        addImageFile:(state,action)=>{
            state.imageFile=action.payload
        },
        resetImageFile:(state)=>{
            state.imageFile=null
        },
        addNewProject:(state,action)=>{
            state.newProject= action.payload
            
        },
        resetNewProject:(state)=>{
            state.newProject={}
        },
        resetImageName:(state)=>{
            state.originalImagePath=""
        },
        resetUserProject: (state) => {
            state.userProjects = [];
        },
        updateProject:(state, action)=>{
            const {project}= action.payload;
            state.userProjects.push(project)
        }, 
        updateCurrentProjectImage:(state,action)=>{
            const{project}= action.payload;
            const projectIndex= state.userProjects.findIndex(item=>item.id===project.id);
            if(projectIndex!=-1){
                state.userProjects[projectIndex].image=project.image;
                state.newProject.image=project.image
                state.newProject.jobs=project.jobs
            } else if(projectIndex==-1){
                state.userProjects.push(project)
            }
        },
        updateDeletingProject:(state,action)=>{
            const {projectId}=action.payload
        const projectIndex= state.userProjects.findIndex(item=>item.id===projectId)
        if(projectIndex!=-1){
            state.userProjects.splice(projectIndex,1)
        }

        } ,
        addReloadProject:(state,action)=>{
            state.isReload= action.payload
        }
       ,
       updateNewJobId:(state,action)=>{
              state.newJobId= action.payload
       },
       updateProjectname:(state,action)=>{
              const {project}= action.payload;
              const projectIndex= state.userProjects.findIndex(item=>item.id===project.id);
              if(projectIndex!=-1){
                state.userProjects[projectIndex].name=project.name;
              }
       }

        
    },
});

export const {
     addUserProject, resetUserProject,resetAllProjectSlice ,
     updateProject,updateDeletingProject,addImageUrl,addCompressImage,
     resetImageName, updateCurrentProjectImage,
     resetNewProject,addNewProject,addImageFile,
     resetImageFile,
     addProjectResponse,
     resetProjectResponse,
     addReloadProject,
     updateNewJobId,
     updateProjectname
    } = UserProjectSlice.actions;
export const getUserProject = (state: { userProjects: ProjectStates }) => state.userProjects.userProjects;
export const getUserProjectImageUrl = (state: { userProjects: ProjectStates }) => state.userProjects.originalImagePath;
export const getUserProjectCompressUrl = (state: { userProjects: ProjectStates }) => state.userProjects.compressImagePath;
export const getUserNewProject = (state: { userProjects: ProjectStates }) => state.userProjects.newProject;
export const getUserImageFile = (state: { userProjects: ProjectStates }) => state.userProjects.imageFile;
export const getprojectResponse = (state: { userProjects: ProjectStates }) => state.userProjects.projectResponce;


export const getIsReloadProject = (state: { userProjects: ProjectStates }) => state.userProjects.isReload;

export const getNewJobId = (state: { userProjects: ProjectStates }) => state.userProjects.newJobId;
export default UserProjectSlice.reducer;
