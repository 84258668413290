import React, { useEffect, useState } from "react";
import { Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CatBrandStyleSwatchModel } from "../../../../Model/material/CatBrandStyleSwatchModel";
import { getMaterialPath } from "../util/materialPath/MaterialPath";

const s3path = process.env.REACT_APP_S3BucketMaterial;

type Props = {
  show: boolean;
  selctedSwatch: CatBrandStyleSwatchModel | null;
  onclose: () => void;
};

const InformationSwatch = ({ show, selctedSwatch, onclose }: Props) => {
  //const path = `${s3path}/liv/materials`;
  const [copied, setCopied] = useState(false);
  const [newPath, setNewPath] = useState('');

  // update the path based on the new bucket
  useEffect(() => {
    if(selctedSwatch ){
      let path;
      if(selctedSwatch.new_bucket === 0){
        path=getMaterialPath("old material")
         setNewPath(path??'');
      }else if(selctedSwatch.new_bucket === 1){
       path=getMaterialPath("materials")
       setNewPath(path??'');
      }else{
        path=getMaterialPath("old material")
        setNewPath(path??'');
      }
    
    }
  },[selctedSwatch])



  const handleClose = () => {
    onclose();
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(err => console.error('Failed to copy: ', err));
  };

  const handleImageCopy = () => {
    const imageUrl = `${newPath}${selctedSwatch?.photo}`;
    navigator.clipboard.writeText(imageUrl)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(err => console.error('Failed to copy: ', err));
  };

  const handleCategoryCopy = () => {
    const categoryTitle = selctedSwatch?.category?.title || "N/A";
    navigator.clipboard.writeText(categoryTitle)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(err => console.error('Failed to copy: ', err));
  };
  
  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        className="bottom_side_new h-auto">
        <Offcanvas.Header closeButton>
          {selctedSwatch ? (
            <Offcanvas.Title className="text-center">
              {selctedSwatch.title || "No Title Available"}
            </Offcanvas.Title>
          ) : (
            <Offcanvas.Title className="text-center">No Swatch Selected</Offcanvas.Title>
          )}
        </Offcanvas.Header>
        <Offcanvas.Body>
          {selctedSwatch && selctedSwatch.id ?
          
          
         
            (
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="rounded border shadow sticky-top">
                    <div className="d-flex align-items-center justify-content-between p-3 py-2 border-bottom">
                      <h6 className="pe-2 m-0">Image</h6>
                      <div className="d-flex gap-3">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip
                              className="custom-tooltip"
                              id={`tooltip-imageUrl`}>
                              {copied ? "Copied!" : "Copy"}
                            </Tooltip>
                          }>
                          <i
                            className="bi bi-copy text-primary ms-2"
                            onClick={handleImageCopy}
                           ></i>
                        </OverlayTrigger>
                      </div>
                    </div>

                    <div className="p-3 ">
                      <img
                        className="rounded-3 m-auto "
                        src={`${newPath}${selctedSwatch?.photo}`}
                        alt="Selected Swatch"
                      />

                          <input
                                type="text"
                                className="form-control bg-secondary mt-3" // Add margin-top for spacing
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Image url"
                                value={`${newPath}${selctedSwatch.photo}`} // Set the input value to the image URL
                                readOnly // Make the input read-only if you want to prevent editing
                              />
                    </div>
                  </div>
                </div>

                {/* <div className='col-12 col-md-1 d-flex align-items-center justify-content-center'>
                    <span className='h-100 border border-1'></span>
                  </div> */}

                <div className="col-12 col-md-8">
                  <div className="row g-3">
                    <div className="col-6">
                      <div className=" rounded border shadow">
                        <div className="d-flex align-items-center justify-content-between p-3 py-2 border-bottom">
                          <h6> Category</h6>
                          <div className="d-flex gap-3">
                            <i
                              className="bi bi-pencil-square text-primary"
                              // onClick={() => handleEdit(key)}
                            ></i>
                            <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip
                              className="custom-tooltip"
                              id={`tooltip-category`}>
                              {copied ? "Copied!" : "Copy"} {/* Show copied status */}
                            </Tooltip>
                          }>
                          <i
                            className="bi bi-copy text-primary ms-2"
                            onClick={handleCategoryCopy} // Call copy function on click
                            ></i>
                        </OverlayTrigger>
                          </div>
                        </div>
                        <div className="p-3 py-2">
                          <p className="data-text m-0 text-truncate">
                            {selctedSwatch.category?.title || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className=" rounded border shadow">
                        <div className="d-flex align-items-center justify-content-between p-3 py-2 border-bottom">
                          <h6> Category Id</h6>

                          <div className="d-flex gap-3">
                            <i
                              className="bi bi-pencil-square text-primary"
                              // onClick={() => handleEdit(key)}
                            ></i>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                              className="custom-tooltip"
                              id={`tooltip-category`}>
                              {copied ? "Copied!" : "Copy"} {/* Show copied status */}
                            </Tooltip>
                              }>
                              <i
                                className="bi bi-copy text-primary"
                                onClick={() => handleCopy(String(selctedSwatch.category.id) || "N/A")}
                               ></i>
                            </OverlayTrigger>
                          </div>
                        </div>
                        <div className="p-3 py-2">
                          <p className="data-text m-0 text-truncate">
                            {selctedSwatch.category.id || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className=" rounded border shadow">
                        <div className="d-flex align-items-center justify-content-between p-3 py-2 border-bottom">
                          <h6>Brand </h6>                         
                           <div className="d-flex gap-3">
                            <i
                              className="bi bi-pencil-square text-primary"
                            ></i>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                className="custom-tooltip"
                                id={`tooltip-category`}>
                                {copied ? "Copied!" : "Copy"} {/* Show copied status */}
                              </Tooltip>
                              }>
                              <i className="bi bi-copy text-primary"
                                onClick={() => handleCopy(String(selctedSwatch.brand?.title) || "N/A")}
                              
                              ></i>
                            </OverlayTrigger>
                          </div>
                        </div>
                        <div className="p-3 py-2">
                              {/* onClick={() => handleCopy(key)} */}
                          <p className="data-text m-0 text-truncate">
                            {selctedSwatch.brand?.title || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="rounded border shadow">
                        <div className="d-flex align-items-center justify-content-between p-3 py-2 border-bottom">
                          <h6>Brand Id </h6>
                          <div className="d-flex gap-3">
                            <i
                              className="bi bi-pencil-square text-primary"
                            ></i>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                              // onClick={() => handleEdit(key)}
                              <Tooltip
                              className="custom-tooltip"
                              id={`tooltip-category`}>
                              {copied ? "Copied!" : "Copy"} {/* Show copied status */}
                            </Tooltip>
                              }>
                              <i className="bi bi-copy text-primary"
                                onClick={() => handleCopy(String(selctedSwatch.brand?.id) || "N/A")}
                              ></i>
                            </OverlayTrigger>
                          </div>
                        </div>
                              {/* id={`tooltip-${key}`}>{copied[key] ? 'Copied!' : 'Copy'}</Tooltip>} */}
                        <div className="p-3 py-2">
                              {/* onClick={() => handleCopy(key)} */}
                          <p className="data-text m-0 text-truncate">
                            {selctedSwatch.brand?.id || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="rounded border shadow">
                        <div className="d-flex align-items-center justify-content-between p-3 py-2 border-bottom">
                          <h6>Style </h6>

                          <div className="d-flex gap-3">
                            <i
                              className="bi bi-pencil-square text-primary"
                            ></i>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                              // onClick={() => handleEdit(key)}
                              <Tooltip
                              className="custom-tooltip"
                              id={`tooltip-category`}>
                              {copied ? "Copied!" : "Copy"} {/* Show copied status */}
                            </Tooltip>
                              }>
                              <i className="bi bi-copy text-primary"
                                onClick={() => handleCopy(String(selctedSwatch.style?.title) || "N/A")}
                              
                              ></i>
                            </OverlayTrigger>
                          </div>
                        </div>
                              {/* id={`tooltip-${key}`}>{copied[key] ? 'Copied!' : 'Copy'}</Tooltip>} */}
                        <div className="p-3 py-2">
                              {/* onClick={() => handleCopy(key)} */}
                          <p className="data-text m-0 text-truncate">
                            {selctedSwatch.style?.title || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="rounded border shadow">
                        <div className="d-flex align-items-center justify-content-between p-3 py-2 border-bottom">
                          <h6>Style Id </h6>
                          <div className="d-flex gap-3">
                            <i
                              className="bi bi-pencil-square text-primary"
                            ></i>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                              // onClick={() => handleEdit(key)}
                              <Tooltip
                              className="custom-tooltip"
                              id={`tooltip-category`}>
                              {copied ? "Copied!" : "Copy"} {/* Show copied status */}
                            </Tooltip>
                              }>
                              <i className="bi bi-copy text-primary"
                                onClick={() => handleCopy(String(selctedSwatch.style?.id) || "N/A")}
                              
                              ></i>
                            </OverlayTrigger>
                          </div>
                        </div>
                              {/* id={`tooltip-${key}`}>{copied[key] ? 'Copied!' : 'Copy'}</Tooltip>} */}
                        <div className="p-3 py-2">
                              {/* onClick={() => handleCopy(key)} */}
                          <p className="data-text m-0 text-truncate">
                            {selctedSwatch.style?.id || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="rounded border shadow">
                        <div className="d-flex align-items-center justify-content-between p-3 py-2 border-bottom">
                          <h6> Swatch </h6>
                          <div className="d-flex gap-3">
                            <i
                              className="bi bi-pencil-square text-primary"
                            ></i>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                              // onClick={() => handleEdit(key)}
                              <Tooltip
                              className="custom-tooltip"
                              id={`tooltip-category`}>
                              {copied ? "Copied!" : "Copy"} {/* Show copied status */}
                            </Tooltip>
                              }>
                              <i className="bi bi-copy text-primary " 
                                onClick={() => handleCopy(String(selctedSwatch.title) || "N/A")}
                              ></i>
                            </OverlayTrigger>
                          </div>
                        </div>
                              {/* id={`tooltip-${key}`}>{copied[key] ? 'Copied!' : 'Copy'}</Tooltip>} */}
                        <div className="p-3 py-2">
                              {/* onClick={() => handleCopy(key)} */}
                          <p className="data-text m-0 text-truncate">
                            {selctedSwatch.title || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="rounded border shadow">
                        <div className="d-flex align-items-center justify-content-between p-3 py-2 border-bottom">
                          <h6>Swatch Id </h6>
                          <div className="d-flex gap-3">
                            <i
                              className="bi bi-pencil-square text-primary"
                            ></i>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                              // onClick={() => handleEdit(key)}
                              <Tooltip
                              className="custom-tooltip"
                              id={`tooltip-category`}>
                              {copied ? "Copied!" : "Copy"} {/* Show copied status */}
                            </Tooltip>
                              }>
                              <i className="bi bi-copy text-primary" 
                                onClick={() => handleCopy(String(selctedSwatch.id) || "N/A")}
                              ></i>
                            </OverlayTrigger>
                          </div>
                        </div>
                              {/* id={`tooltip-${key}`}>{copied[key] ? 'Copied!' : 'Copy'}</Tooltip>} */}
                        <div className="p-3 py-2">
                              {/* onClick={() => handleCopy(key)} */}
                          <p className="data-text m-0 text-truncate">
                            {selctedSwatch.id || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="rounded border shadow">
                        <div className="d-flex align-items-center justify-content-between p-3 py-2 border-bottom">
                          <h6>Swatch path </h6>
                          <div className="d-flex gap-3">
                            <i
                              className="bi bi-pencil-square text-primary"
                            ></i>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                              // onClick={() => handleEdit(key)}
                              <Tooltip
                              className="custom-tooltip"
                              id={`tooltip-category`}>
                              {copied ? "Copied!" : "Copy"} {/* Show copied status */}
                            </Tooltip>
                              }>
                              <i className="bi bi-copy text-primary" 
                                onClick={() => handleCopy(String(selctedSwatch.photo) || "N/A")}
                              ></i>
                            </OverlayTrigger>
                          </div>
                        </div>
                              {/* id={`tooltip-${key}`}>{copied[key] ? 'Copied!' : 'Copy'}</Tooltip>} */}
                        <div className="p-3 py-2">
                              {/* onClick={() => handleCopy(key)} */}
                          <p className="data-text m-0 text-truncate">
                            {selctedSwatch.photo || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="rounded border shadow">
                        <div className="d-flex align-items-center justify-content-between p-3 py-2 border-bottom">
                          <h6>modified </h6>
                          <div className="d-flex gap-3">
                            <i
                              className="bi bi-pencil-square text-primary"
                            ></i>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                              // onClick={() => handleEdit(key)}
                              <Tooltip
                              className="custom-tooltip"
                              id={`tooltip-category`}>
                              {copied ? "Copied!" : "Copy"} {/* Show copied status */}
                            </Tooltip>
                              }>
                              <i className="bi bi-copy text-primary"
                                onClick={() => handleCopy(String(selctedSwatch.modified) || "N/A")}
                              ></i>
                            </OverlayTrigger>
                          </div>
                        </div>
                              {/* id={`tooltip-${key}`}>{copied[key] ? 'Copied!' : 'Copy'}</Tooltip>} */}

                              {/* onClick={() => handleCopy(key)} */}
                        <div className="p-3 py-2">
                          <p className="data-text m-0 text-truncate"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>Select a swatch to see details.</p>
          )
         
        
         
          }
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default InformationSwatch;
