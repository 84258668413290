import React, { useEffect, useRef } from 'react'
import Compressor from 'compressorjs';
import { useDispatch, useSelector } from 'react-redux';
import { addCompressImage, getUserImageFile, getUserNewProject } from '../../../slice/user/UserProject';
import { getUserLogin } from '../../../slice/user/UserSlice';
import { UploadImageS3Bucket } from '../../../api/projects/ProjectApi';
import imageCompression from 'browser-image-compression';
 type Props={
  jobId:number,
    resetUploadcompressImage:(data:string)=>void
 }

const UploadCompressImage = ({jobId,resetUploadcompressImage}:Props) => {
  
    const getUserNewProjects= useSelector(getUserNewProject)
    const getUserImageFiles= useSelector(getUserImageFile)
      const getUserLogins= useSelector(getUserLogin)
        const dispatch= useDispatch();
        const isApi=useRef(true)


    useEffect(()=>{
        if(getUserNewProjects &&
             getUserNewProjects.id &&
              getUserImageFiles &&
              getUserLogins &&
              getUserLogins[0] &&
              getUserLogins[0].role &&
              isApi.current &&
              jobId
            ){
               const id=  getUserNewProjects.id
           isApi.current=false
             
                    handleCompressedUpload(getUserImageFiles,id,getUserLogins[0].role,jobId)
        }
     },[getUserNewProjects,getUserImageFiles,getUserLogins,jobId])


  

// const handleCompressedUpload = async (imageFile: File, id: number, role: string, jobId: number) => {
//     try {
//         // Define options for compression
//         const options = {
//             maxSizeMB: 1, // Maximum size in MB
//             maxWidthOrHeight: 800, // Maximum width or height
//             useWebWorker: true, // Use a web worker for better performance
//             initialQuality: 0.9, // Initial quality (0 to 1)
//         };

//         // Compress the image
//         const compressedResult = await imageCompression(imageFile, options);

//         // Create FormData to send the compressed image
//         const formData = new FormData();
//         const imgName = imageFile.name;
//         const extensionOnly = imgName.split('.').pop();
//         const imagename = `image_${id}_compress.${extensionOnly}`;

//         formData.append('image', compressedResult);
//         if (id) formData.append('id', id.toString());
//         if (jobId) formData.append('jobId', jobId.toString());
//         formData.append('imagename', imagename);

//         // Call the upload function
//         upLoadImage(formData, role, imagename);
//     } catch (error) {
//         console.error('Error compressing image:', error);
//         // Optionally, handle the error (e.g., show a notification)
//     }
// };


    const handleCompressedUpload = (imageFile:File, id:number, role:string,jobId:number) => {
        const image = imageFile
        new Compressor(image, {
          quality: 1.0,
         
          success: (compressedResult) => {
             
                    const formData= new FormData;
                    const imgName=imageFile.name
                    const extensionOnly=imgName.split('.').pop()
                    const imagename=`image_${id}_compress.${extensionOnly}`
                formData.append('image',compressedResult)
                if( id) formData.append('id', id.toString())
                if( jobId) formData.append('jobId', jobId.toString())
                  formData.append('imagename',imagename)

           upLoadImage(formData,role,imagename)
          },
        });
      };

      const upLoadImage=async(data:FormData, role:string,imagename:string)=>{

        try{
             const response= await UploadImageS3Bucket (data, role)

                if(response && response.status===200){
                    //console.log("upload Image",response.data)
                     dispatch(addCompressImage(imagename))
                    resetUploadcompressImage(imagename)
                    isApi.current=true
                }
        } catch(err){
          isApi.current=true
            alert("Error in uploading compressed Image")
            resetUploadcompressImage("fail")
        }
    }
  return (
    <div>
        
    </div>
  )
}

export default UploadCompressImage