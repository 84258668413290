import React from 'react'

type Props = {
  selected: string[],
  color_code: string,
}

const ShowSelected = ({ selected, color_code }: Props) => {
  return (
    <>
      {selected && selected.length > 0 && selected.map((selectedCategory, index) => {
        return (
          <div className="seg-progress-details rounded-pill" key={`${selectedCategory}-${index}`}
            style={{
              border: `1px solid ${color_code}`,
              borderRadius: "6px",
              backgroundColor: `${color_code}50`,
              transition: "background-color 0.3s ease",
            }}
          >
            <span className='px-2'>{selectedCategory}</span>
          </div>
        )
      })}
    </>
  )
}

export default ShowSelected