import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ProjectModel } from '../../../../Model/project/ProjectModel'
import { ProjectEdit } from '../../../../api/projects/ProjectApi'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'
import { useEditable } from '@chakra-ui/react'
import { is } from '@react-three/fiber/dist/declarations/src/core/utils'
import axios from 'axios'
import { updateProjectname } from '../../../../slice/user/UserProject'


type Props={
    projectData:ProjectModel
    resetEditProject:()=>void
}
const EditProject = ({projectData,resetEditProject}:Props) => {

    const dispatch= useDispatch()
     const isApi= useRef(true) 

       useEffect(() => {
        if(isApi.current && projectData){
            isApi.current=false
            projectEdit(projectData)
        }
       },[projectData])

     const projectEdit=async(projectData:ProjectModel)=>{

        try{
            const response= await ProjectEdit(projectData)
            console.log("project update",response)
            if(response.status===200){
              dispatch(updateProjectname({
                project:response.data
              }))
                dispatch(addMessage({
                    isShow:true,
                    mess:"Project updated successfully",
                     toastType:"success"
                   }))
                   isApi.current=false
                   resetEditProject()
            }

        }catch(err){
            if(axios.isAxiosError(err)){
                dispatch(addMessage({
                    isShow:true,
                    mess: err.response?.data.message,
                    toastType:"Error"
                   }))

                   isApi.current=false
                   resetEditProject()
                }

        }
     }
     
     
  return (
    <>
    </>
  )
}

export default EditProject