import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getJobBase64, searchJobBase64 } from '../../slice/userJobSlice/UserJobSlice';

const SelelctedProjectRefresh = () => {
   const dispatch = useDispatch();
    const getJobBase64s= useSelector(getJobBase64);

    useEffect(()=>{
        if(getJobBase64s && 
            getJobBase64s.imagePath &&
            getJobBase64s.projectId &&
            getJobBase64s.jobId 
        ){ 
            localStorage.setItem("selectedProject",JSON.stringify(getJobBase64s))
        }else{
            const selectedProject= localStorage.getItem("selectedProject")  
            if(selectedProject){
                const selectedProjectData= JSON.parse(selectedProject)
                if(selectedProjectData){
                    dispatch(searchJobBase64({
                        imagePath:selectedProjectData.imagePath,
                        projectId:selectedProjectData.projectId,
                        jobId:selectedProjectData.jobId
                    }))  
                }
            }
        }
        
    },[getJobBase64s])
  return (
    <>
    </>
  )
}

export default SelelctedProjectRefresh