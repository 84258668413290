import React, { useEffect, useRef } from 'react'
import { GetAllDoorSwatches, GetAllGarageDoorSwatches, GetAllRoofSwatches, GetAllWindowSwatches } from '../../../../../api/material/catBrandStyleSwatch/CatBrandStyleSwatchApi'
import { CatBrandStyleSwatchModel } from '../../../../../Model/material/CatBrandStyleSwatchModel'
import { useDispatch } from 'react-redux'
import { addMessage } from '../../../../../slice/messageToast/ToastSlice'
import { addDoorSwatches, addGarageSwatches, addRoofSwatches, addWindowSwatches } from '../../../../../slice/swatches/SwatchSlices'
import axios from 'axios'


type GarageProps={
    resetswatchGarage: () => void
}
const GarageSwatch = ({resetswatchGarage}:GarageProps) => {
     const dispatch= useDispatch()
     const isApi= useRef(true)
    useEffect(()=>{
        if(isApi.current){
            getAllGarageSwatches()
            isApi.current=false
        }   
    },[])

    const getAllGarageSwatches=async()=>{
        try{    
            const response=await GetAllGarageDoorSwatches()
          //  console.log("response garage",response)
            if(response && 
                    response.status===200 &&
                response.data &&
                response.data.materials
                ){
                    dispatch(addGarageSwatches({
                        garage  :response.data.materials}))
                    resetswatchGarage()
            }
        }catch(err){
            if(axios.isAxiosError(err)){
            
                dispatch(addMessage({
                    isShow:true,
                    mess: err.response?.data.message,
                    toastType:"Error"
                   }))
            }
            resetswatchGarage()
        }
    }

  return (
    <>
    </>
  )
}

export default GarageSwatch