import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserLogin } from "../../../../slice/user/UserSlice";
import "./Comment.scss";
import {
  closeMainCanvas,
  getAddRelyId,
  getCavasGroupComments,
  getComment,
  hideCommentBox,
  resetCanvasComment,
  resetCommnets,
  showCommentsBox,
} from "../../../../slice/projectCommet/ProjectCommentSlice";
import {
  MessageModel,
  ProjectCommentModel,
  RepliesModel,
  UpdateCommentsModel,
} from "../../../../Model/comments/CommentsModel";

type Props = {
  addMessage: (data: MessageModel) => void;
  updateComments: (data: UpdateCommentsModel) => void;
};
const MainComments = ({ addMessage, updateComments }: Props) => {
  const [isPersonActive, setIsPersonActive] = useState(false);
  const getUserLogins = useSelector(getUserLogin);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [loginDate, setLoginDate] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [showUserList, setShowUserList] = useState(false);
  const dispatch = useDispatch();

  const users = ["John Doe", "Jane Smith", "Alex Johnson"]; // Example user list
  const getCavasGroupCommentss = useSelector(getCavasGroupComments); // get
  const getComments = useSelector(getComment);
  const [pointerX, setpointerX] = useState<number | null>(0);
  const [pointerY, setpointerY] = useState<number | null>(0);
  const [userId, setUserId] = useState<number | null>(null);
  const [userReply, setUserReply] = useState<RepliesModel[]>([]);
  const getAddRelyIds = useSelector(getAddRelyId);
  const [projectComments, setProjectComments] = useState<ProjectCommentModel[]>(
    []
  );

  // add all comments
  useEffect(() => {
    if (
      getComments &&
      getComments.projectComments &&
      getComments.projectComments.length > 0
    ) {
      setProjectComments(getComments.projectComments);
    }
  }, [getComments]);
  // add reply if user want to add commment
  useEffect(() => {
    if (
      getComments &&
      getComments.projectComments &&
      getComments.projectComments.length > 0 &&
      getAddRelyIds
    ) {
      const allComment = getComments.projectComments.filter(
        (item) => item._id == getAddRelyIds
      ) as ProjectCommentModel[];

      if (allComment && allComment.length > 0) {
        console.log("allComments", allComment[0]);
        setUserReply(allComment[0]?.replies || []);
      }
    }
  }, [getAddRelyIds, getComments]);

  useEffect(() => {
    //  console.log("userReply", userReply)
  }, [userReply]);

  // set userName and LoginDate
  useEffect(() => {
    if (getUserLogins && getUserLogins.length > 0) {
      const name = getUserLogins[0].name;
      const email = getUserLogins[0].email;

      const newDate = new Date();
      const formattedDate = newDate
        .toLocaleString("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false, // 24-hour format
        })
        .replace(",", "");
      //console.log("newDate", formattedDate)
      if (name && newDate && email && getUserLogins[0].id) {
        setUserId(getUserLogins[0].id);
        setUserName(name);
        setLoginDate(formattedDate);
        setUserEmail(email);
      }
    }
  }, [getUserLogins]);

  const handleAtClick = () => {
    setShowUserList(true); // Show the user list when @ is clicked
  };

  const handleUserSelect = (user: string) => {
    setSelectedUser(user);
    setIsPersonActive(true); // Activate the person button when a user is selected
    setShowUserList(false); // Hide the user list after selection
  };

  const handleCloseMainCanvas = () => {
    if (message && userId && userName) {
      const data = {
        message: message,
        userId: userId,
        name: userName,
      };
      setMessage(null);
      if (data) {
        addMessage(data);
      }
    }
  };

  const handleAddReply = () => {
    if (getAddRelyIds != "") {
      const data: UpdateCommentsModel = {
        _id: getAddRelyIds,
        messageData: {
          message: message ?? "",
          userId: userId ?? 0,
          name: userName ?? "",
        },
      };
      setMessage(null);
      updateComments(data);
    }
  };

  const [userLetter, setUserLetter] = useState<string | null>(null);
  useEffect(() => {
    if (getUserLogins && getUserLogins.length > 0) {
      const name = getUserLogins[0].name?.toUpperCase();

      if (name) {
        const firstLetter = name.charAt(0);
        //console.log("firstLetter",firstLetter)
        setUserLetter(firstLetter);
      }
    }
  }, [getUserLogins]);

  // cancel the comments
  const handleCancelComments = () => {
    setMessage(null);
    //dispatch(resetCommnets())
    dispatch(hideCommentBox());
  };

  const handleShowComment = (id: string) => {
    // console.log("show Comments", id)
    dispatch(showCommentsBox({ id: id }));
  };

  const handleCloseComments = () => {
    dispatch(hideCommentBox());
  };

  const handleCloseEmtyComments = () => {
    dispatch(closeMainCanvas());
  };
  return (
    <>
      {projectComments &&
        projectComments.length > 0 &&
        projectComments.map((item) => {
          const value = item._id;
          const y = (item?.position?.y ?? 0) - 50;
          //  console.log("item?.position?.y-->", item?.position?.y)
          //  console.log("y value", y)
          return (
            <div
              className="comment-sections"
              style={{
                width: 300,
                transform: `translate(${item.position?.x}px, ${y}px)`,
                // transform: `translate(${0}px, ${0}px)`,
                transition: "transform 0.3s ease-in-out",
              }}
              key={item._id}
            >
              <div
                className="d-flex align-items-start group"
                onClick={() => handleShowComment(item._id ?? "")}
                style={{
                  backgroundColor: item.isHover ? "red" : "transparent",
                }}
              >
                <div className="UserAvatar d-flex ">
                  <div className="avatarCircle user-avatar-circle rounded-circle bg-primary text-white">
                    <p className="m-0"> {userLetter} </p>
                  </div>
                </div>
              </div>

              {item.isShow && (
                <div className="bg-white Comment-sec  p-2">
                  <div className="comment-header gap-3 pb-1">
                    <span>{item.segment}</span>
                    <span>
                      <i className="bi bi-check-circle"></i>
                    </span>
                    <span>
                      <i className="bi bi-trash3-fill"></i>
                    </span>
                    <span onClick={handleCloseComments}>
                      <i className="bi bi-x fs-4"></i>
                    </span>
                  </div>

                  <>
                    {item.replies &&
                      item.replies.length > 0 &&
                      item.replies.map((reply) => {
                        return (
                          <div
                            className="command-details mt-3"
                            key={reply.message}
                          >
                            <div className="d-flex">
                              <div className="avatar-Circle rounded-circle bg-primary text-white">
                                <p className="m-0 "> {userLetter}</p>
                              </div>
                              <span className="fw-semibold mx-2">
                                {reply.name}
                              </span>
                              <span>
                                {reply?.createdAt
                                  ? new Date(
                                      reply.createdAt
                                    ).toLocaleDateString("en-GB")
                                  : ""}
                              </span>
                            </div>
                            <span className="command-mass">
                              {" "}
                              {reply.message}
                            </span>
                          </div>
                        );
                      })}
                    <div className="p-2">
                      <textarea
                        className="form-control from-textarea comment-form mt-1"
                        id="exampleFormControlTextarea1"
                        rows={4}
                        value={message ?? ""}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>

                      <div className="d-flex align-item-center justify-content-between comment-outline py-2 border-top px-2">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center position-relative">
                            {/* @ button */}
                            <span
                              aria-label="Tag User"
                              className="btn  border-0 tag-user"
                              onClick={handleAtClick}
                            >
                              @
                            </span>

                            {/* Conditionally show user list */}
                            {showUserList && (
                              <ul className="user-dropdown position-absolute list-unstyled bg-white border">
                                {users.map((user, index) => (
                                  <li
                                    key={index}
                                    className="dropdown-item p-1"
                                    onClick={() => handleUserSelect(user)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {user}
                                  </li>
                                ))}
                              </ul>
                            )}

                            {/* <button
                        aria-label="Add Person"
                        className={`btn bg-transparent border-0 p-0 ms-2 ${isPersonActive ? "active-user" : ""}`}
                      >
                        <i className="bi bi-person-bounding-box"></i>
                      </button>
                      <button
                      className='btn rounded-pill   check-btn ms-2'
                     onClick={handleCancelComments} // Ensure to add the onClick event here
                    >
                     <i className="bi bi-x-circle"></i>
                    </button> */}
                          </div>

                          {getAddRelyIds != "" && (
                            <button
                              className="btn rounded-pill bg-primary text-white check-btn "
                              onClick={handleAddReply}
                            >
                              <i className="bi bi-check2"></i>
                            </button>
                          )}

                          {getAddRelyIds === "" && (
                            <button
                              className="btn rounded-pill bg-primary text-white check-btn "
                              onClick={handleCloseMainCanvas}
                            >
                              <i className="bi bi-check2"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              )}
            </div>
          );
        })}

      {getComments.canvasGroup &&
        getComments.canvasGroup.isMainComment &&
        getComments.canvasGroup.groupName &&
        getComments.canvasGroup.segShortName &&
        getComments.canvasGroup.pointer &&
        getComments.canvasGroup.pointer.x &&
        getComments.canvasGroup.pointer.y && (
          <div
            className="comment-sections"
            style={{
              transform: `translate(${getComments.canvasGroup.pointer.x}px, ${
                getComments.canvasGroup.pointer.y - 50
              }px)`,
              transition: "transform 0.3s ease-in-out",
            }}
            key="no_comments"
          >
            <div
              className="d-flex align-items-start group"
              // onClick={()=>handleShowComment(item._id??"")}
              //   style={{backgroundColor:item.isHover?'red':"transparent"}}
            >
              <div className="UserAvatar d-flex ">
                <div className="avatarCircle user-avatar-circle rounded-circle bg-primary text-white">
                  <p className="m-0"> {userLetter} </p>
                </div>
              </div>
            </div>
            <div className="bg-white Comment-sec  p-2"
            style={getComments.canvasGroup.pointer.x > 614? { marginLeft:"615px" } : { marginLeft: "0px" }}
            >
              <div className="comment-header gap-3 pb-1">
                <span>{getComments.canvasGroup.segShortName}</span>
                <span>
                  <i className="bi bi-check-circle"></i>
                </span>
                <span>
                  <i className="bi bi-trash3-fill"></i>
                </span>
                <span onClick={handleCloseEmtyComments}>
                  <i className="bi bi-x fs-4"></i>
                </span>
              </div>

              <>
                <div className="p-2">
                  <textarea
                    className="form-control from-textarea comment-form mt-1"
                    id="exampleFormControlTextarea1"
                    rows={4}
                    value={message ?? ""}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>

                  <div className="d-flex align-item-center justify-content-between comment-outline py-2 border-top px-2">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex align-items-center position-relative">
                        {/* @ button */}
                        <span
                          aria-label="Tag User"
                          className="btn  border-0 tag-user"
                          onClick={handleAtClick}
                        >
                          @
                        </span>

                        {/* Conditionally show user list */}
                        {showUserList && (
                          <ul className="user-dropdown position-absolute list-unstyled bg-white border">
                            {users.map((user, index) => (
                              <li
                                key={index}
                                className="dropdown-item p-1"
                                onClick={() => handleUserSelect(user)}
                                style={{ cursor: "pointer" }}
                              >
                                {user}
                              </li>
                            ))}
                          </ul>
                        )}

                        <button
                          aria-label="Add Person"
                          className={`btn bg-transparent border-0 p-0 ms-2 ${
                            isPersonActive ? "active-user" : ""
                          }`}
                        >
                          <i className="bi bi-person-bounding-box"></i>
                        </button>
                        <button
                          className="btn rounded-pill   check-btn ms-2"
                          onClick={handleCancelComments} // Ensure to add the onClick event here
                        >
                          <i className="bi bi-x-circle"></i>
                        </button>
                      </div>

                      <button
                        className="btn rounded-pill bg-primary text-white check-btn "
                        onClick={handleCloseMainCanvas}
                      >
                        <i className="bi bi-check2"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        )}

      {/* {getCavasGroupCommentss &&
        getCavasGroupCommentss.isMainComment &&
        getCavasGroupCommentss.pointer &&
        getCavasGroupCommentss.pointer.x &&
        getCavasGroupCommentss.pointer.y &&
        getComments.iscomment &&
        (
         
          <div className='comment-sections'
            style={{
              transform: `translate(${getCavasGroupCommentss.pointer.x}px, ${getCavasGroupCommentss.pointer.y}px)`,
              transition: 'transform 0.3s ease-in-out',
             
            }}

          >
            <div className="d-flex align-items-start group" 
           
            >

              <div className="UserAvatar d-flex ">
                <div className="avatarCircle 1 rounded-circle bg-primary text-white">
                  <p className="m-0"> {userLetter} </p>
                </div>
              </div>
            </div>






            <div
              className='bg-white Comment-sec  p-2'

            >




              <div className='rounded-3 w-100 comment-form p-2'>
               { getAddRelyIds!=""  && 
               userReply && userReply.length>0 &&
               userReply.map(reply=>(
                <span key={reply.message}>
                  <div className="avatarCircle 1 rounded-circle bg-primary text-white">
                  <p className="m-0"> {userLetter}
                   
                     </p>
                </div>
                <span> {reply.message}</span>
                  </span>
               ))
               }
                <textarea
                  className="form-control from-textarea"
                  id="exampleFormControlTextarea1"
                  rows={4}
                  value={message ?? ""}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>

                <div className='d-flex align-item-center justify-content-between comment-outline pt-2 border-top'>
                  <div className='d-flex align-items-center justify-content-between w-100'>
                    <div className="d-flex align-items-center position-relative">
                    
                      <span
                        aria-label="Tag User"
                        className="btn bg-transparent border-0 tag-user"
                        onClick={handleAtClick}
                      >
                        @
                      </span>

                     
                      {showUserList && (
                        <ul className="user-dropdown position-absolute list-unstyled bg-white border">
                          {users.map((user, index) => (
                            <li
                              key={index}
                              className="dropdown-item p-1"
                              onClick={() => handleUserSelect(user)}
                              style={{ cursor: "pointer" }}
                            >
                              {user}
                            </li>
                          ))}
                        </ul>
                      )}

                      <button
                        aria-label="Add Person"
                        className={`btn bg-transparent border-0 p-0 ms-2 ${isPersonActive ? "active-user" : ""}`}
                      >
                        <i className="bi bi-person-bounding-box"></i>
                      </button>
                    </div>

                    <button
                      className='btn rounded-pill bg-primary text-white check-btn'
                     onClick={handleCancelComments} // Ensure to add the onClick event here
                    >
                     <i className="bi bi-x-circle"></i>
                    </button>
              {  getAddRelyIds !='' &&  
                <button

                      className='btn rounded-pill bg-primary text-white check-btn'
                      onClick={handleAddReply}
                    
                    >
                      <i className="bi bi-check2">R</i>
                    </button>}
                   {getAddRelyIds === ''&&
                    <button

                      className='btn rounded-pill bg-primary text-white check-btn'
                      onClick={handleCloseMainCanvas}
                    
                    >
                      <i className="bi bi-check2">N</i>
                    </button>}

                  </div>
                </div>
              </div>

            </div>
          </div>
        )} */}
    </>
  );
};
export default MainComments;
