import React, { useEffect, useRef } from 'react'
import { GetallSegments } from '../../../../api/segment/SegmentApi'
import { useDispatch, useSelector } from 'react-redux'
import { addSegment, getAllSegment } from '../../../../slice/segment/SegmentSlice'

const GetAllSegments = () => {
     const dispatch= useDispatch()

        const getAllSegments= useSelector(getAllSegment)
        const isApi= useRef(true)
         useEffect(()=>{
            if(getAllSegments &&
                getAllSegments.length==0 &&
                isApi.current
            ){
                isApi.current=false
                getSegment()
            }
         },[getAllSegments])

    const getSegment=async()=>{
        try{

            const response= await GetallSegments()
            //console.log("response segment---",response.data)
              if(response && response.status===200){
                isApi.current=true
                dispatch(addSegment(response.data))  
              }

        }catch(err){
            alert("Error in getting segment")
        }
    }
  return (
    <div></div>
  )
}

export default GetAllSegments