import React, { useEffect, useState } from "react";
import { Button, Input } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { ProjectModel } from "../../../Model/project/ProjectModel";

import "./ShowListOfProjects.css";
import "./ListOfProject.scss";
import GetUserJob from "../jobs/GetUserJob";
import {
  getUserJobData,
  searchJobBase64,
} from "../../../slice/userJobSlice/UserJobSlice";
import { useDispatch, useSelector } from "react-redux";
import GetJobbase64 from "../../brandPage/jobs/jobapi/GetJobbase64";
// import { getLoading, startLoading, stopLoading } from '../../../slice/loading/LoadingSlice'
// import Loading from '../../loading/Loading'

import { addNewProject } from '../../../slice/user/UserProject'
import Base64Image from '../base64Image/Base64Image'
import ActionProject from '../actionProject/ActionProject'
import DeletEProjectById from '../actionProject/deleteProject/DeletEProjectById'
import DeleteModal from '../actionProject/deleteProject/DeleteModal'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import { addApiMessage, addMessage } from '../../../slice/messageToast/ToastSlice'
import GetBase64FromApi from '../base64Image/GetBase64FromApi'
import { updateProjectPrompTbasedImage } from '../../../slice/genAi/GenAiAttributesSlice'
import { changeSegmentTab, switchToOutline } from '../../../slice/tabControl/TabControlSlice'
import { getSettingPath } from "../../../slice/settingPathSlice/SettingPathSlice";
import { startLoading, stopLoading } from "../../../slice/loading/LoadingSlice";
import EditProject from "../actionProject/editProject/EditProject";


const AssetImage = process.env.REACT_APP_ASSERT_Image
const masterImage = `${AssetImage}/images/no-image-.jpg`
const urlpath = process.env.REACT_APP_Project_Image


type Props = {
  projectItem: ProjectModel;
  // id: number;
  // name: string;
  // image?: string;
  // compressImage?: string;
  // modified?: string;
  // onEditClick: () => void;
};
const ListOfProject = ({ projectItem }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [jobId, setJobId] = useState<number | null | undefined>(null);
  const [isJobImage, setIsJobImage] = useState<boolean>(false);
   
  const [isDeletModal, setIsDeleteModal] = useState<boolean>(false);
  const[path,setPath]= useState<string>("")
  const [projectName, setProjectName] = useState(projectItem.name);
  const [newProjectData, setNewProjectData] = useState<ProjectModel|null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const[isEditApi, setIsEditApi]=useState<boolean>(false)

  const getUserJobDatas = useSelector(getUserJobData);
  const getSettingPaths= useSelector(getSettingPath)
   // set the image path
   useEffect(()=>{
    if(getSettingPaths && getSettingPaths.length>0){
      let path
      const projectPath= getSettingPaths.filter((data)=>data.title==="Project Image")
      if(projectPath.length>0){
        path=projectPath[0].path??""
        setPath(path)
      }
    }
 },[getSettingPaths])


  const handleJob = (item: ProjectModel) => {
    dispatch(addNewProject(item));
    let jobid: number = 0;
    let imageUrl: string = "";
    let projectId: number = 0;
    if (item && item.jobs && item.jobs.length > 0 && item.image && item.id) {
      projectId = item.id;
      // setProjectId(item.id)
      // setImageUrl(item.image)
      imageUrl = item.image;
      item.jobs.map((item) => {
        jobid = item;
        //setJobId(item)
      });
    }

     
    if(jobid!=0 && 
      projectId!=0 && 
      imageUrl!="" 
      
    ){
      
     
     // console.log("jobid",jobid)
      dispatch(updateProjectPrompTbasedImage({
        jobId:jobid,
         projectId:projectId,
         projectImage:item.image
      }))

      dispatch(searchJobBase64({
        imagePath:imageUrl,
        projectId:projectId,
        jobId:jobid
       }))


       dispatch(switchToOutline("segment"))
       dispatch(changeSegmentTab("details"))
       
      navigate(`/project/view/${jobid}`)

    }
  };
  const [isJob, setIsJob] = useState<boolean>(false);

  const handleResetJob = (mess: string) => {
    if (mess === "success") {
      // navigate(`/project/view/${jobId}`)
      // } else if(mess==="required"){
      setIsJobImage(true);
    } else {
      dispatch(
        addMessage({
          isShow: true,
          mess: "Some thing went wrong",
          toastType: "Error",
        })
      );
      // alert("Some thing went wrong")
    }
    //setJobId(null)
  };

  const handleResetImageUrl = (mess: boolean) => {
    setJobId(null);
    // setImageUrl(null)
    setIsJobImage(false);
    // dispatch(stopLoading())
    if (mess) {
      navigate(`/project/view/${jobId}`);
    } else {
      dispatch(
        addMessage({
          isShow: true,
          mess: "Error in getting base64 image",
          toastType: "Error",
        })
      );
      //alert("Error in getting base64 image")
    }
  };

 
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    // TODO: Add backend logic to save the updated project name
  };

  const handleApiCall = (projectItem: ProjectModel) => {
    console.log("projectItem", projectItem);
    const jobId = projectItem.jobs?.[0] ?? 0; // Use optional chaining and nullish coalescing
    const data: ProjectModel = {
      id: projectItem.id,
      name: projectName,
      slug: projectItem.slug,
      image: projectItem.image,
      customer_id: projectItem.customer_id,
      modified: new Date().toISOString(),
      created: projectItem.created,
      isActive: projectItem.isActive,
      jobs: [jobId],
      status: projectItem.status,
      compressImage: projectItem.compressImage,
      timeline: projectItem.timeline,
      description: projectItem.description,
    };
    dispatch(startLoading());
    setNewProjectData(data);
    setIsEditApi(true);
  };


  const handleResetEdit=()=>{
    setIsEditApi(false)
    dispatch(stopLoading()) 
  }
  return (

    <>
     <div className="col-12 col-md-4">
      <div className="card shadow-lg rounded-3 border-0">
        {projectItem &&
        projectItem.image &&
        projectItem.compressImage &&
        projectItem.jobs &&
        projectItem.id ? (
          (() => {
            const jobId = projectItem!.jobs[0];
          

            return (
              <div
                className="media-images rounded-top-3 overflow-hidden"
                key={projectItem.id}
                onClick={() => handleJob(projectItem)}>
                <img
                  src={`${path}${projectItem.id}/${jobId}/${projectItem.compressImage}`}
                  alt={projectItem.name}
                  key={projectItem.id}
                />
              </div>
            );
          })()
        ) : (
          <div className="media-images rounded-top-3 overflow-hidden">
            <img
              className="master-images-1"
              src={masterImage}
              alt="masterImage"
            />
          </div>
        )}
        <div className="card-content">
          <div className="job-content p-3 pt-2 align-items-center justify-content-between">
            <div>
              <Button
                className="job-project-title fs-5 fw-semibold p-0 border-0 bg-white"
                variant="solid"
                colorScheme="blue"
                size="md"
                borderRadius="md"
                onClick={() => {
                  if (!isEditing) {
                    handleJob(projectItem);
                  }
                }}>
                {isEditing ? (
                  <Input
                    type="text"
                    value={projectName}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    className="rounded-1 bg-secondary border-0 p-0 fs-6 mt-2 ps-1"
                    autoFocus
                    onClick={(e) => e.stopPropagation()} // Prevent Input click from triggering Button click
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleApiCall(projectItem); // Call the API when Enter key is pressed
                      }
                    }}
                  />
                ) : (
                  <span className="create-project-icon fs-6 fw-semibold ps-0 border-0 rounded-1 d-inline-block pt-2">
                    {projectItem.name}-{projectItem.id}
                  </span>
                )}
              </Button>
  
              {projectItem.modified &&
                (() => {
                  const formatDate = projectItem.modified;
                  const date = new Date(formatDate);
                  const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits
                  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
                  const year = date.getFullYear();
  
                  return (
                    <div
                      className="job-project-title fs-5 fw-medium p-0 border-0 bg-white"
                      onClick={() => handleJob(projectItem)}>
                      {projectItem.modified && (
                        <div className="cre-pro-date fw-medium d-flex">
                          Modified:{" "}
                          {new Date(projectItem.modified).toLocaleDateString()}
                        </div>
                      )}
                    </div>
                  );
                })()}
            </div>
  
            <div className="d-flex align-items-center gap-1 project-action">
              {/* Project Action */}
              {projectItem &&
              projectItem.id &&
              //  projectItem.jobs &&
              //   projectItem.jobs.length>0 &&
              //   projectItem.jobs[0] &&
              (
                <ActionProject
                projectItem={projectItem}
                // jobId={projectItem.jobs[0]??0}
                //   projectId={projectItem.id}
                  onEditClick={() => {
                    console.log("Edit clicked!");
                    setIsEditing(true);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>

    { isEditApi &&
      newProjectData &&
      <EditProject
      projectData={newProjectData}
      resetEditProject={handleResetEdit}

      />}
    </>
   
  );
};

export default ListOfProject;
