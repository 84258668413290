import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JobSegmentModel } from "../../Model/Job/JobModel";
import { SegmentDetails } from "../../Model/Job/SamModel";
import { StartUpateMasterArrayModel } from "../../Model/masterArray/UpdateIndexModel";
import { PayCircleOutlined } from "@ant-design/icons";
import { AllreadyExistSwatchModel } from "../../Model/svgModel/SvgModel";


interface SegregatedStates {
    segreDatedSegments:JobSegmentModel[]
    startUpdateMaterArray:StartUpateMasterArrayModel,
    isUpdateseg:boolean,
    
    isStartSegegration:boolean,
    isStartIndexing:boolean,
    newSegment:JobSegmentModel,
    deleteSegmentHoverLayer:{
        childName?:string|null
        childShortName?:string|null
    },
    hoverLayerViewSeg:string,
    showActiveSegment:string,
    
}

const initialState: SegregatedStates = {
segreDatedSegments:[],
isUpdateseg:false,

  startUpdateMaterArray:{
    masterData: null,
  },
  isStartSegegration:false,
  isStartIndexing:false,
  newSegment:{},
  deleteSegmentHoverLayer:{
    childName:null,
    childShortName:null
  },
  hoverLayerViewSeg:"",
  showActiveSegment:""
};

const SegregatedSegmentSlice = createSlice({
    name: "segregatedSegment",
    initialState,
    reducers: {
        resetSegregatedSegments:(state)=>{
            return initialState;
        },
        segegratedJobSegment:(state,action)=>{
            state.segreDatedSegments= action.payload
            state.isUpdateseg=true
          },
          updateSortSeg:(state,action)=>{
           state.isUpdateseg= action.payload
          }, 
          

          resetSegregated:(state)=>{
            state.segreDatedSegments=[]
          }, 
          updateAnnoatationInSegegtated:(state,action)=>{
            const {segName,groupName,seg_short,childgroupName,masterData}= action.payload
                
            const segIndex = state.segreDatedSegments.findIndex(item => Object.keys(item).includes(segName));
                    
             if(segIndex!=-1){
             const segData=   state.segreDatedSegments[segIndex][segName]
             const details=segData.details
             if(details){
                 details.annotation_type="manual"
                details.annotation=masterData.annotation
                details.bb_annotation_float=masterData.bb_annotation_float
                details.bb_annotation_int=masterData.bb_annotation_int
                details.bb_dimension_pixel=masterData.bb_dimension_pixel
                details.seg_dimension_pixel=masterData.seg_dimension_pixel||[]
                details.perimeter_pixel=masterData.perimeter_pixel??0
                details.perimeter_feet=masterData.perimeter_feet??0
                details.top_coordinate=masterData.top_coordinate??[]
                details.annotation_area_pixel=masterData.annotation_area_pixel??0
                details.annotation_area_sqft=masterData.annotation_area_sqft??0
                details.dimension_seg=masterData.dimension_seg??[]
                details.long_trim_seg_dist=masterData.long_trim_seg_dist??[]
                details.middle_points=masterData.middle_points??[]
                details.bb_area_pixel=masterData.bb_area_pixel
                details.bb_area_sqft=masterData.bb_area_sqft??0
                details.bb_dimension_pixel=masterData.bb_dimension_pixel
                details.bb_dimension_feet=masterData.bb_dimension_feet??[]
             }
             } else if (segIndex==-1){
                const detail:SegmentDetails={
                    annotation_type:"manual",
                    annotation:masterData.annotation,
                    bb_annotation_int:masterData.bb_annotation_int,
                    bb_annotation_float:masterData.bb_annotation_float,
                    group:childgroupName,
                    seg_type:groupName,
                    label:segName,
                    seg_name:segName,
                    seg_short:seg_short,
                    seg_dimension_pixel:masterData.seg_dimension_pixel||[],
                    perimeter_pixel:masterData.perimeter_pixel||0,
                    perimeter_feet:masterData.perimeter_feet||0,
                    top_coordinate:masterData.top_coordinate||[],
                    annotation_area_pixel:masterData.annotation_area_pixel||0,
                    annotation_area_sqft:masterData.annotation_area_sqft||0,
                    dimension_seg:masterData.dimension_seg||[],
                    long_trim_seg_dist:masterData.long_trim_seg_dist??[],
                    middle_points:masterData.middle_points??[],
                    bb_area_pixel:masterData.bb_area_pixel,
                    bb_area_sqft:masterData.bb_area_sqft??0,
                    bb_dimension_pixel:masterData.bb_dimension_pixel,
                    bb_dimension_feet:masterData.bb_dimension_feet??[]

                  }
                  const segDetails={
                    details:detail,
                    swatch:{}
                  }
                state.segreDatedSegments.push({
                   [segName]:segDetails,
                  
                })
             }
              state.startUpdateMaterArray.groupName=groupName
              state.startUpdateMaterArray.childgroupName=childgroupName
              state.startUpdateMaterArray.segName=segName
              state.startUpdateMaterArray.seg_short=seg_short
              state.startUpdateMaterArray.masterData=masterData
               state.startUpdateMaterArray.isUpdate=true

                state.isStartSegegration= true
         
        },
        deleteSegmentFromCanvas:(state,action)=>{
            const {childName,childShortName}=action.payload
                state.deleteSegmentHoverLayer.childName=childName
                state.deleteSegmentHoverLayer.childShortName=childShortName

            const segIndex= state.segreDatedSegments.findIndex(item=>
                Object.keys(item).includes(childName) )
                if(segIndex!=-1){
                    state.segreDatedSegments.splice(segIndex,1)
                }
                localStorage.removeItem("getSegregateSegment")
                
                state.isStartIndexing=true
        },
        resetDeleteSegmenthoverLayer:(state)=>{
            state.deleteSegmentHoverLayer.childName= null
            state.deleteSegmentHoverLayer.childShortName= null
        },
        resetStartMasterArrayStart:(state)=>{
                state.startUpdateMaterArray={
                    masterData: null,
                }
            },
            startSegregation:(state)=>{
                state.isStartSegegration=true
            },
            stopSegregation:(state)=>{
                state.isStartSegegration=false
            },
            stopIsStartIndexing:(state)=>{
                state.isStartIndexing= false
               },
               updateAfterIndexing:(state,action)=>{
                const {groupName,childName}= action.payload
                
                state.segreDatedSegments.forEach((item, segIndex)=>{
                    const seg = Object.keys(item).find(grp =>
                        item[grp]?.details?.seg_type === groupName
                    );
                      
                      if(segIndex!=-1 && seg){
                        const group=state.segreDatedSegments[segIndex]
                        Object.keys(group).forEach(segName=>{
                            const value=group[segName]
                            if(value && value.details &&value.details.label){
                                const segNAme=value.details.label
                                const shortName=value.details.seg_short
                                const modifiedshortName = shortName?.replace(/[0-9]/g, "");
                
                                const segNo = segNAme.match(/\d+/); 
                                const segExtractedNumber = segNo ? Number(segNo[0]) : null;
                                const childNo=childName.match(/\d+/);
                                const childExtractedNumber = childNo ? Number(childNo[0]) : null;
                                if(childExtractedNumber &&segExtractedNumber){
    
                                    const diff=segExtractedNumber-childExtractedNumber
                                         if(diff>0 &&modifiedshortName){
                                            value.details.seg_name=groupName+(segExtractedNumber-1)
                                            value.details.label=groupName+(segExtractedNumber-1)
                                            value.details.seg_short=modifiedshortName+(segExtractedNumber-1)
                                            group[groupName+(segExtractedNumber-1)] = value;
    
                                         }
                                }
                             
                                
                            }
                        })
                      }
                })
               
                
                  
                  

               }, 
               addNewSegment:(state,action)=>{
                state.newSegment= action.payload
               },
               resetNewSegment:(state)=>{
                state.newSegment={}
               },
               updateSegregation:(state,action)=>{
                state.segreDatedSegments=action.payload
                state.isUpdateseg= true
               },
               startHoverLayerView:(state,action)=>{

                state.hoverLayerViewSeg=action.payload
               },
               stopHoverLayerView:(state)=>{
                state.hoverLayerViewSeg=""
               },
               updateCurrentSwatchImage:(state,action)=>{
               const {swatchImages,swatchId,swatchTitle,swatchPhoto,segmentNames}=action.payload;
                if(segmentNames && segmentNames.length>0){
                    segmentNames.map((seg:string)=>{
                        
                        const segName= Object.keys(seg)[0]
                        const segmentIndex= state.segreDatedSegments.findIndex(item=>
                            Object.keys(item).includes(segName)
                           )
                              if(segmentIndex !==-1){
                               const segmentValue= state.segreDatedSegments[segmentIndex][segName]
                               if(segmentValue ){
                                    segmentValue.swatch={
                                        swatch_id:parseInt(swatchId),   
                                        swatch_seg_image:swatchImages[segName],
                                        isActive:true
                                    }
                                   
            
                               }
                              }
                    })
                   
                }
              

                   
               },
               updateSwatchData:(state,action)=>{
                const {allSwatchs}=action.payload
                  if(allSwatchs && allSwatchs.length>0){
                    allSwatchs.map((item:AllreadyExistSwatchModel)=>{
                          
                        const segmentIndex= state.segreDatedSegments.findIndex(seg=>
                            Object.keys(seg).includes(item.segname??"")
                           )

                           if(segmentIndex !==-1){
                            const segmentValue= state.segreDatedSegments[segmentIndex][item.segname??""]
                            if(segmentValue && 
                                item.swatchId  &&
                                 item.swatchUrl ){
                                   
                                    if(item.isActive){
                                        segmentValue.swatch={ 
                                            swatch_id:item.swatchId ,
                                            swatch_seg_image:item.swatchUrl,
                                            isActive:item.isActive
                                        }
                                        
                                    }else{
                                        segmentValue.swatch={ 
                                            swatch_id:item.swatchId ,
                                            swatch_seg_image:item.swatchUrl,
                                            isActive:item.isActive
                                        }  
                                    }
                                
         
                            }
                           }
                    })
                  }
               },
               deleteSwatch: (state, action) => {
                const { swatchId, groupName, groupSubName } = action.payload;
               let foundKey;
                
               const segIndex= state.segreDatedSegments.findIndex((segment, index) => {
                    Object.keys(segment).forEach((key) => {
                        const swatch = segment[key]?.swatch;
                        
                        if (swatch?.swatch_id === swatchId) {
                            foundKey = key; // Declare foundKey outside the loop
                            return index;
                        }
                        
                    });
                });
             
                
                if (segIndex !== -1 && foundKey) {
                    const seg= state.segreDatedSegments[segIndex][foundKey]
                    if(seg){
                        seg.swatch={}
                    }
                }
               
            },
            updateActiveSegment:(state,action)=>{
                const {segName}= action.payload
               state.showActiveSegment=segName
            
    }
}
}
)

export const {
    resetSegregatedSegments,
    segegratedJobSegment,
    updateAnnoatationInSegegtated,
    deleteSegmentFromCanvas,
    resetSegregated,
    resetStartMasterArrayStart,
    startSegregation,stopSegregation,
    stopIsStartIndexing,
    updateAfterIndexing,
    addNewSegment,
    resetNewSegment,
    resetDeleteSegmenthoverLayer,
    updateSegregation,
    updateSortSeg,
    startHoverLayerView,
    stopHoverLayerView,
    updateCurrentSwatchImage,
    deleteSwatch,
    updateSwatchData,
    updateActiveSegment
    
}=SegregatedSegmentSlice.actions;

export const getSegregateSegment = (state: { segregatedSegment: SegregatedStates }): JobSegmentModel[]=> {
        return state.segregatedSegment.segreDatedSegments|| [];
    }

   
    export const getStartMaterArrayUpdate = (state: { segregatedSegment: SegregatedStates }): StartUpateMasterArrayModel=> {
            return state.segregatedSegment.startUpdateMaterArray|| {};
        }

        export const getStartSegeration = (state: { segregatedSegment: SegregatedStates }): boolean=> {
            return state.segregatedSegment.isStartSegegration|| false;
        }
        export const getStartIndexing = (state: {  segregatedSegment: SegregatedStates  }): boolean=> {
            return state.segregatedSegment.isStartIndexing|| false;
        }
        export const getNewSegment = (state: {  segregatedSegment: SegregatedStates  }): JobSegmentModel=> {
            return state.segregatedSegment.newSegment|| {};
        }
       
        export const getDeleteSegmentHover = (state: {  segregatedSegment: SegregatedStates  })=> {
            return state.segregatedSegment.deleteSegmentHoverLayer;
        } 
        export const getSegregatedSliceData = (state: {  segregatedSegment: SegregatedStates  })=> {
            return state.segregatedSegment;
        } 
        
        export const getHoverLayerSeg = (state: {  segregatedSegment: SegregatedStates  })=> {
            return state.segregatedSegment.hoverLayerViewSeg;
        } 
        

        export const getActiveSegment = (state: {  segregatedSegment: SegregatedStates  })=> {
            return state.segregatedSegment.showActiveSegment;
        }
        
    
export default SegregatedSegmentSlice.reducer