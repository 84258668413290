import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SegmentModel } from "../../Model/segment/SegmentModel";
import { JobSegmentationModel } from "../../Model/masterArray/MasterArrayModel";

interface SegmentStates {
    segment:SegmentModel[],
    isAddSegmentModal:boolean,
    selectedSegmentForSwatch:JobSegmentationModel[]

    
}

const initialState: SegmentStates = {
    segment:[],
    isAddSegmentModal:false,
    selectedSegmentForSwatch:[]
};

const SegmentSlice = createSlice({
    name: "segment",
    initialState,
    reducers: {
        addSegment:(state,action)=>{
            state.segment= action.payload.map((item:SegmentModel)=>{
                return {...item,isDisabled:false}
            })
        }, resetSegment:(state)=>{
            state.segment=[]
        },
         updateSegment:(state,action)=>{
            const {name,isDisabled}= action.payload
            const index= state.segment.findIndex(item=>item.name==name)
            if(index!=-1){
                state.segment[index].isDisabled=isDisabled
            }
         }, 
         openAddSegmentModal:(state)=>{
            state.isAddSegmentModal=true
         },
         closeAddSegmentModal:(state)=>{
            state.isAddSegmentModal=false
         },
         addSelectedSegmentForSwatch:(state,action)=>{
            state.selectedSegmentForSwatch=action.payload
         },
         resetSelectedSegmentForSwatch:(state)=>{
            state.selectedSegmentForSwatch=[]
         },
         UpdateIsDisable:(state)=>{
            state.segment.forEach(item=>{
                item.isDisabled= false
            })
         },

        updateSegmentData:(state,action)=>{
        const {id , data}= action.payload
        const index= state.segment.findIndex(item=>item.id==id)
        if(index!=-1){
            state.segment[index]=data
        }
    }
      }

    
}
)

export const {addSegment,resetSegment,
    updateSegment,
    openAddSegmentModal,
    closeAddSegmentModal,
    addSelectedSegmentForSwatch,
    UpdateIsDisable,
    updateSegmentData
}=SegmentSlice.actions;
export const getAllSegment=  (state:{segment:SegmentStates})=>state.segment.segment
export const getIsAddSegmentModal=  (state:{segment:SegmentStates})=>state.segment.isAddSegmentModal
export const getSelectedSegmentForSwatch=  (state:{segment:SegmentStates})=>state.segment.selectedSegmentForSwatch
export default SegmentSlice.reducer