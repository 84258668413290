import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectGenAiImage } from '../../../../slice/genAi/GenAiAttributesSlice'
import { GalleryModel, GenAiImageModel } from '../../../../Model/genAiAttributes/GenAiImages'
import { getSettingPath } from '../../../../slice/settingPathSlice/SettingPathSlice'


const s3BucketUrl=process.env.REACT_APP_S3Bucket
const urlpath = process.env.REACT_APP_Project_Image

type Props={
    startCompare:(genImage:GalleryModel)=>void
}
const GenAiGalleryImages = ({startCompare}:Props) => {
    const dispatch= useDispatch()
    const [gallery, setGallery]= useState<GenAiImageModel|null>(null)
   
    const[path,setPath]= useState<string>("")
    const getProjectGenAiImages= useSelector(getProjectGenAiImage )

    
    const getSettingPaths= useSelector(getSettingPath)
    // set the image path
    useEffect(()=>{
     if(getSettingPaths && getSettingPaths.length>0){
       let path
       const projectPath= getSettingPaths.filter((data)=>data.title==="Project Image")
       if(projectPath.length>0){
         path=projectPath[0].path??""
         setPath(path)
       }
     }
  },[getSettingPaths])
    // update the gen Ai gallery images from redux

    useEffect(()=>{
        if(getProjectGenAiImages){
            setGallery(getProjectGenAiImages)
        }
    },[getProjectGenAiImages])

    // compare images
    const handleCompareImage=(genImage:GalleryModel)=>{
         //console.log("genImage",genImage)
         startCompare(genImage)
    }
  return (
    <>
   {gallery && gallery.project_id && gallery.gallery ? (
        gallery.gallery.length > 1 ? (
            gallery.gallery
                .filter(item => item.image_name !== "Project Image")
                .map(item => {
                    let path: string = `${urlpath}/${gallery.project_id}/styleGen/${item.image_path}`;
                    return (
                        <div className="col-2" key={item.image_name}
                        onClick={()=>handleCompareImage(item)}
                        >
                            <img src={path} alt="no image" width={200} />
                        </div>
                    );
                })
        ) : (
            gallery.gallery.length === 1 &&
            gallery.gallery
                .filter(item => item.image_name === "Project Image")
                .map(item => {
                    let paths = `${path}${gallery.project_id}/${gallery.job_id}/${item.image_path}`;
                    return (
                        <div className="col-2" key={item.image_name}>
                            <img src={paths} alt="no image" width={200} />
                        </div>
                    );
                })
        )
    ) : null}
    </>
  )
}

export default GenAiGalleryImages