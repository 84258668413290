import React, { useEffect, useMemo, useRef, useState } from "react";
import "./Swatchtable.css";

import { useDispatch, useSelector } from "react-redux";
import { addIsDownloadSwatch, addSearchSwatch, getIsSearch } from "../../../../../slice/materialSlice/MaterialSlice";
import { getMaterial } from "../../../../../slice/materialSlice/MaterialSlice";

import DataTable from "react-data-table-component";
import { getAllSwatches } from "../../../../../slice/materialSlice/MaterialSlice";
import { MaterialModel } from "../../../../../Model/material/MaterialModel";


import { getSearchSwatchData } from "../../../../../slice/materialSlice/MaterialSlice";
import { getLoading } from "../../../../../slice/loading/LoadingSlice";
import SearchSwatches from "../searchSwatchs/SearchSwatches";
import Loading from "../../../../loading/Loading";
import AddEditSwatch from "./addEditSwatch/AddEditSwatch";
import GetAllMaterialsHome from "../../../../getAllMaterials/GetAllMaterialsHome";
import { getMaterialPath } from "../../../../ImageView/LeftSections/util/materialPath/MaterialPath";
import { SearchMaterialModal } from "../../../../../Model/material/SearchMaterial";
import { CatBrandStyleSwatchModel } from "../../../../../Model/material/CatBrandStyleSwatchModel";
import SearchSwatchBased from "../../../../ImageView/LeftSections/segmentbasedSwatch/searchSwatch/searchSwatchApi/SearchSwatchBased";
import { getAllSearchSwatch, getIsResetSearch } from "../../../../../slice/materialSlice/SearchMaterial";
const SwatchTable = () => {

  const [allSwatches, setAllSwatches] = useState<CatBrandStyleSwatchModel[]>([]);
  

  const getAllSwatche = useSelector(getAllSwatches);
  const getMaterials = useSelector(getMaterial);
  const [currentPages, setCurrentPages] = useState<number | null>();
  const dispatch = useDispatch();
   const [isOpenModal, setIsOpenModal] = useState<boolean>(false);  
  const getIsSearchs= useSelector(getIsSearch)
  const getSearchSwatchDatas= useSelector(getSearchSwatchData)
  const getLoadings= useSelector(getLoading)
    const getAllSearchSwatchs= useSelector(getAllSearchSwatch )
    const getIsResetSearchs = useSelector(getIsResetSearch)
  const [isloading, setIsLoading]= useState<boolean>(false)
  useEffect(() => {
    if (getLoadings) {
        setIsLoading(true);
    }else{
      setIsLoading(false);
    }
  }, [getMaterials]);

//  id searched thevalue then update teh table

const allSwatchesMemo = useMemo(() => {
  if (getIsSearchs && getSearchSwatchDatas && getSearchSwatchDatas.length > 0) {
    return getSearchSwatchDatas;
  } else if (!getIsSearchs && getMaterials && getMaterials.length > 0) {
    return getMaterials;
  }
  return [];
}, [getIsSearchs, getSearchSwatchDatas, getMaterials]);
   

useEffect(() => {
  setAllSwatches(allSwatchesMemo);
}, [allSwatchesMemo,]);





// if searched
   useEffect(()=>{
           if(getAllSearchSwatchs && getAllSearchSwatchs.length>0 &&
             getAllSearchSwatchs[0].Swatches && 
             getAllSearchSwatchs[0].Swatches.length>0){
            setAllSwatches( getAllSearchSwatchs[0].Swatches)
           }else if(getAllSearchSwatchs && 
            getAllSearchSwatchs.length==0 &&
             allSwatchesMemo && allSwatchesMemo.length>0){
            setAllSwatches(allSwatchesMemo)
           }
   },[getAllSearchSwatchs,allSwatchesMemo])
  


  const handleChangePage = () => {
    if (currentPages != null) {
      const page = currentPages + 1;
      const pageSize = 200;
      dispatch(
        addSearchSwatch({
          page: page,
          pageSize: pageSize,
        })
      );
      setCurrentPages(page);
      dispatch(addIsDownloadSwatch(true));
    }
  };
  const handleChangePrevPage = () => {
    if (currentPages != null) {
      const page = currentPages - 1;
      const pageSize = 200;
      setCurrentPages(page);
      dispatch(
        addSearchSwatch({
          page: page,
          pageSize: pageSize,
        })
      );
      dispatch(addIsDownloadSwatch(true));
    }
  };

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPages(page);
  };

  const handleAddSwatch = () => {
  setIsOpenModal(true)
  }


  const columns = [
    {
      name: "Id",
      selector: (row: CatBrandStyleSwatchModel) => row.id ?? 0, // Selector returns a primitive value
      sortable: true,
    },
    {
      name: "Image",
      cell: (row: CatBrandStyleSwatchModel) => {
        let path;
       
        if(row.new_bucket==0){
          path=getMaterialPath("old material")
        }else{
          path=getMaterialPath("materials")
        }
        // const path =
        //   "https://dzinlyv2.s3.us-east-2.amazonaws.com/liv/materials";
        const imagePath = row.photo ?? "";
        return <img width={50} src={`${path}${imagePath}`} alt="Material" />;
      },
    },

    {
      name: "Title",
      selector: (row: CatBrandStyleSwatchModel) => row.title ?? "",
      sortable: true,
    },
    {
      name: "Style",
      selector: (row: CatBrandStyleSwatchModel) => row.style?.title ?? "",
      sortable: true,
    },

    {
      name: "Brand",
      selector: (row: CatBrandStyleSwatchModel) => row.brand?.title ?? "",
      sortable: true,
    },

    {
      name: "Category",
      selector: (row: CatBrandStyleSwatchModel) => row.category?.title ?? "",
      sortable: true,
    },

    {
      name: "Status",
      selector: (row: CatBrandStyleSwatchModel) => row.status ?? "",
      sortable: true,
    },

    {
      name: "Action",
      cell: (row: CatBrandStyleSwatchModel) => (
        <>
          <div className="d-flex action">
            <div
              className="action-icon-table"
              // onClick={() => handleEditCategory(row)}
            >
              <i className="bi bi-pencil-square mx-0"></i>
            </div>
            <div className="action-icon-table">
              <i className="bi bi-copy mx-0"></i>
            </div>
            <div className="action-icon-table">
              <i className="bi bi-box-arrow-up-right mx-0"></i>
            </div>
            <div
              className="action-icon-table trash-icon-media"
              // onClick={() => handleDelete(row)}
            >
              <i className="bi bi-trash3 mx-0"></i>
            </div>
          </div>
        </>
      ),
    },
  ];

 


  return (
    <>
      {/* <CreateNewEntrySwatch /> */}

      <div className="card mx-3">
        <div className="card-header bg-white p-2 d-flex justify-content-between align-items-center">
          
          <SearchSwatches/>



        
        </div>

         {isloading &&
          <Loading />
         }
        <div className="card-body p-0">
          <div className="p-0">
            {allSwatches && allSwatches.length == 0 ? (
              <Loading />
            ) : (
             

              <DataTable
                columns={columns}
                data={allSwatches?.map((item) => ({ ...item, key: item.id }))}
                pagination
                fixedHeader
              />
            )}
          </div>
        </div>
      </div>
     {/* get all category , brand , style */}
      <GetAllMaterialsHome />

 {/* update the swatchn based on search */}
    <SearchSwatchBased />

      {/* add / edit matertial */}
      {isOpenModal &&
        <AddEditSwatch
        isModalOpen={isOpenModal}
        onClose={()=>setIsOpenModal(false)}
        type='Add'
        />}
    </>
  );
};

export default SwatchTable;
