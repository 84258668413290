import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { useSelector } from "react-redux";
import { getUserJobBase64 } from "../../slice/userJobSlice/UserJobSlice";

const CanvasEdit = () => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);

  const getUserJobBase64s = useSelector(getUserJobBase64);

  // Adjust canvas dimensions to fit the container
  const resizeCanvas = (fabricCanvas: fabric.Canvas) => {
    const container = containerRef.current;
    if (container) {
      const { offsetWidth, offsetHeight } = container;

      fabricCanvas.setWidth(offsetWidth);
      fabricCanvas.setHeight(offsetHeight);
      fabricCanvas.renderAll();
    }
  };

  // Add an image to the canvas
  const addImageToCanvas = (canvas: fabric.Canvas) => {
   // const encodedImage = "data:image/png;base64," + imageBase64;
    const encodedImage = "data:image/png;base64,"

    const decodedImage = atob(encodedImage.split(",")[1]);
    const uint8Array = new Uint8Array(decodedImage.length);
    for (let i = 0; i < decodedImage.length; i++) {
      uint8Array[i] = decodedImage.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: "image/png" });
    const imageUrl = URL.createObjectURL(blob);
  
    const url =
      "https://betadzinly.s3.us-east-2.amazonaws.com/projects/966/967/image_966_original.jpeg";
    fabric.Image.fromURL(url, (img) => {
      if (img && img.width && img.height && canvas.width && canvas.height) {
        // Calculate the scale to fit the image without stretching
        const imgAspectRatio = img.width / img.height;
        const canvasAspectRatio = canvas.width / canvas.height;
        let scale;
      console.log("image Width-->--->",img.width)
      console.log("image Height",img.height)
      console.log("canvas Width",canvas.width)
      console.log("canvas Height",canvas.height)
        if (canvasAspectRatio > imgAspectRatio) {
          scale = canvas.height / img.height;
        } else {
          scale = canvas.width / img.width;
        }

        img.scale(scale); // Scale the image
        img.set({
          left: (canvas.width - img.width * scale) / 2, // Center the image horizontally
          top: (canvas.height - img.height * scale) / 2, // Center the image vertically
          selectable: false, // Make it non-draggable
          hasControls: false, // Disable scaling and rotation
          evented: false, // Disable drag events
        });

        canvas.add(img); // Add the image to the canvas
        canvas.sendToBack(img); // Send the image to the background
        canvas.renderAll();
      }
    });
  };

  useEffect(() => {
    // Initialize the Fabric.js canvas
    const fabricCanvas = new fabric.Canvas(canvasRef.current, {
      backgroundColor: "#282828",
    });
    setCanvas(fabricCanvas);

    // Set initial canvas size
    resizeCanvas(fabricCanvas);

    // Resize the canvas when the window resizes
    const handleResize = () => resizeCanvas(fabricCanvas);
    window.addEventListener("resize", handleResize);

    // Handle zoom in and out with 'ctrl +', 'ctrl -' keys
    // const handleKeyDown = (e: KeyboardEvent) => {
    //   if (e.ctrlKey && e.key === "+") {
    //     handleZoom(true);
    //   } else if (e.ctrlKey && e.key === "-") {
    //     handleZoom(false);
    //   }
    // };

    //window.addEventListener("keydown", handleKeyDown);

    return () => {
      fabricCanvas.dispose();
      window.removeEventListener("resize", handleResize);
     // window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (canvas  ) {
      addImageToCanvas(canvas);
    }
  }, [canvas,getUserJobBase64s]);

  // Handle zooming within the canvas container
  // const handleZoom = (zoomIn: boolean) => {
  //   const zoomChange = zoomIn ? 0.1 : -0.1;
  //   const newZoom = Math.max(0.1, Math.min(canvas?.getZoom() + zoomChange, 5)); // Limit zoom to 0.1 to 5 range

  //   if (canvas) {
  //     canvas.setZoom(newZoom);
  //     canvas.renderAll();

  //     // Adjust the canvas container's scale to match the zoom level
  //     const container = containerRef.current;
  //     if (container) {
  //       container.style.transform = `scale(${newZoom})`;
  //       container.style.transformOrigin = 'top left';
  //     }
  //   }
  // };

  return (
    <div className="main-body-wrapper">
      <main>
        <div className="editor-wrapper">
          <div className="editor-toolbar-left">
            {/* Toolbar buttons can be added here */}
          </div>
          <div
            className="editor-canvas position-relative"
            ref={containerRef}
            style={{
              height: "100vh",
              overflow: "hidden",
              transformOrigin: "top left",
            }}
          >
            <canvas ref={canvasRef} />
          </div>
        </div>
      </main>
    </div>
  );
};

export default CanvasEdit;
