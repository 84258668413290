import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsOverAllSwatches, getMasterArray, resetIsUpdateOverAllSwatch, updateOverAllSwatchImages } from '../../slice/canvas/masterArray/MasterArraySlice';
import { Swatch } from '../../Model/Job/SamModel';
import { getUserLogin } from '../../slice/user/UserSlice';

const SortOverAllSwatches = () => {
    const getUserLogins = useSelector(getUserLogin);
    const getMasterArrays = useSelector(getMasterArray);
    const getIsOverAllSwatchess = useSelector(getIsOverAllSwatches);
    const dispatch = useDispatch();

    useEffect(() => {
        if (getMasterArrays && 
            getMasterArrays.allSeg &&
            getMasterArrays.allSeg.length > 0 &&
            getUserLogins && 
            getUserLogins.length > 0 &&
            getIsOverAllSwatchess
        ) {
            dispatch(resetIsUpdateOverAllSwatch());
            getMasterArrays.allSeg.map((grp, index) => {
                let allswatch: Swatch[] = [];
                const groupName = grp.name;
                const userId = getUserLogins[0].id;
                const groupSegments = grp.segmentation;
                if (groupSegments && 
                    groupSegments.length > 0 && 
                    userId) {
                    groupSegments.map(subGrp => {
                        Object.keys(subGrp).forEach(keys => {
                            const subGroupArray = subGrp[keys];  // wall1 array
                            if (subGroupArray && subGroupArray.length > 0) {
                                subGroupArray.map(child => {
                                    const segName = child.details?.seg_short;
                                    const childUser = child.designer?.filter(item => item.user_id === userId);
                                    if (childUser && childUser.length > 0) {
                                        const childSwatch = childUser[0].swatch ?? [];
                                        const uniqueDesigners = Array.from(new Map(childSwatch.map(designer => [designer.swatch_id, designer])).values());
                                        childSwatch.map(item => {
                                            const isExist = allswatch.findIndex(sw => sw.swatch_id == item.swatch_id);
                                            if (isExist === -1) {
                                                const data: Swatch = {
                                                    swatch_id: item.swatch_id,
                                                    swatch_seg_image: item.swatch_seg_image,
                                                    title: item.title,
                                                    photo: item.photo,
                                                    cost: item.cost,
                                                    isActive: item.isActive,
                                                    isApproved: item.isApproved,
                                                    new_bucket: item.new_bucket??0,
                                                    segGroup: [segName ?? ""]
                                                };
                                                allswatch.push(data);
                                            } else if (allswatch[isExist]) {
                                                if (!allswatch[isExist].segGroup) {
                                                    allswatch[isExist].segGroup = [];
                                                }
                                                allswatch[isExist].segGroup.push(segName ?? '');
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    });
                }
                const uniqueDesigners = Array.from(new Map(allswatch.map(designer => [designer.swatch_id, designer])).values());
                if (allswatch && allswatch.length > 0) {
                    dispatch(updateOverAllSwatchImages({
                        groupName: groupName,
                        ovetAllSwatches: allswatch
                    }));
                }
            });
        }
    }, [getMasterArrays, getUserLogins, getIsOverAllSwatchess]);

    return (
        <></>
    );
};

export default SortOverAllSwatches;