import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addHoverGroup,
  addNoHoverGroup,
  getMasterArray,
  getUpdateIndexing,
  resetHoverGrp,
  resetNoHoverGroup,
  resetUpdateIndexing,
  updateGroupNameActive,
} from "../../../../slice/canvas/masterArray/MasterArraySlice";
import {
  GroupMasterSegment,
  MasterArrayModel,
  MasterModel,
} from "../../../../Model/masterArray/MasterArrayModel";
import {
  changeSegmentTab,
  getTabControl,
} from "../../../../slice/tabControl/TabControlSlice";
import {
  addSelectedSegment,
  resetSelectedSegment,
} from "../../../../slice/userJobSlice/UserJobSlice";
import "../LeftSections.scss";
// import './LeftSections.scss'
import EachSeg from "../EachSegments/EachSeg";

import { ReactSVG } from "react-svg";
import { getCanvasControl, getIsSelectedCanvas, updateisSeletedCanvas } from "../../../../slice/canvas/masterArray/CanvasControlSlice";
import AddSegment from "./AddSegment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import SwatchImages from "../selectSwatch/SwatchImages";
import { addSelectedSegmentForSwatch } from "../../../../slice/segment/SegmentSlice";
import { addEachSegment, addGroupName, getEachSegment, getGroupName } from "../../../../slice/toolActive/ToolActiveSlice";
import { addTragetAddSegment, addUpdateGroup, getIsUpdateGroup } from "../../../../slice/canvas/updatevalue/UpdateValueSlice";

import SearchSwatchHome from "../segmentbasedSwatch/searchSwatch/SearchSwatchHome";
import { addSegmentTypeAllSearchSwatch, getIsFilter, resetFilter, updateIsFilter } from "../../../../slice/materialSlice/SearchMaterial";
import { resetSearchBrandName } from "../../../../slice/materialSlice/MaterialBrandSlice";
import { resetSearchStyleName } from "../../../../slice/materialSlice/MaterialStyleSlice";
import { addApiMessage } from "../../../../slice/messageToast/ToastSlice";

const Segments = () => {
  const [isMEdiaLibrary, setIsMediaLibrary] = useState(false);
  const [allSegMaster, setAllSegMaster] = useState<MasterArrayModel[]>([]);
  const [eachSegment, setEachSegment] = useState<MasterArrayModel | null>(null);
  const [masterSegName, setMasterName] = useState<string | null>(null);
  const [controlPannel, setControlPannel] = useState<string>();
  const getMasterArrays = useSelector(getMasterArray);
  const getTabControls = useSelector(getTabControl);
  const dispatch = useDispatch();
  const getCanvasControls = useSelector(getCanvasControl);
  const getUpdateIndexings = useSelector(getUpdateIndexing);
  const getGroupNames= useSelector(getGroupName)
  const getEachSegments= useSelector(getEachSegment)
   const getIsUpdateGroups = useSelector(getIsUpdateGroup)
   
  // update the active 
  useEffect(()=>{
    if(getGroupNames!=""){
      
      setMasterName(getGroupNames)
     UpdateEachSegmentGroupName(getGroupNames);
    }else{

      setMasterName(null)
    }
  },[getGroupNames])
  

  // getEach segment form redux
  useEffect(()=>{
    if(getEachSegments){
    setEachSegment(getEachSegments)
  }},[getEachSegments])


  // Update tab panel
  useEffect(() => {
    if (getTabControls) {
      setControlPannel(getTabControls);
    }
  }, [getTabControls]);

  // Update master array
  useEffect(() => {
    if (
      getMasterArrays &&
      getMasterArrays.allSeg &&
      getMasterArrays.allSeg.length > 0
    ) {
      setAllSegMaster(getMasterArrays.allSeg);
    } else {
      setAllSegMaster([]);
      setEachSegment(null);
    }
  }, [getMasterArrays]);

  // show first segment Swatch e.g wall
    const getIsSelectedCanvass= useSelector(getIsSelectedCanvas)
  useEffect(() => {
   
    if (
      getCanvasControls &&
      getCanvasControls.groupName &&
      getMasterArrays &&
      getMasterArrays.allSeg &&
      getMasterArrays.allSeg.length > 0 &&
      getIsSelectedCanvass
    ) {
     // console.log("getCanvasControls.isMainComment",getCanvasControls.isMainComment)
      dispatch(addGroupName(getCanvasControls.groupName))
    
      dispatch(updateGroupNameActive({  // set the active on GroupName
        groupName:getCanvasControls.groupName
      }))
      const group = getMasterArrays.allSeg.find(
        (item) => item.name == getCanvasControls.groupName
      );
  
       
      if (group && group.segmentation) {
        dispatch(updateisSeletedCanvas())
        dispatch(addEachSegment(group))
          
      }

    }
  }, [getMasterArrays, getCanvasControls]);

  const UpdateEachSegmentGroupName = (groupName: string) => {
    if (
      getMasterArrays &&
      getMasterArrays.allSeg &&
      getMasterArrays.allSeg.length > 0
    ) {
      // setAllSegMaster(getMasterArrays.allSeg)
      const group = getMasterArrays.allSeg.find(
        (item) => item.name == groupName
      );
    // setMasterName(groupName);
      if (group && group.segmentation) {
        //setEachSegment(group);
       // console.log("group.segmentation",group.segmentation)
        dispatch(addEachSegment(group))
       // dispatch(addSelectedSegment(group.segmentation));
        dispatch(addApiMessage("  "))
      }
    }
  };
  // On click tab
  const handleEachSegment = (data: MasterArrayModel) => {
    dispatch(resetSelectedSegment());
      
    if (data.name) {
      dispatch(updateGroupNameActive({  // set the active on GroupName
        groupName:data.name
      }))
     // setMasterName(data.name);
      dispatch(addGroupName(data.name)) // set GroupName
    }
    if (data && data.segmentation && data.segmentation.length > 0) {
      dispatch(addSelectedSegment(data.segmentation));
      dispatch(addSelectedSegmentForSwatch(data.segmentation))
    
       const key=Object.keys(data.segmentation[0])
      
      dispatch(addTragetAddSegment({
        groupName:data.name,
        subGroupName:key[0]
      }))
    }
  };

  const handleshowMedia = () => {
    setIsMediaLibrary(true);
  };

 

  /// update the each segment after delete
  useEffect(() => {
    if (
      getUpdateIndexings &&
      getUpdateIndexings.isUpdate &&
      getUpdateIndexings.groupName
    ) {
      UpdateEachSegmentGroupName(getUpdateIndexings.groupName);
     
    }
  }, [getUpdateIndexings, getMasterArrays]);

  // hover over the group
  const handleHoverGroup = (groupName: string) => {
    // console.log("hover",groupName)
    dispatch(resetNoHoverGroup());
    dispatch(addHoverGroup(groupName));
  };

  const handleRemoveHover = (groupName: string) => {
    // console.log("handleRemoveHover", groupName)
    dispatch(resetHoverGrp());
    dispatch(addNoHoverGroup(groupName));
    // dispatch(resetHoverGrp())
  };

  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };


   // update the each seg 
   useEffect(()=>{
    if(getIsUpdateGroups){
      console.log("updating the segment")
      dispatch(addUpdateGroup(false))
      UpdateEachSegmentGroupName(masterSegName??"")
    }
   },[getIsUpdateGroups])

   // 
   const handleOpenFilter=()=>{

    dispatch(resetFilter())
    dispatch(addSegmentTypeAllSearchSwatch({
      type:getGroupNames
    }))
    dispatch(updateIsFilter(true))
  dispatch(resetSearchBrandName())
         dispatch(resetSearchStyleName())
   }
   
  return (
    <>
      <div className="d-flex align-items-start" key="segments">

      {/* Left side bar menu */}

        <ul
          className="nav nav-pills flex-column left-panel-menu  p-2"
          id="myTab"
          role="tablist">
          {allSegMaster &&
            allSegMaster.length > 0 &&
            allSegMaster.map((item: MasterArrayModel, index: number) => {
              const path = "https://dzinlystrapi.s3.us-east-2.amazonaws.com";

              return (
                <li
                  className={`nav-item dzi-${item.name}`}
                  role="presentation"
                  key={item.id || index}>
                  <OverlayTrigger
                    key={`overlay-${item.id || index}`}
                    placement="right"
                    overlay={
                      <Tooltip id={`tooltip-${item.id}`}>{item.name}</Tooltip>
                    }>
                    <button
                      className={`nav-link ${
                        masterSegName === item.name ? "active" : ""
                      }`}
                      id={`${item.id}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#${item.id}`}
                      type="button"
                      role="tab"
                      aria-controls={`${item.id}`}
                      aria-selected={
                        masterSegName == item.name ? "true" : "false"
                      }
                      onClick={() => handleEachSegment(item)}
                      onMouseEnter={() => handleHoverGroup(item.name ?? "")}
                      onMouseLeave={() => handleRemoveHover(item.name ?? "")}
                      style={{
                        border: `1px solid ${item.color_code}`,
                        borderRadius: "6px",
                        backgroundColor:
                          item.isActive
                            ? `${item.color_code}50`
                            : "transparent", // Added background color with opacity
                        
                        transition: "background-color 0.3s ease", // Smooth transition                 // Rounded corners
                      }}>
                      <span className="icon">
                        <ReactSVG
                          src={`${item.icon}`}
                          beforeInjection={(svg) => {
                            svg.setAttribute(
                              "style",
                              "width: 100%; height: 100%;"
                            );
                            // Add a border with dynamic color
                            svg.setAttribute(
                              "style",
                              `width: 100%; height: 100%; border-radius: 4px;`
                            );
                          }}
                          onError={(error) => {
                            console.error("Error loading SVG:", error);
                          }}
                        />
                      </span>
                    </button>
                  </OverlayTrigger>
                </li>
              );
            })}

          <AddSegment />
        </ul>

        {/* Left side bar menu */}

        <div className="each-Seg-cont">
          <div className="tab-content tab-sub-container" id="myTabContent">
            {getEachSegments &&
            getEachSegments?.name == getGroupNames && (
              <EachSeg
                masterSegName={getGroupNames} //groupname
                eachSegment={getEachSegments }
              />
            )}

   
            <div className="eachSeg-header d-flex align-items-center justify-content-between py-1 px-3 border-bottom">
              
           {/*           <button type="button" className="btn btn-outline-secondary btn-sm bg-white text-dark border-dark rounded-pill d-flex align-items-center gap-1" data-bs-toggle="offcanvas" href="#offcanvasExample" > */}

              <button type="button" className="btn btn-outline-secondary btn-sm bg-white text-dark border-dark rounded-pill d-flex align-items-center gap-1"
                onClick={handleOpenFilter} >
                <i className="bi bi-filter-circle"></i>
                Filter
              </button>
           

              <button type="button" className='btn p-2 text-primary'>
                  <i className="bi bi-arrow-clockwise"></i>
              </button>
            </div>
            
            <div className=" swichpallets-boxs">
              <div className="p-3 swichpallets-box">
                <div className="row g-2">
                  <SwatchImages />
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
         
         {/* search section */}
         <SearchSwatchHome/>
        
     
    </>
  );
};




export default Segments;
