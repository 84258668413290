import React, { useState } from "react";

import {
  CiPalette,
  CiIndent,
  CiViewColumn,
  CiViewList,
  CiUser,
  CiGlobe,
  CiStop1,
  CiPenpot,
  CiSettings,
} from "react-icons/ci";
import "./_sidebar.scss";

import "./_sidebar.scss"
import logo from '../../../../public/logo.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addIsDownloadCategory } from '../../../slice/materialSlice/MaterialCategorySlice';
import GetAllMaterialsHome from '../../getAllMaterials/GetAllMaterialsHome';
import { addIsDownloadBrand, addSearchBrand } from '../../../slice/materialSlice/MaterialBrandSlice';
import { addIsDownloadStyle, addSearchStyle } from '../../../slice/materialSlice/MaterialStyleSlice';
import { addIsDownloadSwatch, addSearchSwatch } from "../../../slice/materialSlice/MaterialSlice";
import { addIsUserSearch } from "../../../slice/Admin/allUserSlice/AllUSerSlice";
import GenAiSideBar from "./genAiSideBar/GenAiSideBar";
import ModelSideBar from "./modelSidebar/ModelSideBar";
interface SidebarProps {
  toggleActiveClass: () => void; // Define the type for the function prop
}

const Sidebar: React.FC<SidebarProps> = ({ toggleActiveClass }) => {
   const dispatch= useDispatch()
  const navigator = useNavigate( )
 const handleCategory=()=>{
  //console.log("hello ,category")
  navigator("/dashboard-page/swatch-categories")
  setActiveSubmenuIndex([0]);
   dispatch(addIsDownloadCategory(true))
 }

  const handleBrand = () => {
    navigator("/dashboard-page/swatch-brand");
    setActiveSubmenuIndex([1]);

    dispatch(addIsDownloadBrand(true));
    dispatch(
      addSearchBrand({
        page: 1,
        pageSize: 200,
      })
    );
  };
;

 const handleStyle =()=>{
  navigator("/dashboard-page/swatch-styles")
  setActiveSubmenuIndex([2]);
  
  dispatch(addIsDownloadStyle(true))
  dispatch(addSearchStyle({
    page:1,
    pageSize:200
  }))
 }

 const handleShape =()=>{
  navigator("/dashboard-page/shape")
  setActiveSubmenuIndex([6]);
 }
 
 const  handleUser =()=>{
  dispatch(addIsUserSearch(true))
  navigator("/dashboard-page/users")
  setActiveSubmenuIndex([5]);
 }

  // const handleUser = () => {
  //   navigator("/dashboard-page/user");
  //   setActiveSubmenuIndex([5]);
  // };

  const handleSegments = () => {
    navigator("/dashboard-page/segment");
    setActiveSubmenuIndex([4]);
  };

  const handleSwatch = () => {
    navigator("/dashboard-page/swatches");
    setActiveSubmenuIndex([3]);
    dispatch(addIsDownloadSwatch(true))
    dispatch(addSearchSwatch({
      page:1,
      pageSize:200
    }))

    // brand
    dispatch(addIsDownloadBrand(true));
    dispatch(
      addSearchBrand({
        page: 1,
        pageSize: 200,
      })
    );

    // style
    dispatch(addIsDownloadStyle(true))
  dispatch(addSearchStyle({
    page:1,
    pageSize:200
  }))
  };

  // State to track which main menu item is active
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  // State to track active submenu item for each parent menu
  const [activeSubmenuIndex, setActiveSubmenuIndex] = useState<{
    [key: number]: number | null;
  }>({});

  // Function to handle main menu click
  const handleItemClick = (index: number) => {
    setActiveIndex(index);
  };

  // Function to handle submenu click
  const handleSubmenuClick = (parentIndex: number, submenuIndex: number) => {
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));
  };

  const handleProject=(parentIndex: number, submenuIndex: number)=>{
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));
    navigator("/dashboard-page/projects");
  }
  const handleJob=()=>{
    navigator("/dashboard-page/jobs")
    setActiveSubmenuIndex([2]);
  
  }

  const handleSettingPath=(index:number)=>{
    setActiveIndex(index);
    navigator("/dashboard-page/setting-path")
    
  
  }



  return (
    <>
      <aside className="sidebar p-3 pe-0 mt-6">
        <div className="sidebar-menu border p-3  h-100">
          <div className="sidebar-header d-flex align-items-center justify-content-between border-bottom mb-3">
            <div className="logo">
              {/* <img src={logo} alt="Logo" />  */}
              <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Logo" />
            </div>
            <button
              type="button"
              className="tooglebtn border-0 bg-transparent p-0 lh-1"
              onClick={toggleActiveClass}>
              <CiIndent />
            </button>
          </div>

          <ul className="sidebar-nav">
            <li
              className={activeIndex === 0 ? "active" : ""}
              role="button"
              onClick={() => handleItemClick(0)}>
              <div className="d-flex align-items-center gap-1">
                <span className="sidebar-nav-icon">
                  <CiGlobe />
                </span>
                <span className="sidebar-nav-text">Dashboard</span>
              </div>
              <i className="bx bx-chevron-down dropicon"></i>
              <ul className="submenu">
                <li
                  className={activeSubmenuIndex[0] === 0 ? "active" : ""}
                  // onClick={() => handleSubmenuClick(0, 0)}
                  onClick={handleCategory}>
                  Category
                </li>

                <li
                  className={activeSubmenuIndex[0] === 1 ? "active" : ""}
                  // onClick={() => handleSubmenuClick(0, 1)}
                  onClick={handleBrand}>
                  Brands
                </li>

                <li
                  className={activeSubmenuIndex[0] === 2 ? "active" : ""}
                  // onClick={() => handleSubmenuClick(0, 2)}
                  onClick={handleStyle}>
                  Style
                </li>
                <li
                  className={activeSubmenuIndex[0] === 3 ? "active" : ""}
                  // onClick={() => handleSubmenuClick(0, 3)}
                  onClick={handleSwatch}>
                  Swatch
                </li>
              </ul>
            </li>

            <li
              className={activeIndex === 1 ? "active" : ""}
              role="button"
              onClick={() => handleItemClick(1)}>
              <div className="d-flex align-items-center gap-1">
                <span className="sidebar-nav-icon">
                  <CiPalette />
                </span>
                <span className="sidebar-nav-text">Material Library</span>
              </div>
              <i className="bx bx-chevron-down dropicon"></i>
              <ul className="submenu">
                <li
                  className={activeSubmenuIndex[1] === 0 ? "active" : ""}
                  // onClick={() => handleSubmenuClick(1, 0)} 
                  onClick={handleCategory}
                  >
                  Category
                </li>
                <li
                  className={activeSubmenuIndex[1] === 1 ? "active" : ""}
                  // onClick={() => handleSubmenuClick(1, 1)}
                  onClick={handleBrand}
                  >
                  Brands
                </li>
                <li
                  className={activeSubmenuIndex[1] === 2 ? "active" : ""}
                  // onClick={() => handleSubmenuClick(1, 2)} 
                  onClick={handleStyle}
                  >
                  Style
                </li>
                <li
                  className={activeSubmenuIndex[1] === 3 ? "active" : ""}
                  // onClick={() => handleSubmenuClick(1, 3)} 
                  onClick={handleSwatch}
                  >
                  Swatch
                </li>
              </ul>
            </li>
            <li
              className={activeIndex === 2 ? "active" : ""}
              role="button"
              onClick={() => handleItemClick(3)}>
              <div className="d-flex align-items-center gap-1">
                <span className="sidebar-nav-icon">
                  <CiViewColumn />
                </span>
                <span className="sidebar-nav-text">Project</span>
              </div>

              <i className="bx bx-chevron-down dropicon"></i>
              <ul className="submenu">
                <li
                  className={activeSubmenuIndex[3] === 0 ? "active" : ""}
                  onClick={() => handleProject(1, 0)}>
                  Project
                </li>
                <li
                  className={activeSubmenuIndex[3] === 1 ? "active" : ""}
                   onClick={handleJob}

                 // onClick={handleJob}
                  >
                  Job
                </li>
              </ul>
            </li>
            {/* Gen Ai dashboard  start*/}
             <GenAiSideBar/>


             {/* Gen Ai dashboard  stop*/}

             {/* model dashBoard */}
             <ModelSideBar/>

            <li
              className={activeIndex === 4 ? "active" : ""}
              role="button"
              onClick={() => handleItemClick(4)}>
              <div className="d-flex align-items-center gap-1">
                <span className="sidebar-nav-icon">
                  <CiViewList />
                </span>
                <span className="sidebar-nav-text" onClick={handleSegments}>
                  Segments
                </span>
              </div>
            </li>
            <li
              className={activeIndex === 5 ? "active" : ""}
              role="button"
              onClick={() => handleItemClick(5)}>
              <div className="d-flex align-items-center gap-1">
                <span className="sidebar-nav-icon">
                  <CiUser />
                </span>
                <span className="sidebar-nav-text" onClick={handleUser}>
                  Users
                </span>
              </div>
            </li>
            <li
              className={activeIndex === 6 ? "active" : ""}
              role="button"
              onClick={() => handleItemClick(6)}>
              <div className="d-flex align-items-center gap-1">
                <span className="sidebar-nav-icon">
                  <CiStop1 />
                </span>
                <span className="sidebar-nav-text" onClick={handleShape}>
                  Shapes
                </span>
              </div>
            </li>

            <li
              className={activeIndex === 7 ? "active" : ""}
              role="button"
              onClick={() => handleSettingPath(7)}>
              <div className="d-flex align-items-center gap-1">
                <span className="sidebar-nav-icon">
                  <CiPenpot />
                </span>
                <span className="sidebar-nav-text">
                  Setting Path
                </span>
              </div>

              <i className="bx bx-chevron-down dropicon"></i>
              {/* <ul className="submenu">
                <li
                  className={activeSubmenuIndex[7] === 0 ? "active" : ""}      
                             // onClick={() => handleSubmenuClick(0, 0)}
                 >
                  Projects
                </li>

                <li
                  className={activeSubmenuIndex[7] === 1 ? "active" : ""}
                  // onClick={() => handleSubmenuClick(0, 1)}
                  >
                   Segment Icons
                </li>

                <li
                  className={activeSubmenuIndex[7] === 2 ? "active" : ""}
                  // onClick={() => handleSubmenuClick(0, 2)}
                 >
                 Category Icons
                </li>
                <li
                  className={activeSubmenuIndex[7] === 3 ? "active" : ""}
                  // onClick={() => handleSubmenuClick(0, 3)}
                  >
                  Category Gallery
                </li>
              </ul> */}
            </li>

       

            <li
              className={activeIndex === 8 ? "active" : ""}
              role="button"
              onClick={() => handleItemClick(8)}>
              <div className="d-flex align-items-center gap-1">
                <span className="sidebar-nav-icon">
                  <CiSettings />
                </span>
                <span className="sidebar-nav-text" onClick={handleUser}>
                  Setting
                </span>
              </div>

              <i className="bx bx-chevron-down dropicon"></i>
              <ul className="submenu">
                <li
                  className={activeSubmenuIndex[1] === 0 ? "active" : ""}
                  onClick={() => handleSubmenuClick(1, 0)}>
                  OverView
                </li>
                </ul>
            </li>

          </ul>
        </div>
      </aside>

      <GetAllMaterialsHome />

      
    </>
  );
};

export default Sidebar;
